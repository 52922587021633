import React, { Fragment } from 'react';

import { UserNanoID } from '../../../usersTypes';
import { FetchSingeUserQueryResponse } from '../../../queries/fetchSingleUser.query';

import { useCurrentUser } from '../../../../../auth/hooks/useAuth';
import { useCachedUser } from '../../../hooks/useCachedUser';

import { DashboardStatistic } from './components/DashboardStatistic';
import { DashboardFinance } from './components/DashboardFinance';
import { DashboardTasksByStatus } from './components/DashboardTasksByStatus';
import { DashboardActiveTasks } from './components/DashboardActiveTasks';
import { DashboardTrackedTime } from './components/DashboardTrackedTime';
import { DashboardMissingSourceFiles } from './components/DashboardMissingSourceFiles';
import { DashboardLatestResults } from './components/DashboardLatestResults';

import { MenuCreateProjectInTeamLink } from '../../../../common/components/menus/MenuCreateProjectInTeamLink';

import { CheckPermissions } from '../../../../../helpers/CheckPermissions';

import { UsersPermissions } from '../../../usersConstants';
import { UserCache } from '../../../UserCache';

interface SingleUserDashboardIndexPageProps {
  userNanoId: UserNanoID;
}

function SingleUserDashboardIndexPage({
  userNanoId
}: SingleUserDashboardIndexPageProps) {
  const { user } = useCachedUser<FetchSingeUserQueryResponse>({
    cacheKey: UserCache.showQueryKey(userNanoId)
  });

  const currentUser = useCurrentUser();

  const selfProfile = userNanoId === currentUser.nanoId;

  if (!user) {
    return null;
  }

  return (
    <Fragment>
      <div className="space-y-16">
        <CheckPermissions
          action={
            selfProfile
              ? UsersPermissions.READ_SELF_DASHBOARD_CREATE_PROJECT_IN_TEAM_LINK
              : UsersPermissions.READ_USER_DASHBOARD_CREATE_PROJECT_IN_TEAM_LINK
          }
        >
          <MenuCreateProjectInTeamLink />
        </CheckPermissions>

        <CheckPermissions
          action={
            selfProfile
              ? UsersPermissions.READ_SELF_DASHBOARD_LATEST_RESULTS_BLOCK
              : UsersPermissions.READ_USER_DASHBOARD_LATEST_RESULTS_BLOCK
          }
        >
          <DashboardLatestResults selfProfile={selfProfile} user={user} />
        </CheckPermissions>

        <CheckPermissions
          action={
            selfProfile
              ? UsersPermissions.READ_SELF_DASHBOARD_ACTIVE_TASKS_BLOCK
              : UsersPermissions.READ_USER_DASHBOARD_ACTIVE_TASKS_BLOCK
          }
        >
          <DashboardActiveTasks selfProfile={selfProfile} user={user} />
        </CheckPermissions>

        <CheckPermissions
          actions={[
            selfProfile
              ? UsersPermissions.READ_SELF_DASHBOARD_TRACKED_TIME_BLOCK
              : UsersPermissions.READ_USER_DASHBOARD_TRACKED_TIME_BLOCK,
            selfProfile
              ? UsersPermissions.READ_SELF_DASHBOARD_MISSING_SOURCE_FILES_BLOCK
              : UsersPermissions.READ_USER_DASHBOARD_MISSING_SOURCE_FILES_BLOCK
          ]}
        >
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <CheckPermissions
              action={
                selfProfile
                  ? UsersPermissions.READ_SELF_DASHBOARD_TRACKED_TIME_BLOCK
                  : UsersPermissions.READ_USER_DASHBOARD_TRACKED_TIME_BLOCK
              }
            >
              <DashboardTrackedTime user={user} selfProfile={selfProfile} />
            </CheckPermissions>

            <CheckPermissions
              action={
                selfProfile
                  ? UsersPermissions.READ_SELF_DASHBOARD_MISSING_SOURCE_FILES_BLOCK
                  : UsersPermissions.READ_USER_DASHBOARD_MISSING_SOURCE_FILES_BLOCK
              }
            >
              <DashboardMissingSourceFiles
                user={user}
                selfProfile={selfProfile}
              />
            </CheckPermissions>
          </div>
        </CheckPermissions>

        <CheckPermissions
          action={
            selfProfile
              ? UsersPermissions.READ_SELF_DASHBOARD_TASKS_BY_STATUS_BLOCK
              : UsersPermissions.READ_USER_DASHBOARD_TASKS_BY_STATUS_BLOCK
          }
        >
          <DashboardTasksByStatus selfProfile={selfProfile} user={user} />
        </CheckPermissions>

        <CheckPermissions
          action={
            selfProfile
              ? UsersPermissions.READ_SELF_DASHBOARD_FINANCE_BLOCK
              : UsersPermissions.READ_USER_DASHBOARD_FINANCE_BLOCK
          }
        >
          <DashboardFinance
            selfProfile={selfProfile}
            client={user.client}
            teamNanoId={user.currentTeam?.nanoId}
          />
        </CheckPermissions>

        <CheckPermissions
          action={
            selfProfile
              ? UsersPermissions.READ_SELF_DASHBOARD_STATISTIC_BLOCK
              : UsersPermissions.READ_USER_DASHBOARD_STATISTIC_BLOCK
          }
        >
          <DashboardStatistic selfProfile={selfProfile} user={user} />
        </CheckPermissions>
      </div>
    </Fragment>
  );
}

export default SingleUserDashboardIndexPage;
