import { useMemo, useEffect, useState } from 'react';
import map from 'lodash/map';
import compact from 'lodash/compact';
import isEqual from 'lodash/isEqual';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import some from 'lodash/some';

import {
  InvoiceNanoID,
  FetchInvoiceFetched,
  FetchInvoicesErrorMessage
} from '../../invoicesTypes';

import {
  FETCH_INVOICES_IN_MESSAGES_QUERY,
  FetchInvoicesInMessagesQueryResponse
} from '../../queries/fetchInvoicesInMessages';

import { useFinPaginatedInvoices } from '../useFinPaginatedInvoices';

import { InvoiceCache } from '../../InvoiceCache';

interface ItemsWithBitInvoicesBaseItemType {
  invoice?: {
    nanoId: InvoiceNanoID;
  };
}

type ItemsWithBitInvoicesItemWithBitInvoiceType<T> = {
  bitInvoice?: FetchInvoicesInMessagesQueryResponse;
  bitInvoiceFetched?: FetchInvoiceFetched;
  bitInvoiceErrorMessage?: FetchInvoicesErrorMessage;
} & T;

interface ItemsWithBitInvoicesOptions<ItemType> {
  items: ItemType[];
}

function useItemsWithBitInvoices<
  ItemType extends ItemsWithBitInvoicesBaseItemType
>({ items }: ItemsWithBitInvoicesOptions<ItemType>) {
  const [invoicesEnabled, setInvoicesEnabled] = useState<boolean>(false);

  const {
    invoices,
    invoicesFilters,
    filterInvoices,
    invoicesFetched,
    invoicesErrorMessage,
    clearInvoicesFilters
  } = useFinPaginatedInvoices<FetchInvoicesInMessagesQueryResponse>({
    cacheKey: InvoiceCache.itemsWithBitInvoicesCacheKey(),
    initialLimit: 500,
    options: {
      enabled: invoicesEnabled,
      enabledPlaceholder: invoicesEnabled,
      withoutPrefetch: true
    },
    query: FETCH_INVOICES_IN_MESSAGES_QUERY
  });

  useEffect(() => {
    const itemsInvoiceNanoIds = compact(
      map(items, (item) => item?.invoice?.nanoId)
    );

    if (isEmpty(itemsInvoiceNanoIds)) {
      !isEmpty(invoicesFilters?.nanoId?.in) && clearInvoicesFilters();
      setInvoicesEnabled(false);
      return;
    }

    if (!isEqual(itemsInvoiceNanoIds, invoicesFilters?.nanoId?.in)) {
      filterInvoices({ nanoId: { in: itemsInvoiceNanoIds } });
      setInvoicesEnabled(true);
    }
  }, [items, invoicesFilters, filterInvoices, clearInvoicesFilters]);

  const itemsWithBitInvoices = useMemo<
    ItemsWithBitInvoicesItemWithBitInvoiceType<ItemType>[]
  >(() => {
    const itemsInvoiceNanoIds = some(items, (item) => item?.invoice?.nanoId);

    if (!itemsInvoiceNanoIds) {
      return items;
    }

    return map(items, (item) => {
      const itemBitInvoice = item?.invoice?.nanoId
        ? find(invoices, ['nanoId', item.invoice.nanoId])
        : null;

      if (item?.invoice?.nanoId) {
        return {
          ...item,
          bitInvoice: itemBitInvoice,
          bitInvoiceFetched: invoicesFetched,
          bitInvoiceErrorMessage: invoicesErrorMessage
        };
      }

      return item;
    });
  }, [items, invoices, invoicesFetched, invoicesErrorMessage]);

  return { itemsWithBitInvoices, invoicesFetched };
}

export default useItemsWithBitInvoices;
