import { gql } from 'graphql-request';

import { MayBe } from '../../../types';

import {
  FileAttachmentID,
  FileAttachmentUpdatedAt,
  FileAttachmentUUID,
  FileAttachmentUserNanoId,
  FileAttachmentUserFullName,
  FileAttachmentUserImageUUID,
  FileAttachmentUserImageFile,
  FileAttachmentUserClient,
  FileAttachmentUserCurrentTeamNanoID
} from '../../fileAttachments/fileAttachmentsTypes';

import {
  TaskUUID,
  TaskName,
  TaskUpdatedAt,
  TaskNanoID,
  TaskUserNanoID,
  TaskUserFullName,
  TaskUserImageUUID,
  TaskUserImageFile,
  TaskUserClient,
  TaskUserCurrentTeamNanoID
} from '../tasksTypes';

export interface FetchProjectAttachmentsTasksQueryResponse {
  uuid: TaskUUID;
  nanoId: TaskNanoID;
  name: TaskName;
  updatedAt: TaskUpdatedAt;
  user: {
    nanoId: TaskUserNanoID;
    fullName: TaskUserFullName;
    client: TaskUserClient;
    currentTeam: MayBe<{
      nanoId: TaskUserCurrentTeamNanoID;
    }>;
    image: {
      uuid: TaskUserImageUUID;
      file: TaskUserImageFile;
    };
  };
  lastFileAttachment: {
    id: FileAttachmentID;
    uuid: FileAttachmentUUID;
    updatedAt: FileAttachmentUpdatedAt;
    user: {
      nanoId: FileAttachmentUserNanoId;
      fullName: FileAttachmentUserFullName;
      client: FileAttachmentUserClient;
      currentTeam: MayBe<{
        nanoId: FileAttachmentUserCurrentTeamNanoID;
      }>;
      image: {
        uuid: FileAttachmentUserImageUUID;
        file: FileAttachmentUserImageFile;
      };
    };
  };
}

export const FETCH_PROJECT_ATTACHMENTS_TASKS_QUERY = gql`
  query ProjectAttachmentsTasks(
    $filters: TasksFilters
    $sort: [TasksSortEnum!]
    $limit: Int
    $offset: Int
  ) {
    tasks(filters: $filters, sort: $sort, limit: $limit, offset: $offset) {
      nodes {
        uuid
        nanoId
        name
        updatedAt
        user {
          nanoId
          fullName
          client
          currentTeam {
            nanoId
          }
          image {
            uuid
            file
          }
        }
        lastFileAttachment {
          id
          uuid
          updatedAt
          user {
            nanoId
            fullName
            client
            currentTeam {
              nanoId
            }
            image {
              uuid
              file
            }
          }
        }
      }
      paginationInfo {
        nextPage
        totalCount
      }
      sql
    }
  }
`;
