import { gql } from 'graphql-request';

import {
  TeamUUID,
  TeamID,
  TeamCreatedAt,
  TeamImageFile,
  TeamName,
  TeamNanoID,
  TeamTerms,
  TeamPreferredPaymentMethod,
  TeamOwnerID,
  TeamOwnerNanoID,
  TeamOwnerEmail
} from '../teamsTypes';

export interface FetchSelectFieldTeamsQueryResponse {
  id: TeamID;
  nanoId: TeamNanoID;
  uuid: TeamUUID;
  name: TeamName;
  createdAt: TeamCreatedAt;
  image: {
    file: TeamImageFile;
  };
  owner?: {
    id: TeamOwnerID;
    nanoId: TeamOwnerNanoID;
    email: TeamOwnerEmail;
  };
  ownerId?: TeamOwnerID;
  preferredPaymentMethod: TeamPreferredPaymentMethod;
  terms: TeamTerms;
}

export const FETCH_SELECT_FIELD_TEAMS_QUERY = gql`
  query SelectFieldTeams(
    $filters: TeamsFilters
    $sort: [TeamsSortEnum!]
    $limit: Int
    $offset: Int
  ) {
    teams(filters: $filters, sort: $sort, limit: $limit, offset: $offset) {
      nodes {
        id
        nanoId
        uuid
        name
        createdAt
        image {
          file
        }
        owner {
          id
          nanoId
          email
        }
        ownerId
        preferredPaymentMethod
        terms
      }
      paginationInfo {
        nextPage
        totalCount
      }
      sql
    }
  }
`;
