export default {
  hide: 'Hide menu',
  keepOpen: 'Keep menu open',
  links: {
    all: 'All',
    access: 'Access',
    accounts: 'Accounts',
    accountingTransactions: 'Accounting Transactions',
    active: 'Active',
    allInvoices: 'All Invoices',
    bills: 'Bills',
    blocked: 'Blocked',
    chatGPTPrompts: 'ChatGPT Prompts',
    clients: 'Clients',
    emailTemplates: 'Email Templates',
    dashboard: 'Dashboard',
    favorite: 'Favorite',
    finance: 'Finance',
    generalLedger: 'General ledger',
    invoices: 'Invoices',
    itemTags: 'Item tags',
    main: 'Main',
    my: 'My',
    permissions: 'Permissions',
    people: 'People',
    products: 'Products',
    projects: 'Projects',
    reports: 'Reports',
    reportSections: 'Report sections',
    sections: 'Sections',
    sentMessages: 'Sent messages',
    smartContracts: 'Smart contracts',
    pages: 'Pages',
    roles: 'Roles',
    content: 'Content',
    tasks: 'Tasks',
    transactionsImports: 'Transaction Imports',
    invoiceProcessingJobs: 'Invoice Processing Jobs',
    categories: 'Categories',
    profile: 'Profile',
    stock: '3D stock',
    messages: 'Messages',
    companies: 'Companies',
    defaultItemTypes: 'Default item types',
    itemTypes: 'Item types',
    itemCategories: 'Item Categories',
    avBillingInfos: 'Billing info',
    depositInvoices: 'Deposit invoices',
    workers: 'Workers',
    smartContractFees: 'Smart contract fees',
    unassigned: 'Unassigned'
  },
  tabs: {
    knowledgeLibrary: 'Knowledge Library',
    bookstack: 'Bookstack',
    dashboard: 'Dashboard',
    notifications: 'Notifications',
    tasksAndProjects: 'Tasks and Projects',
    workspace: 'Workspace',
    workspaceBeta: 'Workspace (beta)',
    stock3d: '3D Stock',
    users: 'Users',
    workers: 'Workers',
    people: 'People',
    clients: 'Clients',
    admin: 'Admin panel',
    payments: 'Payments',
    library: 'Library',
    myLibrary: 'My Library',
    reports: 'Reports'
  }
};
