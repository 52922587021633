import cl from 'classnames';
import { useCallback } from 'react';
import size from 'lodash/size';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import without from 'lodash/without';
import includes from 'lodash/includes';

import { IconsEnum } from '../../../../../../../assets/icons/types';
import { PositionStyleEnum } from '../../../../../../../types';
import {
  FetchUsersErrorMessage,
  FetchUsersFetched,
  FetchUsersTotalCount,
  UserUUID
} from '../../../../../../users/usersTypes';

import {
  ItemMessagesFilters,
  ItemMessagesListFilterMessages
} from '../../../../list/ItemMessagesList';

import { UsersCheckboxList } from './components/UsersCheckboxList';
import { UserCheckboxFieldUserType } from './components/UserCheckboxField';

import { DropdownHelper } from '../../../../../../../helpers/dropdowns/DropdownHelper';
import { PopoverPlacement } from '../../../../../../../helpers/Popover/popoverConstants';
import { PureButtonHelper } from '../../../../../../../helpers/buttons/PureButtonHelper';
import { LoadingSkeleton } from '../../../../../../../helpers/LoadingSkeleton';
import { NoResults } from '../../../../../../../helpers/NoResults';

import { categoriesKeys, words } from '../../../../../../../locales/keys';

interface ItemMessagesHeaderUsersFilterProps {
  users: UserCheckboxFieldUserType[];
  usersError: FetchUsersErrorMessage;
  usersFetched: FetchUsersFetched;
  usersTotalCount: FetchUsersTotalCount;
  messagesFilters: ItemMessagesFilters;
  filterMessages: ItemMessagesListFilterMessages;
}

function ItemMessagesHeaderUsersFilter({
  users,
  usersError,
  usersFetched,
  usersTotalCount,
  messagesFilters,
  filterMessages
}: ItemMessagesHeaderUsersFilterProps) {
  const checkedUuids = messagesFilters.userUuids;

  const handleCheck = useCallback<(uuid: UserUUID) => void>(
    (uuid) => {
      if (includes(checkedUuids, uuid)) {
        const updatedUserUuids = without(checkedUuids, uuid);
        filterMessages({
          ...messagesFilters,
          userUuids: isEmpty(updatedUserUuids) ? undefined : updatedUserUuids
        });
      } else {
        const updatedUserUuids = [...(checkedUuids || []), uuid];
        filterMessages({
          ...messagesFilters,
          userUuids: updatedUserUuids
        });
      }
    },
    [checkedUuids, filterMessages, messagesFilters]
  );

  const handleClear = useCallback<() => void>(() => {
    filterMessages(omit(messagesFilters, ['userUuids']));
  }, [messagesFilters, filterMessages]);

  return (
    <DropdownHelper
      className="flex items-center justify-center"
      buttonClassName={cl(
        'py-1 pl-1 pr-1 sm:pr-2 sm:space-x-1 rounded inline-flex items-center whitespace-nowrap text-xs leading-5 focus:ring-base focus:ring-offset-0',
        size(checkedUuids) > 0
          ? 'text-blue-600 hover:text-blue-700 dark:text-blue-400 dark:hover:text-blue-300'
          : 'hover:text-gray-950 dark:hover:text-white'
      )}
      icon={IconsEnum.USERS_SOLID}
      iconClassName="h-4 w-4 mr-1"
      popoverClassName="relative px-2 py-1 z-20 overflow-y-auto bg-white border border-transparent dark:bg-gray-700 dark:border-opacity-10 dark:border-white dark:text-white focus:outline-none p-0 ring-1 ring-black ring-opacity-5 rounded-md shadow-lg text-gray-700 max-w-xs text-center"
      dropdownPlacement={PopoverPlacement.BOTTOM_START}
      i18nText={categoriesKeys.authors.plural}
      popoverPositionStyle={PositionStyleEnum.fixed}
    >
      {usersTotalCount || !usersFetched ? (
        <LoadingSkeleton
          loaded={usersFetched}
          height={20}
          width={120}
          className="m-0"
        >
          <UsersCheckboxList
            users={users}
            checkedUuids={checkedUuids}
            onCheck={handleCheck}
          />
          <div className="px-1 mt-2">
            <PureButtonHelper
              className="py-1.5 pl-3 pr-3 rounded-md whitespace-nowrap text-sm leading-5 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-500/20 focus:ring-offset-0 w-full"
              i18nText={words.clearFilter}
              onClick={handleClear}
            />
          </div>
        </LoadingSkeleton>
      ) : (
        <NoResults className="p-2" />
      )}

      {usersError && <p className="mt-2 text-sm text-red-600">{usersError}</p>}
    </DropdownHelper>
  );
}

export default ItemMessagesHeaderUsersFilter;
