import { ProductsKeyType } from '../../keys';

const productsKeys: ProductsKeyType = {
  // add: 'Add product to library',
  aiTagsUpdate: 'AI Tags Update',
  all: 'All products',
  associatedAssets: 'Associated assets',
  attachSelected: 'Attach selected products',
  categories: {
    architecturalPart: 'Architectural part',
    equipmentAndDevices: 'Equipment and devices',
    exterior: 'Exterior',
    homeDecor: 'Home decor',
    homeTextiles: 'Home textiles',
    kitchen: 'Kitchen',
    plants: 'Plants'
  },
  clearAllCustomTags: 'Clear all custom tags',
  clearProductCache: 'Clear product cache',
  clearProductsCache: 'Clear products cache',
  created: 'Created product',
  customTags: 'Custom tags',
  deleteProduct: 'Delete product',
  download: 'Download product',
  downloadModel: 'Download model',
  deleteWarning:
    'Are you sure you want to delete the product? This action cannot be undone.',
  addProduct: 'Add product',
  attach: 'Attach products',
  attachProduct: 'Attach product',
  category: 'Product category',
  editProduct: 'Edit product',
  my: 'My Products',
  name: 'Product name',
  noPlural: 'No products',
  oneOrMoreSkuWasNotFoundInProductLibrary:
    'One or more SKU was not found in product library',
  plural: 'Products',
  search: 'Search products',
  searchCustomTags: 'Search custom tags',
  searchProductsByNameOrSku: 'Search products by name or SKU',
  selected: 'Selected products',
  similar: 'Similar products',
  singular: 'Product',
  threeDStock: '3D Stock Products',
  viewAll: 'View all products'
};

export default productsKeys;
