import {
  FetchCacheItemSources,
  FetchItemGqlQuery,
  NanoID,
  UUID
} from '../../../../../../../../types';

import { fetchCacheItem } from '../../../baseActions/fetchCacheItem';
import { fetchFinItem } from '../../../baseActions/fetchFinItem';
import { fetchItem } from '../../../baseActions/fetchItem';
import { fetchReadItem } from '../../../baseActions/fetchReadItem';

interface FetchCacheItemOptions {
  query: FetchItemGqlQuery;
  source?: FetchCacheItemSources;
  targetApi?: 'api' | 'read_api' | 'fin_api' | 'cache_api';
  uuid: UUID | NanoID;
}

function handleFetchCacheItem({
  query,
  source = FetchCacheItemSources.MNESIA,
  uuid,
  targetApi = 'api'
}: FetchCacheItemOptions) {
  switch (targetApi) {
    case 'cache_api':
      return fetchCacheItem({
        query,
        uuid,
        source
      });
    case 'api':
      return fetchItem({
        query,
        uuid
      });
    case 'read_api':
      return fetchReadItem({
        query,
        uuid
      });
    case 'fin_api':
      return fetchFinItem({
        query,
        uuid
      });
  }
}

export default handleFetchCacheItem;
