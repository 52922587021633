import {
  FetchCacheItemSources,
  FetchItemGqlQuery,
  NanoID,
  UUID
} from '../../../../../../../types';

import { generateUuid } from '../../../../../../../utils/generateUuid';
import { getQueryName } from '../../../../../../../utils/getQueryName';
import { productionConsole } from '../../../../../../../utils/productionConsole';

import { graphQLCacheClient } from '../../graphQLClients';

interface FetchCacheItemProps {
  query: FetchItemGqlQuery;
  uuid: UUID | NanoID;
  source?: FetchCacheItemSources;
}

export function fetchCacheItem({
  query,
  uuid,
  source = FetchCacheItemSources.ES
}: FetchCacheItemProps) {
  const { rawName, name } = getQueryName(query);

  productionConsole(
    'log',
    'fetchCacheItem',
    { uuid, source },
    { rawName, name }
  );

  const requestId = generateUuid();

  graphQLCacheClient.addQueryParams(name, requestId);

  return graphQLCacheClient.request(query, { uuid, source }, { requestId });
}
