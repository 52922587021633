import { gql } from 'graphql-request';

import {
  CategoryID,
  CategoryLocalizedName,
  CategoryNameEn,
  CategoryName,
  CategoryParentID
} from '../../categories/categoriesTypes';

export interface FetchCategoriesSelectFieldQueryResponse {
  id: CategoryID;
  localizedName: CategoryLocalizedName;
  nameEn: CategoryNameEn;
  name: CategoryName;
  parentId: CategoryParentID;
  parent: {
    id: CategoryParentID;
  };
}

export const FETCH_CATEGORIES_SELECT_FIELD_QUERY = gql`
  query CategoriesSelectField(
    $filters: CategoriesFilters
    $sort: [CategoriesSortEnum!]
    $limit: Int
    $offset: Int
  ) {
    categories(filters: $filters, sort: $sort, limit: $limit, offset: $offset) {
      nodes {
        id
        localizedName
        nameEn
        name
        parentId
        parent {
          id
        }
      }
      sql
    }
  }
`;
