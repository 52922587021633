import { IconProps } from '../types';

function BookStackOutlineIcon({ className, id }: IconProps) {
  return (
    <svg
      id={id}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      className={className || 'h-5 w-5'}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M3 4h16.1s1.9.9 1.9 3-1.9 3-1.9 3H3s1.9-.9 1.9-3S3 4 3 4m18 10H4.9S3 14.9 3 17s1.9 3 1.9 3H21s-1.9-.9-1.9-3 1.9-3 1.9-3"
      />
    </svg>
  );
}

export default BookStackOutlineIcon;
