import {
  FetchCategoriesScopes,
  FetchCategoriesSortTypes
} from './categoriesTypes';

export const INITIAL_CATEGORIES_FILTERS = {};
export const INITIAL_CATEGORIES_PAGE = 1;
export const INITIAL_CATEGORIES_SORT = [
  FetchCategoriesSortTypes.CREATED_AT_DESC
];
export const INITIAL_CATEGORIES_LIMIT = 50;

export const productCategoriesScopes = [
  FetchCategoriesScopes.PRODUCTS_MISTAKE_TYPES,
  FetchCategoriesScopes.PRODUCTS_STATUSES,
  FetchCategoriesScopes.PRODUCTS_TAGS,
  FetchCategoriesScopes.PRODUCTS_TYPE,
  FetchCategoriesScopes.PRODUCTS_SEASONAL,
  FetchCategoriesScopes.PRODUCTS_FILE_VERSION_TAGS,
  FetchCategoriesScopes.PRODUCT_GROUPS,
  FetchCategoriesScopes.PRODUCT_COLLECTIONS,
  FetchCategoriesScopes.FACILITIES_RENDER_TYPES,
  FetchCategoriesScopes.FACILITY_STYLES,
  FetchCategoriesScopes.FACILITY_MATERIALS,
  FetchCategoriesScopes.FACILITY_COLORS,
  FetchCategoriesScopes.FACILITY_MANUFACTURERS
];

export const lifestyleCategoriesScopes = [
  FetchCategoriesScopes.LIFESTYLE_GROUPS,
  FetchCategoriesScopes.LIFESTYLES_TYPES,
  FetchCategoriesScopes.SCENE_STYLES,
  FetchCategoriesScopes.SCENE_MATERIALS,
  FetchCategoriesScopes.SCENE_MAIN_COLORS,
  FetchCategoriesScopes.SCENE_ACCENT_COLORS,
  FetchCategoriesScopes.SCENE_TONES,
  FetchCategoriesScopes.SCENE_GAMMAS,
  FetchCategoriesScopes.SCENE_PERFORMERS,
  FetchCategoriesScopes.SCENES_RENDER_TYPES
];

export enum CategoriesPermissions {
  READ_CATEGORIES_INDEX_PAGE = 'read_categories_index_page',
  READ_CATEGORIES_SEARCH_FILTERS_POPOVER = 'read_categories_search_filters_popover',
  READ_CATEGORIES_INDEX_ES_QUERY = 'read_categories_index_es_query',
  READ_PRODUCT_CATEGORIES_INDEX_ES_QUERY = 'read_product_categories_index_es_query',
  READ_LIFESTYLE_CATEGORIES_INDEX_ES_QUERY = 'read_lifestyle_categories_index_es_query'
}
