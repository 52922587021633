import { gql } from 'graphql-request';

import { MayBe } from '../../../types';

import {
  ProjectMemberUUID,
  ProjectMemberTrackedTasksTime,
  ProjectMemberTrackedTime,
  ProjectMemberCreatedAt,
  ProjectMemberUserID,
  ProjectMemberUserUUID,
  ProjectMemberUserFullName,
  ProjectMemberUserImageUUID,
  ProjectMemberUserImageFile,
  ProjectMemberProjectUuid,
  ProjectMemberNotificationsEnabled,
  ProjectMemberID,
  ProjectMemberProjectNanoID,
  ProjectMemberUserNanoID,
  ProjectMemberUserFinanceRoleName,
  ProjectMemberUserBlocked,
  ProjectMemberUserClient,
  ProjectMemberUserCurrentTeamNanoID,
  ProjectMemberTrackedTasksSeconds,
  ProjectMemberTrackedSeconds
} from '../projectMembersTypes';

export interface FetchProjectMembersQueryResponse {
  id: ProjectMemberID;
  uuid: ProjectMemberUUID;
  trackedTasksTime: ProjectMemberTrackedTasksTime;
  trackedTime: ProjectMemberTrackedTime;
  trackedTasksSecond: ProjectMemberTrackedTasksSeconds;
  trackedSecond: ProjectMemberTrackedSeconds;
  createdAt: ProjectMemberCreatedAt;
  notificationsEnabled: ProjectMemberNotificationsEnabled;
  user: {
    id: ProjectMemberUserID;
    uuid: ProjectMemberUserUUID;
    nanoId: ProjectMemberUserNanoID;
    fullName: ProjectMemberUserFullName;
    financeRoleName: ProjectMemberUserFinanceRoleName;
    blocked: ProjectMemberUserBlocked;
    client: ProjectMemberUserClient;
    currentTeam: MayBe<{
      nanoId: ProjectMemberUserCurrentTeamNanoID;
    }>;
    image: {
      uuid: ProjectMemberUserImageUUID;
      file: ProjectMemberUserImageFile;
    };
  };
  project: {
    uuid: ProjectMemberProjectUuid;
    nanoId: ProjectMemberProjectNanoID;
  };
}

export const FETCH_PROJECT_MEMBERS_QUERY = gql`
  query ProjectMembers(
    $filters: ProjectMembersFilters
    $sort: [ProjectMembersSortEnum!]
    $limit: Int
    $offset: Int
  ) {
    projectMembers(
      filters: $filters
      sort: $sort
      limit: $limit
      offset: $offset
    ) {
      nodes {
        id
        uuid
        trackedTasksTime
        trackedTime
        trackedTasksSeconds
        trackedSeconds
        createdAt
        notificationsEnabled
        user {
          id
          uuid
          nanoId
          fullName
          financeRoleName
          blocked
          client
          currentTeam {
            nanoId
          }
          image {
            uuid
            file
          }
        }
        project {
          uuid
          nanoId
        }
      }
      paginationInfo {
        nextPage
        totalCount
      }
      sql
    }
  }
`;
