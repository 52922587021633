import {
  FetchLifestyleCategoriesCacheKey,
  FetchFinLifestyleCategoriesFilters
} from '../../lifestylesTypes';

import {
  FetchLifestyleGammasQueryResponse,
  FETCH_LIFESTYLE_GAMMAS_QUERY
} from '../../queries/fetchLifestyleGammas.query';
import { CACHE_FETCH_LIFESTYLE_GAMMAS_QUERY } from '../../queries/fetchCacheLifestyleGammas.query';

import { useFinPaginatedCategories } from '../../../categories/hooks/useFinPaginatedCategories';

import { FetchCategoriesScopes } from '../../../categories/categoriesTypes';
import { CategoriesPermissions } from '../../../categories/categoriesConstants';

const defaultInitialFilters = {
  scope: { eq: FetchCategoriesScopes.SCENE_GAMMAS }
};
const options = {
  staleTime: 1000 * 60 * 60
};

interface LifestyleGammasDefaultOptions {
  cacheKey: FetchLifestyleCategoriesCacheKey;
  addInitialFilters?: FetchFinLifestyleCategoriesFilters;
}

function useLifestyleGammas({
  cacheKey,
  addInitialFilters
}: LifestyleGammasDefaultOptions) {
  const initialFilters = addInitialFilters
    ? { ...defaultInitialFilters, ...addInitialFilters }
    : defaultInitialFilters;

  const {
    categories,
    categoriesError,
    categoriesFetched,
    categoriesIsPlaceholderData
  } = useFinPaginatedCategories<FetchLifestyleGammasQueryResponse>({
    action: CategoriesPermissions.READ_LIFESTYLE_CATEGORIES_INDEX_ES_QUERY,
    cacheKey,
    query: FETCH_LIFESTYLE_GAMMAS_QUERY,
    cacheQuery: CACHE_FETCH_LIFESTYLE_GAMMAS_QUERY,
    initialFilters,
    options
  });

  return {
    lifestyleGammas: categories,
    lifestyleGammasErrorMessage: categoriesError,
    lifestyleGammasFetched: categoriesFetched,
    lifestyleGammasIsPlaceholderData: categoriesIsPlaceholderData
  };
}

export default useLifestyleGammas;
