import { gql } from 'graphql-request';

import {
  CategoryID,
  CategoryUUID,
  CategoryName,
  CategoryLocalizedName
} from '../../categories/categoriesTypes';

export interface FetchCacheProductMaterialsQueryResponse {
  uuid: CategoryUUID;
  id: CategoryID;
  name: CategoryName;
  localizedName: CategoryLocalizedName;
}

export const FETCH_CACHE_PRODUCT_MATERIALS_QUERY = gql`
  query CacheProductMaterialsCategories(
    $filters: CategoriesFilters
    $sort: [CategoriesSortEnum!]
    $limit: Int
    $offset: Int
    $source: String
    $trackTotalHits: Boolean
  ) {
    categories(
      filters: $filters
      sort: $sort
      limit: $limit
      offset: $offset
      source: $source
      trackTotalHits: $trackTotalHits
    ) {
      nodes {
        uuid
        id
        name
        localizedName
      }
      sql
    }
  }
`;
