import { useMemo } from 'react';
import compact from 'lodash/compact';
import map from 'lodash/map';
import mapValues from 'lodash/mapValues';

import {
  ProformaInvoiceFormGroupByMode,
  ProformaInvoiceFormViewMode
} from '../../../../../teams/components/forms/ProformaInvoiceForm/ProformaInvoiceForm.types';
import { MultiSelectDataType } from '../../../../../../helpers/MultiSelect/types';

import { useCurrentUser } from '../../../../../../auth/hooks/useAuth';
import { useTranslate } from '../../../../../../common/hooks/useTranslate';

import { InvoicesPermissions } from '../../../../invoicesConstants';
import {
  categoriesKeys,
  invoicesKeys,
  projectsKeys,
  tasksKeys
} from '../../../../../../locales/keys';

function useInvoiceItemsViewViewModes() {
  const { t } = useTranslate();

  const currentUser = useCurrentUser();

  const invoiceItemsViewViewModeOptions = useMemo(
    () => ({
      [ProformaInvoiceFormViewMode.INVOICES]: [
        [
          {
            value: ProformaInvoiceFormGroupByMode.TASK,
            i18nLabel: tasksKeys.singularLowerCase
          },
          {
            value: ProformaInvoiceFormGroupByMode.PROJECT,
            i18nLabel: projectsKeys.singularLowerCase
          },
          {
            value: ProformaInvoiceFormGroupByMode.PROJECT_TASK,
            i18nLabel: invoicesKeys.grouping.projectTask
          }
        ],
        [
          {
            value: ProformaInvoiceFormGroupByMode.MONTH_INVOICE_CREATED,
            i18nLabel: invoicesKeys.grouping.monthInvoiceCreated
          }
        ],
        [
          {
            value: ProformaInvoiceFormGroupByMode.INVOICE_TAG,
            i18nLabel: invoicesKeys.grouping.invoiceTag
          }
        ]
      ],
      [ProformaInvoiceFormViewMode.TASKS]: [
        [
          {
            value: ProformaInvoiceFormGroupByMode.PROJECT,
            i18nLabel: projectsKeys.singularLowerCase
          }
        ],
        [
          // {
          //   value: ProformaInvoiceFormGroupByMode.PROJECT_AND_TASK_VERSIONS,
          //   i18nLabel: invoicesKeys.grouping.projectAndTaskVersions
          // },
          {
            value: ProformaInvoiceFormGroupByMode.MONTH_TASK_CREATED,
            i18nLabel: invoicesKeys.grouping.monthTaskCreated
          },
          {
            value: ProformaInvoiceFormGroupByMode.MONTH_TASK_DONE,
            i18nLabel: invoicesKeys.grouping.monthTaskDone
          }
        ],
        [
          {
            value: ProformaInvoiceFormGroupByMode.INVOICE_TAG,
            i18nLabel: invoicesKeys.grouping.invoiceTag
          }
        ]
      ],
      [ProformaInvoiceFormViewMode.PROJECTS]: [
        compact([
          {
            value: ProformaInvoiceFormGroupByMode.MONTH_PROJECT_CREATED,
            i18nLabel: invoicesKeys.grouping.monthProjectCreated
          },
          currentUser.hasPermissions(
            InvoicesPermissions.READ_PAYMENTS_INVOICES_PROJECT_CLOSURE_MONTH_GROUPING
          )
            ? {
                value: ProformaInvoiceFormGroupByMode.MONTH_PROJECT_CLOSED,
                i18nLabel: invoicesKeys.grouping.monthProjectClosed
              }
            : null
        ]),
        [
          {
            value: ProformaInvoiceFormGroupByMode.INVOICE_TAG,
            i18nLabel: invoicesKeys.grouping.invoiceTag
          }
        ]
      ],
      [ProformaInvoiceFormViewMode.CATEGORIES]: [
        [
          {
            value: ProformaInvoiceFormGroupByMode.PROJECT,
            i18nLabel: projectsKeys.singularLowerCase
          }
        ],
        [
          {
            value: ProformaInvoiceFormGroupByMode.INVOICE_TAG,
            i18nLabel: invoicesKeys.grouping.invoiceTag
          }
        ]
      ]
    }),
    [currentUser]
  );

  const viewModeOptions = useMemo<MultiSelectDataType[]>(
    () =>
      compact([
        {
          value: ProformaInvoiceFormViewMode.INVOICES,
          label: t(invoicesKeys.pluralLowerCase)
        },
        {
          value: ProformaInvoiceFormViewMode.TASKS,
          label: t(tasksKeys.pluralLowerCase)
        },
        {
          value: ProformaInvoiceFormViewMode.PROJECTS,
          label: t(projectsKeys.pluralLowerCase)
        },
        {
          value: ProformaInvoiceFormViewMode.CATEGORIES,
          label: t(categoriesKeys.pluralLowerCase)
        }
      ]),
    [t]
  );

  const groupByOptions = useMemo(
    () =>
      mapValues(invoiceItemsViewViewModeOptions, (groups) =>
        compact(
          map(groups, (opts) => {
            const options = compact(
              map(opts, (opt) => ({
                value: opt.value,
                label: t(opt.i18nLabel)
              }))
            );

            if (!options.length) return null;

            return {
              label: '',
              options
            };
          })
        )
      ),
    [invoiceItemsViewViewModeOptions, t]
  );

  return {
    viewModeOptions,
    groupByOptions
  };
}

export default useInvoiceItemsViewViewModes;
