import React, { useState } from 'react';

import { MaterialsListItemUploadedMaterial } from './MaterialsListItemUploaded.types';

import { Tooltip } from '../../../../../../../helpers/tooltips/Tooltip';
import { TooltipPlacement } from '../../../../../../../helpers/tooltips/tooltipsConstants';
import { DateHelper } from '../../../../../../../helpers/DateHelper';
import { Translate } from '../../../../../../../helpers/Translate';

import { words } from '../../../../../../../locales/keys';

interface MaterialsListItemUploadedProps {
  material: MaterialsListItemUploadedMaterial;
}

function MaterialsListItemUploaded({
  material
}: MaterialsListItemUploadedProps) {
  const [referenceTooltipElement, setReferenceTooltipElement] =
    useState<HTMLSpanElement | null>(null);

  if (!material.maxFile?.createdAt) {
    return null;
  }

  return (
    <div
      className="text-gray-600 dark:text-gray-500 text-2xs truncate"
      ref={setReferenceTooltipElement}
    >
      <span>
        <Translate id={words.uploaded} />
        {': '}
        <DateHelper
          date={material.maxFile.createdAt}
          // customFormat={getCustomDateFormat(material.maxFile.createdAt)}
          customFormat="MMM dd, yyyy, h:mm a"
        />
      </span>

      <Tooltip
        withArrow
        placement={TooltipPlacement.TOP}
        referenceElement={referenceTooltipElement}
      >
        <DateHelper date={material.maxFile.createdAt} />
      </Tooltip>
    </div>
  );
}

export default MaterialsListItemUploaded;
