import { ProductUUID } from '../../../../../products/productsTypes';
import {
  SelectedProductCreatedAt,
  SelectedProductUpdatedAt,
  SelectedProductDeletedAt,
  SelectedProductID,
  SelectedProductUUID
} from '../../../../../selectedProducts/selectedProductsTypes';

import { generateNanoId } from '../../../../../../utils/generateNanoId';

import { TEMP_SELECTED_PRODUCT_LAST_ID } from '../../../../productsSetsConstants';

interface TempSelectedProductProduct {
  uuid: ProductUUID;
}

interface TempSelectedProductResult {
  id: SelectedProductID;
  uuid: SelectedProductUUID;
  createdAt: SelectedProductCreatedAt;
  updatedAt: SelectedProductUpdatedAt;
  deletedAt: SelectedProductDeletedAt;
  product: TempSelectedProductProduct;
}

class TempSelectedProduct {
  static lastId = TEMP_SELECTED_PRODUCT_LAST_ID;

  static create(
    product: TempSelectedProductProduct
  ): TempSelectedProductResult {
    const jsonDateNow = JSON.stringify(new Date());
    return {
      product,
      id: `${TempSelectedProduct.lastId++}`,
      uuid: generateNanoId() as SelectedProductUUID,
      createdAt: jsonDateNow,
      updatedAt: jsonDateNow,
      deletedAt: null
    };
  }
}

export default TempSelectedProduct;
