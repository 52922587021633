import { gql } from 'graphql-request';

import {
  CategoryID,
  CategoryUUID,
  CategoryName
} from '../../categories/categoriesTypes';

export interface FetchMaterialBrandsQueryResponse {
  uuid: CategoryUUID;
  id: CategoryID;
  name: CategoryName;
}

export const FETCH_MATERIAL_BRANDS_QUERY = gql`
  query MaterialBrandsCategories(
    $filters: CategoriesFilters
    $sort: [CategoriesSortEnum!]
    $limit: Int
    $offset: Int
  ) {
    categories(filters: $filters, sort: $sort, limit: $limit, offset: $offset) {
      nodes {
        uuid
        id
        name
      }
      paginationInfo {
        currentPage
        nextPage
        totalCount
      }
      sql
    }
  }
`;
