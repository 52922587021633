import { gql } from 'graphql-request';

import { MayBe } from '../../../../../../types';

import {
  UserFinanceRoleName,
  UserFullName,
  UserID,
  UserImageFile,
  UserImageUUID,
  UserNanoID,
  UserUUID,
  UserClient,
  UserCurrentTeamNanoID
} from '../../../../../../main/users/usersTypes';

export interface FetchItemTeamUsersQueryResponse {
  fullName: UserFullName;
  financeRoleName: UserFinanceRoleName;
  id: UserID;
  client: UserClient;
  currentTeam: MayBe<{
    nanoId: UserCurrentTeamNanoID;
  }>;
  image: {
    uuid: UserImageUUID;
    file: UserImageFile;
  } | null;
  uuid: UserUUID;
  nanoId: UserNanoID;
}

export const FETCH_ITEM_TEAM_USERS_QUERY = gql`
  query ItemTeamUsers(
    $filters: UsersFilters
    $sort: [UsersSortEnum!]
    $limit: Int
    $offset: Int
  ) {
    users(filters: $filters, sort: $sort, offset: $offset, limit: $limit) {
      paginationInfo {
        nextPage
        totalCount
      }
      nodes {
        id
        uuid
        nanoId
        fullName
        financeRoleName
        client
        currentTeam {
          nanoId
        }
        image {
          uuid
          file
        }
      }
      sql
    }
  }
`;
