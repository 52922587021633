import {
  FetchLifestyleCategoriesCacheKey,
  FetchFinLifestyleCategoriesFilters
} from '../../lifestylesTypes';

import {
  FETCH_LIFESTYLE_CATEGORIES_QUERY,
  FetchLifestyleCategoriesQueryResponse
} from '../../queries/fetchLifestyleCategories.query';
import { CACHE_FETCH_LIFESTYLE_CATEGORIES_QUERY } from '../../queries/fetchCacheLifestyleCategories.query';

import { useFinPaginatedCategories } from '../../../categories/hooks/useFinPaginatedCategories';

import {
  FetchCategoriesScopes,
  FetchCategoriesSortTypes
} from '../../../categories/categoriesTypes';

import { CategoriesPermissions } from '../../../categories/categoriesConstants';

const defaultInitialFilters = {
  scope: { eq: FetchCategoriesScopes.LIFESTYLE_GROUPS }
};
const initialSort = [FetchCategoriesSortTypes.NAME_ASC];
const options = {
  staleTime: 1000 * 60 * 60
};

interface LifestyleCategoriesOptions {
  cacheKey?: FetchLifestyleCategoriesCacheKey;
  addInitialFilters?: FetchFinLifestyleCategoriesFilters;
}

function useLifestyleCategories({
  cacheKey,
  addInitialFilters
}: LifestyleCategoriesOptions = {}) {
  const initialFilters = addInitialFilters
    ? { ...defaultInitialFilters, ...addInitialFilters }
    : defaultInitialFilters;

  const {
    categories,
    categoriesError,
    categoriesFetched,
    categoriesIsPlaceholderData
  } = useFinPaginatedCategories<FetchLifestyleCategoriesQueryResponse>({
    action: CategoriesPermissions.READ_LIFESTYLE_CATEGORIES_INDEX_ES_QUERY,
    query: FETCH_LIFESTYLE_CATEGORIES_QUERY,
    cacheQuery: CACHE_FETCH_LIFESTYLE_CATEGORIES_QUERY,
    trackTotalHits: true,
    cacheKey,
    initialFilters,
    initialSort,
    initialLimit: 1000,
    options
  });

  return {
    lifestyleCategories: categories,
    lifestyleCategoriesErrorMessage: categoriesError,
    lifestyleCategoriesFetched: categoriesFetched,
    lifestyleCategoriesIsPlaceholderData: categoriesIsPlaceholderData
  };
}

export default useLifestyleCategories;
