import { useCallback, useEffect, useMemo, useReducer } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import isEqual from 'lodash/isEqual';

import {
  FetchCacheItemSources,
  FetchCacheItemsSources,
  FetchItemsFilters,
  FetchItemsLimit,
  FetchItemsPage,
  FetchItemsSort,
  UUID
} from '../../../../../../types';

import { IndexQueryResponse } from '../useIndexQuery';
import {
  indexRequestReducer,
  IndexRequestReducerType
} from '../useIndexQuery/reducers/indexRequestReducer';
import {
  CacheIndexQueryErrorType,
  CacheIndexQueryOptions
} from './useCacheIndexQuery.types';

import { useCurrentUser } from '../../../../../../auth/hooks/useAuth';
import { useUpdateIndexQueryItemCache } from '../useIndexQuery/hooks/useUpdateIndexQueryItemCache';
import { useUpdateIndexQueryItemsCache } from '../useIndexQuery/hooks/useUpdateIndexQueryItemsCache';

import { changeItemsFiltersAction } from '../useIndexQuery/actions/changeItemsFiltersAction';
import { clearItemsFiltersAction } from '../useIndexQuery/actions/clearItemsFiltersAction';
import { filterItemsAction } from '../useIndexQuery/actions/filterItemsAction';
import { limitItemsAction } from '../useIndexQuery/actions/limitItemsAction';
import { paginateItemsAction } from '../useIndexQuery/actions/paginateItemsAction';
import { sortItemsAction } from '../useIndexQuery/actions/sortItemsAction';

import { handleFetchCacheItems } from './utils/handleFetchCacheItems';
import { handleFetchCacheItem } from './utils/handleFetchCacheItem';
import { LocalForage } from '../../../../../../utils/LocalForage';
import { parseRequestError } from '../../../../../../utils/parseRequestError';

import {
  INITIAL_FILTERS,
  INITIAL_LIMIT,
  INITIAL_PAGE,
  INITIAL_SORT
} from '../useIndexQuery/indexRequestConstants';

import { CommonPermissions } from '../../../../commonConstants';

function useCacheIndexQuery<NodeType, MetaType = undefined>({
  action,
  additionalParams,
  cacheKey,
  defaultApi = 'read_api',
  fetchCacheItemSource = FetchCacheItemSources.MNESIA,
  fetchItemCacheKey,
  fetchItemQuery,
  fetchCacheItemQuery,
  initialFilters = INITIAL_FILTERS,
  initialLimit = INITIAL_LIMIT,
  initialPage = INITIAL_PAGE,
  initialSort = INITIAL_SORT,
  options = {},
  query,
  cacheQuery,
  scope,
  source = FetchCacheItemsSources.ES,
  trackTotalHits = false
}: CacheIndexQueryOptions<NodeType, MetaType>) {
  const currentUser = useCurrentUser();

  const localForageCacheKey = `${cacheKey}-index`;

  const targetApi =
    currentUser?.hasPermissions(action) &&
    currentUser.hasPermissions(CommonPermissions.READ_CACHE_QUERY) &&
    cacheQuery
      ? 'cache_api'
      : defaultApi;

  const currentQuery = targetApi === 'cache_api' ? cacheQuery : query;

  const currentFetchItemQuery =
    targetApi === 'cache_api' ? fetchCacheItemQuery : fetchItemQuery;

  const [{ currentFilters, currentLimit, currentPage, currentSort }, dispatch] =
    useReducer<IndexRequestReducerType>(indexRequestReducer, {
      currentPage: initialPage,
      currentLimit: initialLimit,
      currentFilters: initialFilters,
      currentSort: initialSort
    });

  const { data: placeholderData, isFetched: placeholderDataFetched } =
    useQuery<IndexQueryResponse<NodeType, MetaType> | null>(
      `${cacheKey}-placeholder`,
      () =>
        LocalForage.getItem<IndexQueryResponse<NodeType, MetaType>>(
          localForageCacheKey
        ),
      { enabled: options.enabledPlaceholder }
    );

  const currentParams = useMemo(
    () => ({
      filters: currentFilters,
      sort: currentSort,
      page: currentPage,
      limit: currentLimit,
      ...additionalParams
    }),
    [currentFilters, currentSort, currentPage, currentLimit, additionalParams]
  );

  const fullCacheKey = useMemo(() => {
    return [cacheKey, currentParams];
  }, [cacheKey, currentParams]);

  const { data, isFetched, isLoading, error, isPlaceholderData } = useQuery<
    IndexQueryResponse<NodeType, MetaType>,
    CacheIndexQueryErrorType
  >(
    fullCacheKey,
    () =>
      handleFetchCacheItems({
        query: currentQuery,
        source,
        targetApi,
        trackTotalHits,
        ...currentParams
      }),
    {
      enabled:
        options.enabled ||
        (options.enabledPlaceholder && placeholderDataFetched),
      cacheTime: options.cacheTime,
      staleTime: options.staleTime,
      refetchInterval: options.refetchInterval,
      keepPreviousData: options.keepPreviousData,
      refetchIntervalInBackground: options.refetchIntervalInBackground,
      onSuccess: (data) => {
        options.onSuccess?.(data);
        if (
          isEqual(currentFilters, initialFilters) &&
          isEqual(currentSort, initialSort) &&
          currentPage === initialPage &&
          currentLimit === initialLimit
        ) {
          return LocalForage.setItem<IndexQueryResponse<NodeType, MetaType>>(
            localForageCacheKey,
            data
          );
        }
      },
      placeholderData: () => {
        if (
          placeholderData &&
          isEqual(currentFilters, initialFilters) &&
          isEqual(currentSort, initialSort) &&
          currentPage === initialPage &&
          currentLimit === initialLimit
        ) {
          return placeholderData;
        }
      }
    }
  );

  const queryClient = useQueryClient();

  const queryResponseValue = data?.[scope];

  useEffect(() => {
    if (options.withoutPrefetch) {
      return;
    }

    const params = {
      filters: currentFilters,
      sort: currentSort,
      page: currentPage + 1,
      limit: currentLimit,
      ...additionalParams
    };

    queryClient.prefetchQuery([cacheKey, params], () =>
      handleFetchCacheItems({
        query: currentQuery,
        source,
        targetApi,
        trackTotalHits,
        ...params
      })
    );
  }, [
    cacheKey,
    queryClient,
    currentQuery,
    currentFilters,
    currentSort,
    currentPage,
    currentLimit,
    queryResponseValue?.paginationInfo?.nextPage,
    options.withoutPrefetch,
    additionalParams,
    targetApi,
    source,
    trackTotalHits
  ]);

  const nodesTotalCount = queryResponseValue?.nodes?.length
    ? queryResponseValue?.paginationInfo?.totalCount ||
      placeholderData?.[scope]?.paginationInfo?.totalCount ||
      0
    : null;

  const isLoadingTotalCount = isLoading
    ? placeholderData?.[scope]?.paginationInfo?.totalCount
    : null;

  const defaultTotalCount = 0;

  const itemsTotalCount =
    nodesTotalCount || isLoadingTotalCount || defaultTotalCount;

  const updateItemCache = useUpdateIndexQueryItemCache<NodeType>({
    fullCacheKey,
    scope
  });

  const updateItemsCache = useUpdateIndexQueryItemsCache<NodeType>({
    fullCacheKey
  });

  return {
    data,
    items: queryResponseValue?.nodes || <NodeType[]>[],
    itemsError: parseRequestError(error),
    itemsErrorMessage: parseRequestError(error),
    itemsTotalCount,
    meta: queryResponseValue?.meta || <MetaType>{},
    isFetched,
    isLoading,
    isPlaceholderData,
    currentFilters,
    currentSort,
    currentPage,
    currentLimit,
    updateItemCache,
    updateItemsCache,
    filterItems: useCallback(
      (nextFilters: FetchItemsFilters) =>
        dispatch(filterItemsAction(nextFilters)),
      [dispatch]
    ),
    changeItemsFilters: useCallback(
      (
        changedFilters: Partial<FetchItemsFilters>,
        removeFilters: string[] = []
      ) => dispatch(changeItemsFiltersAction(changedFilters, removeFilters)),
      [dispatch]
    ),
    clearItemsFiltersPersistInitial: useCallback(
      () => dispatch(filterItemsAction(initialFilters)),
      [dispatch, initialFilters]
    ),
    clearItemsFilters: useCallback(
      () => dispatch(clearItemsFiltersAction()),
      [dispatch]
    ),
    sortItems: useCallback(
      (nextSort: FetchItemsSort) => dispatch(sortItemsAction(nextSort)),
      [dispatch]
    ),
    paginateItems: useCallback(
      (nextPage: FetchItemsPage) => dispatch(paginateItemsAction(nextPage)),
      [dispatch]
    ),
    limitItems: useCallback(
      (nextLimit: FetchItemsLimit) => dispatch(limitItemsAction(nextLimit)),
      [dispatch]
    ),
    prefetchItems: useCallback(
      ({
        nextFilters,
        nextSort,
        nextPage,
        nextLimit
      }: {
        nextFilters?: FetchItemsFilters;
        nextSort?: FetchItemsSort;
        nextPage?: FetchItemsPage;
        nextLimit?: FetchItemsLimit;
      }) => {
        const params = {
          filters: nextFilters || currentFilters,
          sort: nextSort || currentSort,
          page: nextPage || currentPage,
          limit: nextLimit || currentLimit
        };
        return queryClient.prefetchQuery([cacheKey, params], () =>
          handleFetchCacheItems({
            query: currentQuery,
            source,
            targetApi,
            trackTotalHits,
            ...params
          })
        );
      },
      [
        currentFilters,
        currentSort,
        currentPage,
        currentLimit,
        queryClient,
        cacheKey,
        currentQuery,
        targetApi
      ]
    ),
    prefetchItem: useCallback(
      (itemUuid: UUID) =>
        queryClient.prefetchQuery(
          [fetchItemCacheKey, itemUuid],
          () =>
            handleFetchCacheItem({
              query: currentFetchItemQuery,
              source: fetchCacheItemSource,
              targetApi,
              uuid: itemUuid
            }),
          { staleTime: 5000 }
        ),
      [
        queryClient,
        fetchItemCacheKey,
        currentFetchItemQuery,
        fetchCacheItemSource,
        targetApi
      ]
    )
  };
}

export default useCacheIndexQuery;
