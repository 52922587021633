import { IconProps } from '../types';

function BagAddOutlineIcon({ className, id }: IconProps) {
  return (
    <svg
      id={id}
      xmlns="http://www.w3.org/2000/svg"
      className={className || 'h-5 w-5'}
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
    >
      <path
        strokeLinejoin="round"
        strokeLinecap="round"
        d="M20.4 13l-.3-5.6c-.1-.9-.5-1.7-1.2-2.2l-1.8-1.4c-.7-.5-1.6-.8-2.4-.8H9.3c-.8 0-1.7.3-2.4.8L5.1 5.2c-.7.5-1.1 1.3-1.2 2.2l-.8 11.5c0 1.1.9 2.1 2 2.1H13m6.6-15H4.4M15 9c0 1.7-1.3 3-3 3s-3-1.3-3-3m9 6v6m-3-3h6"
      />
    </svg>
  );
}

export default BagAddOutlineIcon;
