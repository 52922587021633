import {
  FetchLifestyleCategoriesCacheKey,
  FetchFinLifestyleCategoriesFilters
} from '../../lifestylesTypes';

import {
  FetchLifestyleStylesQueryResponse,
  FETCH_LIFESTYLE_STYLES_QUERY
} from '../../queries/fetchLifestyleStyles.query';
import { CACHE_FETCH_LIFESTYLE_STYLES_QUERY } from '../../queries/fetchCacheLifestyleStyles.query';

import { useFinPaginatedCategories } from '../../../categories/hooks/useFinPaginatedCategories';

import { FetchCategoriesScopes } from '../../../categories/categoriesTypes';
import { CategoriesPermissions } from '../../../categories/categoriesConstants';

const defaultInitialFilters = {
  scope: { eq: FetchCategoriesScopes.SCENE_STYLES }
};
const options = {
  staleTime: 1000 * 60 * 60
};

interface LifestyleStylesDefaultOptions {
  cacheKey: FetchLifestyleCategoriesCacheKey;
  addInitialFilters?: FetchFinLifestyleCategoriesFilters;
}

function useLifestyleStyles({
  cacheKey,
  addInitialFilters
}: LifestyleStylesDefaultOptions) {
  const initialFilters = addInitialFilters
    ? { ...defaultInitialFilters, ...addInitialFilters }
    : defaultInitialFilters;

  const {
    categories,
    categoriesError,
    categoriesFetched,
    categoriesIsPlaceholderData
  } = useFinPaginatedCategories<FetchLifestyleStylesQueryResponse>({
    action: CategoriesPermissions.READ_LIFESTYLE_CATEGORIES_INDEX_ES_QUERY,
    cacheKey,
    query: FETCH_LIFESTYLE_STYLES_QUERY,
    cacheQuery: CACHE_FETCH_LIFESTYLE_STYLES_QUERY,
    initialFilters,
    options
  });

  return {
    lifestyleStyles: categories,
    lifestyleStylesErrorMessage: categoriesError,
    lifestyleStylesFetched: categoriesFetched,
    lifestyleStylesIsPlaceholderData: categoriesIsPlaceholderData
  };
}

export default useLifestyleStyles;
