import { LifestyleUUID } from '../../../../../lifestyles/lifestylesTypes';
import {
  SelectedLifestyleCreatedAt,
  SelectedLifestyleUpdatedAt,
  SelectedLifestyleDeletedAt,
  SelectedLifestyleID,
  SelectedLifestyleUUID
} from '../../../../../selectedLifestyles/selectedLifestylesTypes';

import { generateNanoId } from '../../../../../../utils/generateNanoId';

import { TEMP_SELECTED_LIFESTYLE_LAST_ID } from '../../../../lifestylesSetsConstants';

interface TempSelectedLifestyleLifestyle {
  uuid: LifestyleUUID;
}

interface TempSelectedLifestyleResult {
  id: SelectedLifestyleID;
  uuid: SelectedLifestyleUUID;
  createdAt: SelectedLifestyleCreatedAt;
  updatedAt: SelectedLifestyleUpdatedAt;
  deletedAt: SelectedLifestyleDeletedAt;
  lifestyle: TempSelectedLifestyleLifestyle;
}

class TempSelectedLifestyle {
  static lastId = TEMP_SELECTED_LIFESTYLE_LAST_ID;

  static create(
    lifestyle: TempSelectedLifestyleLifestyle
  ): TempSelectedLifestyleResult {
    const jsonDateNow = JSON.stringify(new Date());
    return {
      lifestyle,
      id: `${TempSelectedLifestyle.lastId++}`,
      uuid: generateNanoId() as SelectedLifestyleUUID,
      createdAt: jsonDateNow,
      updatedAt: jsonDateNow,
      deletedAt: null
    };
  }
}

export default TempSelectedLifestyle;
