import { gql } from 'graphql-request';

export const GET_CATEGORIES_QUERY = gql`
  query IndexCategories(
    $filters: CategoriesFilters
    $sort: [CategoriesSortEnum!]
    $limit: Int
    $offset: Int
  ) {
    categories(filters: $filters, sort: $sort, limit: $limit, offset: $offset) {
      nodes {
        id
        uuid
        name
        nameEn
        nameUk
        order
        pdfExport
        categoriesCount
        blocked
        section {
          id
          uuid
          name
        }
        parent {
          id
          uuid
          localizedName
        }
        user {
          uuid
          fullName
          image {
            uuid
            file
          }
        }
        createdAt
        updatedAt
      }
      paginationInfo {
        nextPage
        totalCount
      }
      sql
    }
  }
`;
