import {
  ProformaInvoiceFormGroupByMode,
  ProformaInvoiceFormViewMode
} from '../../ProformaInvoiceForm.types';
import { GroupedItemsGroupBy } from '../../../../../../groupedItems/groupedItemsTypes';

function getGroupByFromViewMode(
  viewMode: ProformaInvoiceFormViewMode,
  groupByMode: ProformaInvoiceFormGroupByMode,
  enableGrouping: boolean
) {
  const groupBy: Set<GroupedItemsGroupBy> = new Set();

  switch (viewMode) {
    case ProformaInvoiceFormViewMode.INVOICES:
      groupBy.add(GroupedItemsGroupBy.ITEM);
      break;

    case ProformaInvoiceFormViewMode.TASKS:
      groupBy.add(GroupedItemsGroupBy.TASK);
      break;

    case ProformaInvoiceFormViewMode.PROJECTS:
      groupBy.add(GroupedItemsGroupBy.PROJECT);
      break;

    case ProformaInvoiceFormViewMode.CATEGORIES:
      groupBy.add(GroupedItemsGroupBy.CATEGORY);
      break;
  }

  if (!enableGrouping) {
    return Array.from(groupBy);
  }

  switch (groupByMode) {
    case ProformaInvoiceFormGroupByMode.SPLIT:
      groupBy.add(GroupedItemsGroupBy.SPLIT);
      break;

    case ProformaInvoiceFormGroupByMode.TASK:
      groupBy.add(GroupedItemsGroupBy.TASK);
      break;

    case ProformaInvoiceFormGroupByMode.PROJECT:
      groupBy.add(GroupedItemsGroupBy.PROJECT);
      break;

    case ProformaInvoiceFormGroupByMode.PROJECT_TASK:
      groupBy.add(GroupedItemsGroupBy.PROJECT);
      groupBy.add(GroupedItemsGroupBy.TASK);
      break;

    case ProformaInvoiceFormGroupByMode.BATCH:
      groupBy.add(GroupedItemsGroupBy.BATCH);
      break;

    case ProformaInvoiceFormGroupByMode.BATCH_TASK:
      groupBy.add(GroupedItemsGroupBy.BATCH);
      groupBy.add(GroupedItemsGroupBy.TASK);
      break;

    case ProformaInvoiceFormGroupByMode.MONTH_INVOICE_CREATED:
      groupBy.add(GroupedItemsGroupBy.INVOICE_CREATION_MONTH);
      break;

    case ProformaInvoiceFormGroupByMode.MONTH_TASK_CREATED_TASK:
      groupBy.add(GroupedItemsGroupBy.TASK);
      groupBy.add(GroupedItemsGroupBy.DATE);
      break;

    case ProformaInvoiceFormGroupByMode.MONTH_TASK_DUE_DATE_TASK:
      groupBy.add(GroupedItemsGroupBy.TASK);
      groupBy.add(GroupedItemsGroupBy.TASK_DUE_MONTH);
      break;

    case ProformaInvoiceFormGroupByMode.MONTH_PROJECT_CREATED_PROJECT:
      groupBy.add(GroupedItemsGroupBy.PROJECT);
      groupBy.add(GroupedItemsGroupBy.DATE);
      break;

    case ProformaInvoiceFormGroupByMode.MONTH_TASK_CREATED:
      groupBy.add(GroupedItemsGroupBy.TASK);
      groupBy.add(GroupedItemsGroupBy.DATE);
      break;

    case ProformaInvoiceFormGroupByMode.MONTH_TASK_DUE_DATE:
      groupBy.add(GroupedItemsGroupBy.TASK);
      groupBy.add(GroupedItemsGroupBy.TASK_DUE_MONTH);
      break;

    case ProformaInvoiceFormGroupByMode.MONTH_PROJECT_CREATED:
      groupBy.add(GroupedItemsGroupBy.PROJECT);
      groupBy.add(GroupedItemsGroupBy.DATE);
      break;

    case ProformaInvoiceFormGroupByMode.MONTH_PROJECT_CLOSED:
      groupBy.add(GroupedItemsGroupBy.PROJECT);
      groupBy.add(GroupedItemsGroupBy.PROJECT_CLOSURE_MONTH);
      break;

    case ProformaInvoiceFormGroupByMode.INVOICE_TAG:
      groupBy.add(GroupedItemsGroupBy.INVOICE_TAG);
      break;
  }

  return Array.from(groupBy);
}

export default getGroupByFromViewMode;
