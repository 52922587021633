import { invoicesKeys } from '../../locales/keys';
import {
  FetchInvoiceProcessingJobsSortTypes,
  InvoiceProcessingJobStatuses
} from './invoiceProcessingJobsTypes';

export const INITIAL_INVOICE_PROCESSING_JOBS_FILTERS = {};
export const INITIAL_INVOICE_PROCESSING_JOBS_PAGE = 1;
export const INITIAL_INVOICE_PROCESSING_JOBS_SORT = [
  FetchInvoiceProcessingJobsSortTypes.CREATED_AT_DESC
];
export const INITIAL_INVOICE_PROCESSING_JOBS_LIMIT = 50;

export const invoiceProcessingJobSI18nTextStatuses = {
  [InvoiceProcessingJobStatuses.COMPLETED]:
    invoicesKeys.invoiceProcessingJobStatuses.completed,
  [InvoiceProcessingJobStatuses.FAILED]:
    invoicesKeys.invoiceProcessingJobStatuses.failed,
  [InvoiceProcessingJobStatuses.INITIALIZED]:
    invoicesKeys.invoiceProcessingJobStatuses.initialized,
  [InvoiceProcessingJobStatuses.PROCESSING]:
    invoicesKeys.invoiceProcessingJobStatuses.processing
};

export enum InvoiceProcessingJobsPermissions {
  READ_ADMIN_MENU_INVOICE_PROCESSING_JOBS_MENU = 'read_admin_menu_invoice_processing_jobs_menu',
  READ_INVOICE_PROCESSING_JOBS_INDEX_PAGE = 'read_invoice_processing_jobs_index_page'
}
