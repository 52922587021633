import { gql } from 'graphql-request';
import {
  GroupedItemInvoiceStatus,
  GroupedItemItemCreatedAt,
  GroupedItemItemID,
  GroupedItemItemName,
  GroupedItemItemsIds,
  GroupedItemSmartcontractStatus,
  GroupedItemTaskNanoID
} from '../../groupedItems/groupedItemsTypes';

export interface FetchTasksGroupedItemsQueryResponse {
  invoiceStatus: GroupedItemInvoiceStatus;
  itemCreatedAt: GroupedItemItemCreatedAt;
  itemId: GroupedItemItemID;
  itemName: GroupedItemItemName;
  itemsIds: GroupedItemItemsIds;
  smartContractStatus: GroupedItemSmartcontractStatus;
  taskNanoId: GroupedItemTaskNanoID;
}
[];

export const FETCH_TASKS_GROUPED_ITEMS_QUERY = gql`
  query GroupedItems(
    $filters: GroupedItemsFilters
    $groupBy: [ItemGroupBy!]!
    $groupItemsByType: Boolean
    $limit: Int
    $offset: Int
    $showTotal: Boolean
    $sort: [GroupedItemsSortEnum!]
  ) {
    groupedItems(
      filters: $filters
      groupBy: $groupBy
      groupItemsByType: $groupItemsByType
      sort: $sort
      showTotal: $showTotal
      limit: $limit
      offset: $offset
    ) {
      nodes {
        invoiceStatus
        itemCreatedAt
        itemId
        itemName
        itemsIds
        smartContractStatus
        taskNanoId
      }
      paginationInfo {
        currentPage
        firstPage
        lastPage
        limitValue
        nextPage
        outOfRange
        prevPage
        totalCount
        totalPages
      }
      sql
    }
  }
`;
