import React, { Fragment } from 'react';

import { PayoneerV4RequestProgramKeys } from '../../../../payoneerV4Requests/payoneerV4RequestsTypes';
import { TeamNanoID, TeamOwnerNanoID } from '../../../teamsTypes';

import {
  FETCH_USER_DASHBOARD_QUERY,
  FetchUserDashboardQueryResponse
} from '../../../../users/queries/fetchUserDashboard.query';

import { useCurrentUser } from '../../../../../auth/hooks/useAuth';
import { useUser } from '../../../../users/hooks/useUser';

import { DashboardStatistic } from '../../../../users/components/content/SingleUserDashboardIndexPage/components/DashboardStatistic';
import { DashboardFinance } from '../../../../users/components/content/SingleUserDashboardIndexPage/components/DashboardFinance';
import { DashboardTasksByStatus } from '../../../../users/components/content/SingleUserDashboardIndexPage/components/DashboardTasksByStatus';
import { DashboardActiveTasks } from '../../../../users/components/content/SingleUserDashboardIndexPage/components/DashboardActiveTasks';
import { DashboardTrackedTime } from '../../../../users/components/content/SingleUserDashboardIndexPage/components/DashboardTrackedTime';
import { DashboardMissingSourceFiles } from '../../../../users/components/content/SingleUserDashboardIndexPage/components/DashboardMissingSourceFiles';
import { DashboardLatestResults } from '../../../../users/components/content/SingleUserDashboardIndexPage/components/DashboardLatestResults';
import { GenerateApplicationTokenPayoneerV4RequestsButton } from '../../../../payoneerV4Requests/components/buttons/GenerateApplicationTokenPayoneerV4RequestsButton';
import { RefreshAccessTokenPayoneerV4RequestsButton } from '../../../../payoneerV4Requests/components/buttons/RefreshAccessTokenPayoneerV4RequestsButton';

import { MenuCreateProjectInTeamLink } from '../../../../common/components/menus/MenuCreateProjectInTeamLink';

import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { LoadingSkeleton } from '../../../../../helpers/LoadingSkeleton';
import { CheckPermissions } from '../../../../../helpers/CheckPermissions';

import { UsersPermissions } from '../../../../users/usersConstants';
import { PayoneerV4RequestsPermissions } from '../../../../payoneerV4Requests/payoneerV4RequestsConstants';
import { UserCache } from '../../../../users/UserCache';

import { stringsKeys } from '../../../../../locales/keys';

interface TeamDashboardIndexPageProps {
  ownerNanoId: TeamOwnerNanoID;
  teamNanoId?: TeamNanoID;
}

function TeamDashboardIndexPage({
  ownerNanoId,
  teamNanoId
}: TeamDashboardIndexPageProps) {
  const cacheKey = UserCache.userDashboardKey();

  const currentUser = useCurrentUser();

  const { user, userFetched, userError } =
    useUser<FetchUserDashboardQueryResponse>({
      cacheKey,
      uuid: ownerNanoId,
      query: FETCH_USER_DASHBOARD_QUERY
    });

  const selfProfile = ownerNanoId === currentUser.nanoId;

  return (
    <Fragment>
      <AlertMessage addClassName="m-4" message={userError} />
      <LoadingSkeleton loaded={userFetched}>
        {user ? (
          <div className="space-y-16">
            {selfProfile ? (
              <CheckPermissions
                actions={[
                  PayoneerV4RequestsPermissions.READ_PAYONEER_V4_REQUESTS_GENERATE_APPLICATION_TOKEN_1_BUTTON,
                  PayoneerV4RequestsPermissions.READ_PAYONEER_V4_REQUESTS_GENERATE_APPLICATION_TOKEN_2_BUTTON,
                  PayoneerV4RequestsPermissions.READ_PAYONEER_V4_REQUESTS_REFRESH_ACCESS_TOKEN_BUTTON
                ]}
              >
                <div className="shrink-0 flex gap-2">
                  <CheckPermissions
                    action={
                      PayoneerV4RequestsPermissions.READ_PAYONEER_V4_REQUESTS_GENERATE_APPLICATION_TOKEN_1_BUTTON
                    }
                  >
                    <GenerateApplicationTokenPayoneerV4RequestsButton
                      programKey={PayoneerV4RequestProgramKeys.MASS_PAYOUTS_1}
                      className="flex-1 sm:flex-initial rounded-md border px-4 py-2 border-transparent text-sm font-medium text-white shadow-sm hover:shadow-md bg-blue-600 hover:bg-blue-700"
                      i18nText={stringsKeys.generateApplicationToken1}
                    />
                  </CheckPermissions>

                  <CheckPermissions
                    action={
                      PayoneerV4RequestsPermissions.READ_PAYONEER_V4_REQUESTS_GENERATE_APPLICATION_TOKEN_2_BUTTON
                    }
                  >
                    <GenerateApplicationTokenPayoneerV4RequestsButton
                      programKey={PayoneerV4RequestProgramKeys.MASS_PAYOUTS_2}
                      className="flex-1 sm:flex-initial rounded-md border px-4 py-2 border-transparent text-sm font-medium text-white shadow-sm hover:shadow-md bg-blue-600 hover:bg-blue-700"
                      i18nText={stringsKeys.generateApplicationToken2}
                    />
                  </CheckPermissions>

                  <CheckPermissions
                    action={
                      PayoneerV4RequestsPermissions.READ_PAYONEER_V4_REQUESTS_REFRESH_ACCESS_TOKEN_BUTTON
                    }
                  >
                    <RefreshAccessTokenPayoneerV4RequestsButton
                      className="flex-1 sm:flex-initial rounded-md border px-4 py-2 border-transparent text-sm font-medium text-white shadow-sm hover:shadow-md bg-blue-600 hover:bg-blue-700"
                      i18nText={stringsKeys.refreshAccessToken}
                    />
                  </CheckPermissions>
                </div>
              </CheckPermissions>
            ) : null}

            <CheckPermissions
              action={
                selfProfile
                  ? UsersPermissions.READ_SELF_DASHBOARD_CREATE_PROJECT_IN_TEAM_LINK
                  : UsersPermissions.READ_USER_DASHBOARD_CREATE_PROJECT_IN_TEAM_LINK
              }
            >
              <MenuCreateProjectInTeamLink />
            </CheckPermissions>

            <CheckPermissions
              action={
                selfProfile
                  ? UsersPermissions.READ_SELF_DASHBOARD_LATEST_RESULTS_BLOCK
                  : UsersPermissions.READ_USER_DASHBOARD_LATEST_RESULTS_BLOCK
              }
            >
              <DashboardLatestResults selfProfile={selfProfile} user={user} />
            </CheckPermissions>

            <CheckPermissions
              action={
                selfProfile
                  ? UsersPermissions.READ_SELF_DASHBOARD_ACTIVE_TASKS_BLOCK
                  : UsersPermissions.READ_USER_DASHBOARD_ACTIVE_TASKS_BLOCK
              }
            >
              <DashboardActiveTasks selfProfile={selfProfile} user={user} />
            </CheckPermissions>

            <CheckPermissions
              actions={[
                selfProfile
                  ? UsersPermissions.READ_SELF_DASHBOARD_TRACKED_TIME_BLOCK
                  : UsersPermissions.READ_USER_DASHBOARD_TRACKED_TIME_BLOCK,
                selfProfile
                  ? UsersPermissions.READ_SELF_DASHBOARD_MISSING_SOURCE_FILES_BLOCK
                  : UsersPermissions.READ_USER_DASHBOARD_MISSING_SOURCE_FILES_BLOCK
              ]}
            >
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <CheckPermissions
                  action={
                    selfProfile
                      ? UsersPermissions.READ_SELF_DASHBOARD_TRACKED_TIME_BLOCK
                      : UsersPermissions.READ_USER_DASHBOARD_TRACKED_TIME_BLOCK
                  }
                >
                  <DashboardTrackedTime
                    user={user}
                    teamNanoId={teamNanoId}
                    selfProfile={selfProfile}
                  />
                </CheckPermissions>

                <CheckPermissions
                  action={
                    selfProfile
                      ? UsersPermissions.READ_SELF_DASHBOARD_MISSING_SOURCE_FILES_BLOCK
                      : UsersPermissions.READ_USER_DASHBOARD_MISSING_SOURCE_FILES_BLOCK
                  }
                >
                  <DashboardMissingSourceFiles
                    user={user}
                    teamNanoId={teamNanoId}
                    selfProfile={selfProfile}
                  />
                </CheckPermissions>
              </div>
            </CheckPermissions>

            <CheckPermissions
              action={
                selfProfile
                  ? UsersPermissions.READ_SELF_DASHBOARD_TASKS_BY_STATUS_BLOCK
                  : UsersPermissions.READ_USER_DASHBOARD_TASKS_BY_STATUS_BLOCK
              }
            >
              <DashboardTasksByStatus selfProfile={selfProfile} user={user} />
            </CheckPermissions>

            <CheckPermissions
              action={
                selfProfile
                  ? UsersPermissions.READ_SELF_DASHBOARD_FINANCE_BLOCK
                  : UsersPermissions.READ_USER_DASHBOARD_FINANCE_BLOCK
              }
            >
              <DashboardFinance
                selfProfile={selfProfile}
                teamNanoId={teamNanoId}
                client={user.client}
              />
            </CheckPermissions>

            <CheckPermissions
              action={
                selfProfile
                  ? UsersPermissions.READ_SELF_DASHBOARD_STATISTIC_BLOCK
                  : UsersPermissions.READ_USER_DASHBOARD_STATISTIC_BLOCK
              }
            >
              <DashboardStatistic
                selfProfile={selfProfile}
                user={user}
                teamNanoId={teamNanoId}
              />
            </CheckPermissions>
          </div>
        ) : null}
      </LoadingSkeleton>
    </Fragment>
  );
}

export default TeamDashboardIndexPage;
