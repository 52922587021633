import { gql } from 'graphql-request';

import { MayBe } from '../../../types';

import {
  TaskMemberInviteUUID,
  TaskMemberInviteCreatedAt,
  TaskMemberInviteTaskID,
  TaskMemberInviteTaskUUID,
  TaskMemberInviteID,
  TaskMemberInviteTaskNanoID,
  TaskMemberInviteTaskName,
  TaskMemberInviteTaskImplementationDate,
  TaskMemberInviteTaskStatus,
  TaskMemberInviteTaskUserNanoID,
  TaskMemberInviteTaskUserFullName,
  TaskMemberInviteTaskUserImageUUID,
  TaskMemberInviteTaskUserImageFile,
  TaskMemberInviteTaskMembersNanoID,
  TaskMemberInviteTaskMembersFullName,
  TaskMemberInviteTaskCreatedAt,
  TaskMemberInviteTaskMembersImageUUID,
  TaskMemberInviteTaskMembersImageFile,
  TaskMemberInviteTaskBurning,
  TaskMemberInviteTaskUserUUID,
  TaskMemberInviteTaskMembersUUID,
  TaskMemberInviteTaskVisibleForClient,
  TaskMemberInviteTaskAmount,
  TaskMemberInviteTaskPrepayment,
  TaskMemberInviteTaskClientPrepayment,
  TaskMemberInviteTaskLastActivity,
  TaskMemberInviteTaskProjectNanoID,
  TaskMemberInviteTaskProjectName,
  TaskMemberInviteTaskLastMessageBody,
  TaskMemberInviteTaskLastMessageCreatedAt,
  TaskMemberInviteTaskMembersFinanceRoleName,
  TaskMemberInviteTaskUserFinanceRoleName,
  TaskMemberInvitedUserNanoId,
  TaskMemberInvitedUserId,
  TaskMemberInvitedUserFullName,
  TaskMemberInviteTaskUserClient,
  TaskMemberInviteTaskMembersClient,
  TaskMemberInviteTaskUserCurrentTeamNanoID,
  TaskMemberInviteTaskMembersCurrentTeamNanoID,
  TaskMemberInviteUserClient,
  TaskMemberInviteUserCurrentTeamNanoID,
  TaskMemberInviteTaskMembersID,
  TaskMemberInviteTaskUserID,
  TaskMemberInviteTaskProjectTeamName,
  TaskMemberInviteTaskProjectTeamNanoID,
  TaskMemberInviteTaskProjectTeamImageFile,
  TaskMemberInviteUserUUID
} from '../taskMemberInvitesTypes';
import {
  TaskMemberInvitedUserFinanceRoleName,
  TaskMemberInvitedUserImageFile,
  TaskMemberInvitedUserImageUUID,
  TaskMemberUserFinanceRoleName,
  TaskMemberUserFullName,
  TaskMemberUserID,
  TaskMemberUserNanoID
} from '../../taskMembers/taskMembersTypes';

export interface FetchTaskMemberInvitesQueryResponse {
  id: TaskMemberInviteID;
  uuid: TaskMemberInviteUUID;
  createdAt: TaskMemberInviteCreatedAt;
  invitedUser: {
    id: TaskMemberInvitedUserId;
    nanoId: TaskMemberInvitedUserNanoId;
    blocked: TaskMemberInvitedUserNanoId;
    fullName: TaskMemberInvitedUserFullName;
    financeRoleName: TaskMemberInvitedUserFinanceRoleName;
    client: TaskMemberInviteUserClient;
    currentTeam: MayBe<{
      nanoId: TaskMemberInviteUserCurrentTeamNanoID;
    }>;
    image: {
      uuid: TaskMemberInvitedUserImageUUID;
      file: TaskMemberInvitedUserImageFile;
    };
  };
  user: {
    id: TaskMemberUserID;
    uuid: TaskMemberInviteUserUUID;
    nanoId: TaskMemberUserNanoID;
    fullName: TaskMemberUserFullName;
    financeRoleName: TaskMemberUserFinanceRoleName;
    client: TaskMemberInviteUserClient;
    currentTeam: MayBe<{
      nanoId: TaskMemberInviteUserCurrentTeamNanoID;
    }>;
  };
  task: {
    id: TaskMemberInviteTaskID;
    uuid: TaskMemberInviteTaskUUID;
    nanoId: TaskMemberInviteTaskNanoID;
    name: TaskMemberInviteTaskName;
    visibleForClient: TaskMemberInviteTaskVisibleForClient;
    status: TaskMemberInviteTaskStatus;
    implementationDate: TaskMemberInviteTaskImplementationDate;
    burning: TaskMemberInviteTaskBurning;
    amount: TaskMemberInviteTaskAmount;
    prepayment: TaskMemberInviteTaskPrepayment;
    clientPrepayment: TaskMemberInviteTaskClientPrepayment;
    lastActivity: TaskMemberInviteTaskLastActivity;
    createdAt: TaskMemberInviteTaskCreatedAt;
    project: {
      nanoId: TaskMemberInviteTaskProjectNanoID;
      name: TaskMemberInviteTaskProjectName;
      team: {
        name: TaskMemberInviteTaskProjectTeamName;
        nanoId: TaskMemberInviteTaskProjectTeamNanoID;
        image?: MayBe<{
          file: TaskMemberInviteTaskProjectTeamImageFile;
        }>;
      };
    };
    lastMessage: {
      body: TaskMemberInviteTaskLastMessageBody;
      createdAt: TaskMemberInviteTaskLastMessageCreatedAt;
    };
    user: {
      id: TaskMemberInviteTaskUserID;
      uuid: TaskMemberInviteTaskUserUUID;
      nanoId: TaskMemberInviteTaskUserNanoID;
      fullName: TaskMemberInviteTaskUserFullName;
      financeRoleName: TaskMemberInviteTaskUserFinanceRoleName;
      client: TaskMemberInviteTaskUserClient;
      currentTeam: MayBe<{
        nanoId: TaskMemberInviteTaskUserCurrentTeamNanoID;
      }>;
      image: {
        uuid: TaskMemberInviteTaskUserImageUUID;
        file: TaskMemberInviteTaskUserImageFile;
      };
    };
    members: {
      id: TaskMemberInviteTaskMembersID;
      uuid: TaskMemberInviteTaskMembersUUID;
      nanoId: TaskMemberInviteTaskMembersNanoID;
      fullName: TaskMemberInviteTaskMembersFullName;
      financeRoleName: TaskMemberInviteTaskMembersFinanceRoleName;
      client: TaskMemberInviteTaskMembersClient;
      currentTeam: MayBe<{
        nanoId: TaskMemberInviteTaskMembersCurrentTeamNanoID;
      }>;
      image: {
        uuid: TaskMemberInviteTaskMembersImageUUID;
        file: TaskMemberInviteTaskMembersImageFile;
      };
    }[];
  };
}

export const FETCH_TASK_MEMBER_INVITES_QUERY = gql`
  query TaskMemberInvites(
    $filters: TaskMemberInvitesFilters
    $sort: [TaskMemberInvitesSortEnum!]
    $limit: Int
    $offset: Int
  ) {
    taskMemberInvites(
      filters: $filters
      sort: $sort
      limit: $limit
      offset: $offset
    ) {
      nodes {
        id
        uuid
        createdAt
        invitedUser {
          id
          uuid
          nanoId
          blocked
          fullName
          financeRoleName
          client
          currentTeam {
            nanoId
          }
          image {
            uuid
            file
          }
        }
        user {
          id
          uuid
          fullName
          nanoId
          financeRoleName
          client
          currentTeam {
            nanoId
          }
        }
        task {
          id
          uuid
          nanoId
          name
          visibleForClient
          status
          implementationDate
          burning
          amount
          prepayment
          clientPrepayment
          lastActivity
          createdAt
          project {
            nanoId
            name
            team {
              name
              nanoId
              image {
                file
              }
            }
          }
          lastMessage {
            body
            createdAt
          }
          user {
            id
            uuid
            nanoId
            fullName
            financeRoleName
            client
            currentTeam {
              nanoId
            }
            image {
              uuid
              file
            }
          }
          members {
            id
            uuid
            nanoId
            fullName
            financeRoleName
            client
            currentTeam {
              nanoId
            }
            image {
              uuid
              file
            }
          }
        }
      }
      paginationInfo {
        nextPage
        totalCount
      }
      sql
    }
  }
`;
