import { useCallback, useEffect } from 'react';

import { $createTextNode, $getRoot } from 'lexical';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
  $convertFromMarkdownString,
  $convertToMarkdownString,
  TRANSFORMERS
} from '@lexical/markdown';
import { $createCodeNode, $isCodeNode } from '@lexical/code';

export interface MarkdownConversationPluginProps {
  withFormatting?: boolean;
}

function MarkdownConversationPlugin({
  withFormatting
}: MarkdownConversationPluginProps) {
  const [editor] = useLexicalComposerContext();

  const handleMarkdownFormatting = useCallback(() => {
    editor.update(() => {
      const root = $getRoot();
      const firstChild = root.getFirstChild();
      if ($isCodeNode(firstChild) && firstChild.getLanguage() === 'markdown') {
        console.log('1 firstChild', firstChild);
        $convertFromMarkdownString(firstChild.getTextContent(), TRANSFORMERS);
        root.selectEnd();
      }
    });
  }, [editor]);

  const handleCodeFormatting = useCallback(() => {
    editor.update(() => {
      const root = $getRoot();
      const firstChild = root.getFirstChild();
      if (!$isCodeNode(firstChild) || firstChild.getLanguage() !== 'markdown') {
        console.log('2 firstChild', firstChild);

        const markdown = $convertToMarkdownString(TRANSFORMERS);
        root
          .clear()
          .append(
            $createCodeNode('markdown').append($createTextNode(markdown))
          );
        root.selectEnd();
      }
    });
  }, [editor]);

  useEffect(() => {
    if (withFormatting) {
      handleMarkdownFormatting();
    } else {
      handleCodeFormatting();
    }
  }, [handleCodeFormatting, handleMarkdownFormatting, withFormatting]);

  return null;
}

export default MarkdownConversationPlugin;
