import { gql } from 'graphql-request';

import { MayBe } from '../../../types';

import {
  SelectedMaterialID,
  SelectedMaterialUUID,
  SelectedMaterialCreatedAt,
  SelectedMaterialMaterialUUID,
  SelectedMaterialMaterialName,
  SelectedMaterialMaterialBrandUUID,
  SelectedMaterialMaterialBrandName,
  SelectedMaterialMaterialImageUUID,
  SelectedMaterialMaterialImageFile,
  SelectedMaterialMaterialNda,
  SelectedMaterialUserNanoId,
  SelectedMaterialUserFullName,
  SelectedMaterialUserImageUUID,
  SelectedMaterialUserImageFile,
  SelectedMaterialUserClient,
  SelectedMaterialUserCurrentTeamNanoID
} from '../selectedMaterialsTypes';

export interface FetchSelectedMaterialsQueryResponse {
  id: SelectedMaterialID;
  uuid: SelectedMaterialUUID;
  createdAt: SelectedMaterialCreatedAt;
  user: {
    nanoId: SelectedMaterialUserNanoId;
    fullName: SelectedMaterialUserFullName;
    client: SelectedMaterialUserClient;
    currentTeam: MayBe<{
      nanoId: SelectedMaterialUserCurrentTeamNanoID;
    }>;
    image: {
      uuid: SelectedMaterialUserImageUUID;
      file: SelectedMaterialUserImageFile;
    };
  };
  material: {
    uuid: SelectedMaterialMaterialUUID;
    name: SelectedMaterialMaterialName;
    nda: SelectedMaterialMaterialNda;
    brand: {
      uuid: SelectedMaterialMaterialBrandUUID;
      name: SelectedMaterialMaterialBrandName;
    };
    image: {
      uuid: SelectedMaterialMaterialImageUUID;
      file: SelectedMaterialMaterialImageFile;
    };
  };
}

export const FETCH_SELECTED_MATERIALS_QUERY = gql`
  query TaskSelectedMaterials(
    $filters: SelectedMaterialsFilters
    $sort: [SelectedMaterialsSortEnum!]
    $limit: Int
    $offset: Int
  ) {
    selectedMaterials(
      filters: $filters
      sort: $sort
      limit: $limit
      offset: $offset
    ) {
      nodes {
        id
        uuid
        createdAt
        user {
          nanoId
          fullName
          client
          currentTeam {
            nanoId
          }
          image {
            uuid
            file
          }
        }
        material {
          uuid
          name
          nda
          brand {
            uuid
            name
          }
          image {
            uuid
            file
          }
        }
      }
      paginationInfo {
        nextPage
        totalCount
      }
      sql
    }
  }
`;
