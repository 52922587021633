import { gql } from 'graphql-request';

import {
  TrackedTimeIntervalCreatedAt,
  TrackedTimeIntervalFullTime,
  TrackedTimeIntervalHours,
  TrackedTimeIntervalID,
  TrackedTimeIntervalMinutes,
  TrackedTimeIntervalProjectID,
  TrackedTimeIntervalProjectName,
  TrackedTimeIntervalProjectNanoID,
  TrackedTimeIntervalTaskID,
  TrackedTimeIntervalTaskName,
  TrackedTimeIntervalTaskNanoID,
  TrackedTimeIntervalTaskProjectName,
  TrackedTimeIntervalTaskProjectNanoID
} from '../trackedTimeIntervalsTypes';

export interface FetchTrackedTimeIntervalQueryResponse {
  id: TrackedTimeIntervalID;
  createdAt: TrackedTimeIntervalCreatedAt;
  fullTime: TrackedTimeIntervalFullTime;
  hours: TrackedTimeIntervalHours;
  minutes: TrackedTimeIntervalMinutes;
  projectId: TrackedTimeIntervalProjectID;
  project: {
    name: TrackedTimeIntervalProjectName;
    nanoId: TrackedTimeIntervalProjectNanoID;
  };
  taskId: TrackedTimeIntervalTaskID;
  task: {
    name: TrackedTimeIntervalTaskName;
    nanoId: TrackedTimeIntervalTaskNanoID;
    project: {
      name: TrackedTimeIntervalTaskProjectName;
      nanoId: TrackedTimeIntervalTaskProjectNanoID;
    };
  };
}

export const FETCH_TRACKED_TIME_INTERVALS_QUERY = gql`
  query TrackedTimeIntervals(
    $filters: TrackedTimeIntervalsFilters
    $sort: [TrackedTimeIntervalsSortEnum!]
    $limit: Int
    $offset: Int
  ) {
    trackedTimeIntervals(
      filters: $filters
      sort: $sort
      limit: $limit
      offset: $offset
    ) {
      nodes {
        id
        fullTime
        createdAt
        hours
        minutes
        projectId
        project {
          nanoId
          name
        }
        taskId
        task {
          nanoId
          name
          project {
            nanoId
            name
          }
        }
      }
      paginationInfo {
        nextPage
        totalCount
      }
      sql
    }
  }
`;
