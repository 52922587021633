import {
  FetchLifestyleCategoriesCacheKey,
  FetchFinLifestyleCategoriesFilters
} from '../../lifestylesTypes';

import {
  FETCH_LIFESTYLE_COLORS_QUERY,
  FetchLifestyleColorsQueryResponse
} from '../../queries/fetchLifestyleColors.query';
import { CACHE_FETCH_LIFESTYLE_COLORS_QUERY } from '../../queries/fetchCacheLifestyleColors.query';

import { useFinPaginatedCategories } from '../../../categories/hooks/useFinPaginatedCategories';

import { FetchCategoriesScopes } from '../../../categories/categoriesTypes';
import { CategoriesPermissions } from '../../../categories/categoriesConstants';

const defaultInitialFilters = {
  scope: { eq: FetchCategoriesScopes.SCENE_MAIN_COLORS }
};
const options = {
  staleTime: 1000 * 60 * 60
};

interface LifestyleColorsDefaultOptions {
  cacheKey: FetchLifestyleCategoriesCacheKey;
  addInitialFilters?: FetchFinLifestyleCategoriesFilters;
}

function useLifestyleColors({
  cacheKey,
  addInitialFilters
}: LifestyleColorsDefaultOptions) {
  const initialFilters = addInitialFilters
    ? { ...defaultInitialFilters, ...addInitialFilters }
    : defaultInitialFilters;

  const {
    categories,
    categoriesError,
    categoriesFetched,
    categoriesIsPlaceholderData
  } = useFinPaginatedCategories<FetchLifestyleColorsQueryResponse>({
    action: CategoriesPermissions.READ_LIFESTYLE_CATEGORIES_INDEX_ES_QUERY,
    cacheKey,
    query: FETCH_LIFESTYLE_COLORS_QUERY,
    cacheQuery: CACHE_FETCH_LIFESTYLE_COLORS_QUERY,
    initialFilters,
    options
  });

  return {
    lifestyleColors: categories,
    lifestyleColorsErrorMessage: categoriesError,
    lifestyleColorsFetched: categoriesFetched,
    lifestyleColorsIsPlaceholderData: categoriesIsPlaceholderData
  };
}

export default useLifestyleColors;
