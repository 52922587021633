import { gql } from 'graphql-request';

import {
  MessageID,
  MessageUUID,
  MessageBody,
  MessageTaskID,
  MessageIsResult,
  MessageResultVersion,
  MessageTaskStatus
} from '../messagesTypes';

export interface FetchTaskMessagesWithResultQueryResponse {
  id: MessageID;
  uuid: MessageUUID;
  body: MessageBody;
  isResult: MessageIsResult;
  resultVersion: MessageResultVersion;
  task: {
    id: MessageTaskID;
    status: MessageTaskStatus;
  };
}

export const FETCH_TASK_MESSAGES_WITH_RESULT = gql`
  query TaskMessagesWithResult(
    $filters: MessagesFilters
    $sort: [MessagesSortEnum!]
    $limit: Int
    $offset: Int
  ) {
    messages(filters: $filters, sort: $sort, limit: $limit, offset: $offset) {
      nodes {
        id
        uuid
        body
        isResult
        resultVersion
        task {
          id
          status
        }
      }
      paginationInfo {
        nextPage
      }
      sql
    }
  }
`;
