import { gql } from 'graphql-request';

import { MayBe } from '../../../types';

import {
  MessageBillAccount,
  MessageBillAmount,
  MessageBillBody,
  MessageBillCreatedAt,
  MessageBillCurrency,
  MessageBillID,
  MessageBillPayable,
  MessageBillPayerClient,
  MessageBillPayerCurrentTeamNanoID,
  MessageBillPayerFullName,
  MessageBillPayerNanoID,
  MessageBillPayerUUID,
  MessageBillPaymentForm,
  MessageBillPaymentUrl,
  MessageBillStatus,
  MessageBillUUID,
  MessageBody,
  MessageColors,
  MessageCreatedAt,
  MessageFavorite,
  MessageFileAttachmentID,
  MessageFileAttachmentsCreatedAt,
  MessageFileAttachmentsDPI,
  MessageFileAttachmentsDrawing,
  MessageFileAttachmentsExt,
  MessageFileAttachmentsFile,
  MessageFileAttachmentsHeight,
  MessageFileAttachmentsName,
  MessageFileAttachmentsReference,
  MessageFileAttachmentsSize,
  MessageFileAttachmentsTexture,
  MessageFileAttachmentsUUID,
  MessageFileAttachmentsWhiteboardKeyID,
  MessageFileAttachmentsWidth,
  MessageFlagged,
  MessageHours,
  MessageID,
  MessageInvoiceAmount,
  MessageInvoiceClientID,
  MessageInvoiceClientStatus,
  MessageInvoiceCreatedAt,
  MessageInvoiceDueDate,
  MessageInvoiceID,
  MessageInvoiceNanoID,
  MessageInvoicePaymentMethod,
  MessageInvoicePrepayment,
  MessageInvoicePrepaymentAmount,
  MessageInvoiceReturnReason,
  MessageInvoiceStatus,
  MessageInvoiceTotal,
  MessageInvoiceType,
  MessageInvoiceUpdatedAt,
  MessageIsResult,
  MessageLikeItems,
  MessageMessageType,
  MessageMinutes,
  MessagePaidBillAmount,
  MessagePaidBillCurrency,
  MessagePaidBillID,
  MessagePaidBillPayerFullName,
  MessagePaidBillPayerNanoID,
  MessagePaidBillPayerUUID,
  MessagePaidBillUUID,
  MessagePinnedAt,
  MessageResentAt,
  MessageProjectID,
  MessageSelectedLifestyleID,
  MessageSelectedLifestylesCreatedAt,
  MessageSelectedLifestylesLifestyleAuthorLocalizedName,
  MessageSelectedLifestylesLifestyleCategoryLocalizedName,
  MessageSelectedLifestylesLifestyleImageFile,
  MessageSelectedLifestylesLifestyleImageName,
  MessageSelectedLifestylesLifestyleImageUUID,
  MessageSelectedLifestylesLifestyleName,
  MessageSelectedLifestylesLifestyleNda,
  MessageSelectedLifestylesLifestylePremiumContentAt,
  MessageSelectedLifestylesLifestyleUUID,
  MessageSelectedLifestylesUUID,
  MessageSelectedMaterialID,
  MessageSelectedMaterialsCreatedAt,
  MessageSelectedMaterialsMaterialImageFile,
  MessageSelectedMaterialsMaterialImageName,
  MessageSelectedMaterialsMaterialImageUUID,
  MessageSelectedMaterialsMaterialName,
  MessageSelectedMaterialsMaterialNda,
  MessageSelectedMaterialsMaterialUUID,
  MessageSelectedMaterialsUUID,
  MessageSelectedProductID,
  MessageSelectedProductsCreatedAt,
  MessageSelectedProductsProductBrand,
  MessageSelectedProductsProductImageFile,
  MessageSelectedProductsProductImageName,
  MessageSelectedProductsProductImageUUID,
  MessageSelectedProductsProductName,
  MessageSelectedProductsProductNda,
  MessageSelectedProductsProductUUID,
  MessageSelectedProductsUUID,
  MessageSortType,
  MessageThreadKeyID,
  MessageThreadStart,
  MessageTodoItemDone,
  MessageTodoItemID,
  MessageTodoItemName,
  MessageTodoItemOrder,
  MessageTodoItemUUID,
  MessageTodoItemVisibleForClients,
  MessageUserClient,
  MessageUserFullName,
  MessageUserImageFile,
  MessageUserImageUUID,
  MessageUserNanoID,
  MessageUserUserCurrentTeamNanoID,
  MessageUserUUID,
  MessageUUID,
  MessageVisibleForClient,
  MessageWhiteboardKeyID,
  MessageSelectedProductsType,
  MessageFileAttachmentsType,
  MessageSelectedLifestylesType,
  MessageSelectedMaterialsType,
  MessageRed,
  MessageCreatedProductID,
  MessageClassification,
  MessageAiNotRelevantAt,
  MessageArchivedAt
} from '../messagesTypes';

export interface FetchProjectMessagesQueryResponse {
  id: MessageID;
  uuid: MessageUUID;
  body: MessageBody;
  messageType: MessageMessageType;
  favorite: MessageFavorite;
  flagged: MessageFlagged;
  archivedAt?: MessageArchivedAt;
  pinnedAt?: MessagePinnedAt;
  resentAt?: MessageResentAt;
  visibleForClient: MessageVisibleForClient;
  colors: MessageColors;
  createdAt: MessageCreatedAt;
  createdProductId: MessageCreatedProductID;
  classification: MessageClassification;
  aiNotRelevantAt?: MessageAiNotRelevantAt;
  projectId: MessageProjectID;
  hours?: MessageHours;
  minutes?: MessageMinutes;
  isResult: MessageIsResult;
  red?: MessageRed;
  invoiceId?: MessageInvoiceID;
  threadKeyId: MessageThreadKeyID;
  whiteboardKeyId: MessageWhiteboardKeyID;
  threadStart: MessageThreadStart;
  invoice?: {
    amount: MessageInvoiceAmount;
    clientId: MessageInvoiceClientID;
    clientStatus: MessageInvoiceClientStatus;
    createdAt: MessageInvoiceCreatedAt;
    dueDate: MessageInvoiceDueDate;
    invoiceTotal: MessageInvoiceTotal;
    nanoId: MessageInvoiceNanoID;
    paymentMethod: MessageInvoicePaymentMethod;
    prepayment: MessageInvoicePrepayment;
    prepaymentAmount: MessageInvoicePrepaymentAmount;
    returnReason: MessageInvoiceReturnReason;
    status: MessageInvoiceStatus;
    type: MessageInvoiceType;
    updatedAt: MessageInvoiceUpdatedAt;
  };
  messageLikes: MessageLikeItems;
  user: {
    uuid: MessageUserUUID;
    nanoId: MessageUserNanoID;
    fullName: MessageUserFullName;
    client: MessageUserClient;
    currentTeam: MayBe<{
      nanoId: MessageUserUserCurrentTeamNanoID;
    }>;
    image: {
      uuid: MessageUserImageUUID;
      file: MessageUserImageFile;
    };
  };
  bill?: {
    id: MessageBillID;
    uuid: MessageBillUUID;
    account: MessageBillAccount;
    amount: MessageBillAmount;
    currency: MessageBillCurrency;
    body: MessageBillBody;
    payable: MessageBillPayable;
    status: MessageBillStatus;
    createdAt: MessageBillCreatedAt;
    paymentForm: MessageBillPaymentForm;
    paymentUrl: MessageBillPaymentUrl;
    payer: {
      uuid: MessageBillPayerUUID;
      nanoId: MessageBillPayerNanoID;
      fullName: MessageBillPayerFullName;
      client: MessageBillPayerClient;
      currentTeam: MayBe<{
        nanoId: MessageBillPayerCurrentTeamNanoID;
      }>;
    };
  };
  paidBill?: {
    id: MessagePaidBillID;
    uuid: MessagePaidBillUUID;
    amount: MessagePaidBillAmount;
    currency: MessagePaidBillCurrency;
    payer: {
      uuid: MessagePaidBillPayerUUID;
      nanoId: MessagePaidBillPayerNanoID;
      fullName: MessagePaidBillPayerFullName;
    };
  };
  fileAttachments: {
    id: MessageFileAttachmentID;
    uuid: MessageFileAttachmentsUUID;
    type: MessageFileAttachmentsType;
    name: MessageFileAttachmentsName;
    file: MessageFileAttachmentsFile;
    size: MessageFileAttachmentsSize;
    drawing: MessageFileAttachmentsDrawing;
    reference: MessageFileAttachmentsReference;
    texture: MessageFileAttachmentsTexture;
    createdAt: MessageFileAttachmentsCreatedAt;
    ext: MessageFileAttachmentsExt;
    width: MessageFileAttachmentsWidth;
    height: MessageFileAttachmentsHeight;
    dpi: MessageFileAttachmentsDPI;
    whiteboardKeyId: MessageFileAttachmentsWhiteboardKeyID;
  }[];
  fileAttachment: MayBe<{
    id: MessageFileAttachmentID;
    uuid: MessageFileAttachmentsUUID;
    type: MessageFileAttachmentsType;
    name: MessageFileAttachmentsName;
    file: MessageFileAttachmentsFile;
    size: MessageFileAttachmentsSize;
    createdAt: MessageFileAttachmentsCreatedAt;
    ext: MessageFileAttachmentsExt;
    width: MessageFileAttachmentsWidth;
    height: MessageFileAttachmentsHeight;
    dpi: MessageFileAttachmentsDPI;
    whiteboardKeyId: MessageFileAttachmentsWhiteboardKeyID;
  }>;
  selectedProducts: {
    id: MessageSelectedProductID;
    uuid: MessageSelectedProductsUUID;
    type: MessageSelectedProductsType;
    createdAt: MessageSelectedProductsCreatedAt;
    product: {
      uuid: MessageSelectedProductsProductUUID;
      name: MessageSelectedProductsProductName;
      brand: MessageSelectedProductsProductBrand;
      nda: MessageSelectedProductsProductNda;
      image: {
        uuid: MessageSelectedProductsProductImageUUID;
        name: MessageSelectedProductsProductImageName;
        file: MessageSelectedProductsProductImageFile;
      };
    };
  }[];
  selectedLifestyles: {
    id: MessageSelectedLifestyleID;
    uuid: MessageSelectedLifestylesUUID;
    type: MessageSelectedLifestylesType;
    createdAt: MessageSelectedLifestylesCreatedAt;
    lifestyle: {
      uuid: MessageSelectedLifestylesLifestyleUUID;
      name: MessageSelectedLifestylesLifestyleName;
      nda: MessageSelectedLifestylesLifestyleNda;
      author: {
        localizedName: MessageSelectedLifestylesLifestyleAuthorLocalizedName;
      };
      category: {
        localizedName: MessageSelectedLifestylesLifestyleCategoryLocalizedName;
      };
      image: {
        uuid: MessageSelectedLifestylesLifestyleImageUUID;
        name: MessageSelectedLifestylesLifestyleImageName;
        file: MessageSelectedLifestylesLifestyleImageFile;
      };
      premiumContentAt: MessageSelectedLifestylesLifestylePremiumContentAt;
    };
  }[];
  selectedMaterials: {
    id: MessageSelectedMaterialID;
    uuid: MessageSelectedMaterialsUUID;
    type: MessageSelectedMaterialsType;
    createdAt: MessageSelectedMaterialsCreatedAt;
    material: {
      uuid: MessageSelectedMaterialsMaterialUUID;
      nda: MessageSelectedMaterialsMaterialNda;
      name: MessageSelectedMaterialsMaterialName;
      image: {
        uuid: MessageSelectedMaterialsMaterialImageUUID;
        name: MessageSelectedMaterialsMaterialImageName;
        file: MessageSelectedMaterialsMaterialImageFile;
      };
    };
  }[];
  sortType: MessageSortType;
  forwardedMessage?: {
    uuid: MessageUUID;
    body: MessageBody;
    createdAt: MessageCreatedAt;
    classification: MessageClassification;
    aiNotRelevantAt?: MessageAiNotRelevantAt;
    visibleForClient: MessageVisibleForClient;
    colors: MessageColors;
    user: {
      fullName: MessageUserFullName;
      client: MessageUserClient;
    };
  };
  repliedMessage?: {
    id: MessageID;
    uuid: MessageUUID;
    body: MessageBody;
    createdAt: MessageCreatedAt;
    classification: MessageClassification;
    aiNotRelevantAt?: MessageAiNotRelevantAt;
    visibleForClient: MessageVisibleForClient;
    colors: MessageColors;
    user: {
      fullName: MessageUserFullName;
      client: MessageUserClient;
    };
    fileAttachments: {
      uuid: MessageFileAttachmentsUUID;
      type: MessageFileAttachmentsType;
      name: MessageFileAttachmentsName;
      file: MessageFileAttachmentsFile;
      drawing: MessageFileAttachmentsDrawing;
      reference: MessageFileAttachmentsReference;
      texture: MessageFileAttachmentsTexture;
      createdAt: MessageFileAttachmentsCreatedAt;
    }[];
    selectedProducts: {
      uuid: MessageSelectedProductsUUID;
      type: MessageSelectedProductsType;
      createdAt: MessageSelectedProductsCreatedAt;
      product: {
        uuid: MessageSelectedProductsProductUUID;
        name: MessageSelectedProductsProductName;
        brand: MessageSelectedProductsProductBrand;
        image: {
          uuid: MessageSelectedProductsProductImageUUID;
          name: MessageSelectedProductsProductImageName;
          file: MessageSelectedProductsProductImageFile;
        };
      };
    }[];
    selectedLifestyles: {
      uuid: MessageSelectedLifestylesUUID;
      type: MessageSelectedLifestylesType;
      createdAt: MessageSelectedLifestylesCreatedAt;
      lifestyle: {
        uuid: MessageSelectedLifestylesLifestyleUUID;
        name: MessageSelectedLifestylesLifestyleName;
        image: {
          uuid: MessageSelectedLifestylesLifestyleImageUUID;
          name: MessageSelectedLifestylesLifestyleImageName;
          file: MessageSelectedLifestylesLifestyleImageFile;
        };
      };
    }[];
    selectedMaterials: {
      uuid: MessageSelectedMaterialsUUID;
      type: MessageSelectedMaterialsType;
      createdAt: MessageSelectedMaterialsCreatedAt;
      material: {
        uuid: MessageSelectedMaterialsMaterialUUID;
        name: MessageSelectedMaterialsMaterialImageName;
        image: {
          uuid: MessageSelectedMaterialsMaterialImageUUID;
          name: MessageSelectedMaterialsMaterialImageName;
          file: MessageSelectedMaterialsMaterialImageFile;
        };
      };
    }[];
    todoItems: {
      id: MessageTodoItemID;
      name: MessageTodoItemName;
      done: MessageTodoItemDone;
      order: MessageTodoItemOrder;
    }[];
  };
  todoItems: {
    id: MessageTodoItemID;
    uuid: MessageTodoItemUUID;
    name: MessageTodoItemName;
    done: MessageTodoItemDone;
    order: MessageTodoItemOrder;
    visibleForClients: MessageTodoItemVisibleForClients;
  }[];
}

export const FETCH_PROJECT_MESSAGES = gql`
  query ProjectMessages(
    $filters: MessagesFilters
    $sort: [MessagesSortEnum!]
    $limit: Int
    $offset: Int
  ) {
    messages(filters: $filters, sort: $sort, limit: $limit, offset: $offset) {
      nodes {
        id
        uuid
        body
        messageType
        favorite
        flagged
        archivedAt
        pinnedAt
        resentAt
        visibleForClient
        colors
        createdAt
        createdProductId
        classification
        aiNotRelevantAt
        projectId
        hours
        minutes
        isResult
        invoiceId
        threadKeyId
        whiteboardKeyId
        threadStart
        red
        invoice {
          amount
          clientId
          clientStatus
          createdAt
          dueDate
          invoiceTotal
          nanoId
          paymentMethod
          prepayment
          prepaymentAmount
          returnReason
          status
          type
          updatedAt
        }
        messageLikes {
          uuid
          messageLikeType
          userId
          user {
            uuid
            fullName
            image {
              file
            }
          }
        }
        user {
          uuid
          nanoId
          fullName
          client
          currentTeam {
            nanoId
          }
          image {
            uuid
            file
          }
        }
        bill {
          id
          uuid
          account
          amount
          currency
          body
          payable
          status
          createdAt
          payer {
            uuid
            nanoId
            fullName
            client
            currentTeam {
              nanoId
            }
          }
        }
        paidBill {
          id
          uuid
          amount
          currency
          payer {
            uuid
            nanoId
            fullName
          }
        }
        fileAttachments {
          id
          uuid
          type
          name
          file
          size
          drawing
          reference
          texture
          createdAt
          ext
          width
          height
          dpi
          whiteboardKeyId
        }
        fileAttachment {
          id
          uuid
          type
          name
          file
          size
          createdAt
          ext
          width
          height
          dpi
          whiteboardKeyId
        }
        selectedProducts {
          id
          uuid
          type
          createdAt
          product {
            uuid
            name
            nda
            brand {
              localizedName
            }
            image {
              uuid
              file
            }
          }
        }
        selectedLifestyles {
          id
          uuid
          type
          createdAt
          lifestyle {
            uuid
            name
            nda
            author {
              localizedName
            }
            category {
              localizedName
            }
            image {
              uuid
              file
            }
            premiumContentAt
          }
        }
        selectedMaterials {
          id
          uuid
          type
          createdAt
          material {
            uuid
            name
            nda
            image {
              uuid
              file
            }
          }
        }
        sortType
        forwardedMessage {
          uuid
          body
          createdAt
          classification
          aiNotRelevantAt
          visibleForClient
          colors
          user {
            fullName
            client
          }
        }
        repliedMessage {
          id
          uuid
          body
          createdAt
          classification
          aiNotRelevantAt
          visibleForClient
          colors
          user {
            fullName
            client
          }
          fileAttachments {
            uuid
            type
            name
            file
            drawing
            reference
            texture
            createdAt
          }
          selectedProducts {
            uuid
            type
            createdAt
            product {
              uuid
              name
              brand {
                localizedName
              }
              image {
                uuid
                name
                file
              }
            }
          }
          selectedLifestyles {
            uuid
            type
            createdAt
            lifestyle {
              uuid
              name
              image {
                uuid
                name
                file
              }
            }
          }
          selectedMaterials {
            uuid
            type
            createdAt
            material {
              uuid
              name
              image {
                uuid
                name
                file
              }
            }
          }
          todoItems {
            id
            name
            done
            order
          }
        }
        todoItems {
          id
          uuid
          name
          done
          order
          visibleForClients
        }
      }
      paginationInfo {
        nextPage
      }
      sql
    }
  }
`;
