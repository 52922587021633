import { gql } from 'graphql-request';

import {
  TaskID,
  TaskLastMessageBody,
  TaskLastMessageCreatedAt,
  TaskName,
  TaskNanoID,
  TaskStatus,
  TaskUUID,
  TaskLastMessageUserID,
  TaskLastMessageUserNanoID,
  TaskLastMessageUserFullName,
  TaskLastMessageUserImageFile,
  TaskLastMessageUserImageUUID,
  TaskProjectName,
  TaskProjectNanoID,
  TaskLastActivity
} from '../tasksTypes';

export interface FetchTasksDashboardQueryResponse {
  id: TaskID;
  uuid: TaskUUID;
  nanoId: TaskNanoID;
  name: TaskName;
  status: TaskStatus;
  project: {
    name: TaskProjectName;
    nanoId: TaskProjectNanoID;
  };
  lastActivity: TaskLastActivity;
  lastMessage: {
    createdAt: TaskLastMessageCreatedAt;
    body: TaskLastMessageBody;
    user: {
      id: TaskLastMessageUserID;
      nanoId: TaskLastMessageUserNanoID;
      fullName: TaskLastMessageUserFullName;
      image: {
        uuid: TaskLastMessageUserImageUUID;
        file: TaskLastMessageUserImageFile;
      };
    };
  };
}

export const FETCH_TASKS_DASHBOARD_QUERY = gql`
  query TasksDashboard(
    $filters: TasksFilters
    $sort: [TasksSortEnum!]
    $limit: Int
    $offset: Int
  ) {
    tasks(filters: $filters, sort: $sort, limit: $limit, offset: $offset) {
      nodes {
        id
        uuid
        nanoId
        name
        updatedAt
        createdAt
        status
        lastActivity
        project {
          name
          nanoId
        }
        lastMessage {
          createdAt
          body
          user {
            id
            nanoId
            fullName
            image {
              uuid
              file
            }
          }
        }
      }
      paginationInfo {
        nextPage
        totalCount
      }
      sql
    }
  }
`;
