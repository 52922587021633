import { gql } from 'graphql-request';

import {
  ProjectUUID,
  ProjectName,
  ProjectID,
  ProjectNanoID
} from '../projectsTypes';

export interface FetchSelectFieldProjectsQueryResponse {
  id: ProjectID;
  uuid: ProjectUUID;
  nanoId: ProjectNanoID;
  name: ProjectName;
}

export const FETCH_SELECT_FIELD_PROJECTS_QUERY = gql`
  query SelectedFieldProjects(
    $filters: ProjectsFilters
    $sort: [ProjectsSortEnum!]
    $limit: Int
    $offset: Int
  ) {
    projects(filters: $filters, sort: $sort, limit: $limit, offset: $offset) {
      nodes {
        id
        uuid
        nanoId
        name
      }
      paginationInfo {
        nextPage
        totalCount
      }
      sql
    }
  }
`;
