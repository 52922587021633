import { useCallback, useEffect, useMemo } from 'react';
import compact from 'lodash/compact';
import find from 'lodash/find';
import sortBy from 'lodash/sortBy';
import isEqual from 'lodash/isEqual';

import {
  FETCH_LIFESTYLE_AUTHORS_SELECTED_QUERY,
  FetchLifestyleAuthorsSelectedQueryResponse
} from '../../../../../queries/fetchLifestyleAuthorsSelected.query';
import {
  FETCH_LIFESTYLE_AUTHORS_QUERY,
  FetchLifestyleAuthorsQueryResponse
} from '../../../../../queries/fetchLifestyleAuthors.query';
import { CACHE_FETCH_LIFESTYLE_AUTHORS_QUERY } from '../../../../../queries/fetchCacheLifestyleAuthors.query';
import { CACHE_FETCH_LIFESTYLE_AUTHORS_SELECTED_QUERY } from '../../../../../queries/fetchCacheLifestyleAuthorsSelected.query';

import { useFinPaginatedCategories } from '../../../../../../categories/hooks/useFinPaginatedCategories';
import { useFinInfiniteCategories } from '../../../../../../categories/hooks/useFinInfiniteCategories';

import { LifestyleCache } from '../../../../../LifestyleCache';

import {
  CategoryID,
  FetchCategoriesScopes,
  FetchCategoriesSortTypes
} from '../../../../../../categories/categoriesTypes';

import {
  LifestyleClientID,
  ChangeLifestylesFiltersFunc
} from '../../../../../lifestylesTypes';
import { LifestylesAuthorsFilterDataItem } from '../../LifestylesAuthorsFilter.types';

import { CategoriesPermissions } from '../../../../../../categories/categoriesConstants';

interface useLifestylesAuthorsFilterProps {
  clientIds?: LifestyleClientID[];
  selectedIds: CategoryID[];
  name: string;
  changeLifestylesFilters: ChangeLifestylesFiltersFunc;
}

const staleTime = 1000 * 60 * 60;

function useLifestylesAuthorsFilter({
  clientIds,
  selectedIds = [],
  name,
  changeLifestylesFilters
}: useLifestylesAuthorsFilterProps) {
  // const cacheKey = clientId
  //   ? LifestyleCache.authorsLibraryFilterCacheKey(clientId)
  //   : LifestyleCache.authorsFilterCacheKey();
  // const initialFilters = clientId
  //   ? { ...defaultInitialFilters, lifestyleClientIds: [clientId] }
  //   : defaultInitialFilters;

  const {
    categories: lifestyleAuthors,
    categoriesError: lifestyleAuthorsErrorMessage,
    categoriesFetched: lifestyleAuthorsFetched,
    categoriesIsPlaceholderData: lifestyleAuthorsIsPlaceholderData,
    isFetchingNextCategoriesPage: lifestyleAuthorsFetchingNextPage,
    hasNextCategoriesPage: hasNextLifestyleAuthorsPage,
    loadMoreCategories: loadMoreLifestyleAuthors,
    categoriesFilters: lifestyleAuthorsFilter,
    changeCategoriesFilters: changeLifestyleAuthorsFilters
  } = useFinInfiniteCategories<FetchLifestyleAuthorsQueryResponse>({
    action: CategoriesPermissions.READ_LIFESTYLE_CATEGORIES_INDEX_ES_QUERY,
    cacheKey: LifestyleCache.authorsFilterCacheKey(),
    query: FETCH_LIFESTYLE_AUTHORS_QUERY,
    cacheQuery: CACHE_FETCH_LIFESTYLE_AUTHORS_QUERY,
    trackTotalHits: true,
    initialFilters: {
      scope: { eq: FetchCategoriesScopes.SCENE_PERFORMERS }
    },
    initialSort: [FetchCategoriesSortTypes.LOCALIZED_NAME_ASC]
  });

  const {
    categories: lifestyleAuthorsSelected,
    categoriesError: lifestyleAuthorsSelectedErrorMessage,
    categoriesFilters: lifestyleAuthorsSelectedFilters,
    changeCategoriesFilters: changeLifestyleAuthorsSelectedFilters
  } = useFinPaginatedCategories<FetchLifestyleAuthorsSelectedQueryResponse>({
    action: CategoriesPermissions.READ_LIFESTYLE_CATEGORIES_INDEX_ES_QUERY,
    cacheKey: LifestyleCache.authorsSelectedFilterCacheKey(),
    query: FETCH_LIFESTYLE_AUTHORS_SELECTED_QUERY,
    cacheQuery: CACHE_FETCH_LIFESTYLE_AUTHORS_SELECTED_QUERY,
    initialFilters: {
      scope: { eq: FetchCategoriesScopes.SCENE_PERFORMERS },
      id: { in: selectedIds }
    },
    options: {
      staleTime,
      keepPreviousData: true
    }
  });

  useEffect(() => {
    if (!isEqual(lifestyleAuthorsSelectedFilters?.id?.in, selectedIds)) {
      changeLifestyleAuthorsSelectedFilters({
        id: { in: selectedIds }
      });
    }
  }, [
    changeLifestyleAuthorsSelectedFilters,
    lifestyleAuthorsSelectedFilters?.id?.in,
    selectedIds
  ]);

  const handleLifestyleAuthorsFilterSearch = useCallback(
    (input: string) => {
      changeLifestyleAuthorsFilters(
        { name: { ilike: input } },
        input ? [] : ['name']
      );
    },
    [changeLifestyleAuthorsFilters]
  );

  const lifestyleAuthorsFilterSelectedData = useMemo(() => {
    const selectedFetchedData = lifestyleAuthorsSelected.map((category) => ({
      id: category.id as string,
      label: category.localizedName
    }));

    const selectedData = compact(
      selectedIds.map((id) => find(selectedFetchedData, ['id', id]))
    );
    return selectedData;
  }, [lifestyleAuthorsSelected, selectedIds]);

  const lifestylesAuthorsFilterData = useMemo<
    LifestylesAuthorsFilterDataItem[]
  >(() => {
    return sortBy(
      lifestyleAuthors.map((category) => ({
        id: category.id as string,
        label: category.localizedName
      })),
      'label'
    );
  }, [lifestyleAuthors]);

  const handleChange = useCallback<
    (
      changedFilters: { [name: string]: string[] | undefined },
      removeFilters: string[]
    ) => void
  >(
    (changedFilters, removeFilters) => {
      changeLifestylesFilters(
        { [name]: { in: changedFilters?.[name] || [] } },
        removeFilters
      );
    },
    [changeLifestylesFilters, name]
  );

  return {
    lifestyleAuthorsFilterData: lifestyleAuthors,
    lifestyleAuthorsFilterSelectedData,
    lifestyleAuthorsFilter: lifestyleAuthors,
    lifestylesAuthorsFilterData,
    lifestyleAuthorsFilterErrorMessage: lifestyleAuthorsErrorMessage,
    lifestyleAuthorsFilterSelectedErrorMessage:
      lifestyleAuthorsSelectedErrorMessage,
    lifestyleAuthorsFilterFetched: lifestyleAuthorsFetched,
    lifestyleAuthorsFilterIsPlaceholderData: lifestyleAuthorsIsPlaceholderData,
    lifestyleAuthorsFilterFetchingNextPage: lifestyleAuthorsFetchingNextPage,
    hasNextLifestyleAuthorsFilterPage: hasNextLifestyleAuthorsPage,
    lifestyleAuthorsFilterSearchValue: lifestyleAuthorsFilter?.name?.ilike,
    handleLifestyleAuthorsFilterSearch,
    loadMoreLifestyleAuthorsFilter: loadMoreLifestyleAuthors,
    handleChangeLifestyleAuthorsFilter: handleChange
  };
}

export default useLifestylesAuthorsFilter;
