import get from 'lodash/get';

import {
  UserFullName,
  UserID,
  UserImageFile,
  UserLocale,
  UserNanoID,
  UserPermissionsAction,
  UserUUID,
  UserClient,
  UserCurrentTeamID,
  UserCurrentTeamNanoID,
  UserCreatedAt,
  UserRoleName,
  UserRoles,
  UserBlocked,
  UserBlockedTermsAcceptedAt
} from '../../../main/users/usersTypes';

import { productionConsole } from '../../../utils/productionConsole';

import { UserInfo } from './useAuth.types';
import reduce from 'lodash/reduce';

type UserPermissionsView = [UserPermissionsAction, boolean][];

class AuthenticatedUser {
  id: UserID;
  uuid: UserUUID;
  nanoId: UserNanoID;
  fullName: UserFullName;
  locale: UserLocale;
  image: { file: UserImageFile } | null;
  client: UserClient;
  blocked?: UserBlocked;
  blockedTermsAcceptedAt?: UserBlockedTermsAcceptedAt;
  createdAt: UserCreatedAt;
  currentTeam?: {
    id: UserCurrentTeamID;
    nanoId: UserCurrentTeamNanoID;
  };
  frontendActions: { [action: UserPermissionsAction]: true };
  roles: { [role: UserRoleName]: true };
  isClient: boolean;

  // for QA and PMs (view permissions via console)
  private checkedPermissionsSessionKey = 'checkedFrontendActions';
  private checkedPermissionsCache: { [key: UserPermissionsAction]: boolean };
  private checkedPermissionsView: UserPermissionsView;

  constructor(values: UserInfo) {
    this.blocked = values.blocked;
    this.blockedTermsAcceptedAt = values.blockedTermsAcceptedAt;
    this.client = values.client;
    this.createdAt = values.createdAt;
    this.currentTeam = values.currentTeam;
    this.frontendActions = values.frontendActions;
    this.fullName = values.fullName;
    this.id = values.id;
    this.image = values.image;
    this.locale = values.locale;
    this.nanoId = values.nanoId;
    this.roles = reduce(
      values.roles,
      (acc, role) => ({ ...acc, [role.name]: true }),
      {}
    );
    this.uuid = values.uuid;
    this.checkedPermissionsCache = {};
    this.checkedPermissionsView = [];

    this.isClient = typeof window !== 'undefined';

    if (this.isClient) {
      (window as any).comparePermissions = (view) =>
        this.comparePermissionViews(view, this.checkedPermissionsView);
    }
  }

  get(field: string, defaultValue = null) {
    return get(this, field, defaultValue);
  }

  hasPermissions(action: UserPermissionsAction): boolean {
    const actionResult = this.frontendActions?.[action] || false;

    this.isClient && this.updateCheckedPermissions(action, actionResult);

    if (!actionResult) {
      productionConsole('log', 'hasPermissions', this.get('id'), action, {
        actionResult,
        client: this.get('client'),
        user: this
      });
    }

    return actionResult;
  }

  private updateCheckedPermissions(
    action: UserPermissionsAction,
    result: boolean
  ) {
    const permissionNotCached =
      this.checkedPermissionsCache[action] === undefined;
    const permissionChanged =
      !permissionNotCached && this.checkedPermissionsCache[action] !== result;

    permissionNotCached && this.checkedPermissionsView.push([action, result]);

    if (permissionChanged) {
      const changedItem = this.checkedPermissionsView.find(
        (item) => item[0] === action
      );
      if (changedItem) {
        changedItem[1] = result;
      }
    }

    this.checkedPermissionsCache[action] = result;

    (permissionNotCached || permissionChanged) &&
      sessionStorage.setItem(
        this.checkedPermissionsSessionKey,
        JSON.stringify(this.checkedPermissionsView)
      );
  }

  private comparePermissionViews(
    prevView: UserPermissionsView,
    nextView: UserPermissionsView
  ) {
    const result = {
      added: {},
      removed: {},
      changed: {}
    };

    const map1 = new Map(prevView);
    const map2 = new Map(nextView);

    for (const [key, value1] of map1.entries()) {
      if (!map2.has(key)) {
        result.removed[key] = value1;
      } else {
        const value2 = map2.get(key);
        if (value1 !== value2) {
          result.changed[key] = [value1, value2 as boolean];
        }
      }
    }

    for (const [key, value2] of map2.entries()) {
      if (!map1.has(key)) {
        result.added[key] = value2;
      }
    }

    return result;
  }

  hasRole(role: UserRoles): boolean {
    return this.roles?.[role] || false;
  }

  // hasPermissions(
  //   action: UserPermissionsAction,
  //   ignoreSelfAction: UserPermissionsAction | null = null,
  //   userId: UserID | null = null
  // ): boolean {
  //   return true;
  //
  //   // const actionResult = has(this.permissions || {}, action) || false;
  //   //
  //   // if (ignoreSelfAction && userId) {
  //   //   return this.get('id') === userId
  //   //     ? actionResult
  //   //     : has(this.permissions || {}, ignoreSelfAction) || false;
  //   // }
  //   //
  //   // if (!actionResult && !this.shownPermissions[action]) {
  //   //   this.shownPermissions[action] = action;
  //   //   // productionConsole('log', 'hasPermissions', this.get('id'), action, {
  //   //   //   actionResult,
  //   //   //   client: this.get('client'),
  //   //   //   ignoreSelfAction,
  //   //   //   userId,
  //   //   //   user: this
  //   //   // });
  //   // }
  //   //
  //   // if (!this.shownPermissions[action]) {
  //   //   this.shownPermissions[action] = action;
  //   //   // productionConsole('warn', 'hasPermissions', this.get('id'), action, {
  //   //   //   actionResult,
  //   //   //   client: this.get('client'),
  //   //   //   ignoreSelfAction,
  //   //   //   userId,
  //   //   //   user: this
  //   //   // });
  //   // }
  //   //
  //   // return actionResult;
  // }
  //
  // isClient() {
  //   return this.get('client');
  // }

  isEn() {
    return this.get('locale') === 'en';
  }

  isRu() {
    return this.get('locale') === 'ru';
  }

  isUk() {
    return this.get('locale') === 'ru';
  }

  isAuthenticated() {
    return !!this.get('uuid');
  }

  // canChange(user: CurrentUser, action: string) {
  //   if (this.get('id') === user.id) {
  //     return true;
  //   }
  //
  //   return this.hasPermissions(action);
  // }
}

export default AuthenticatedUser;
