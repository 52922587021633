import { fetchCacheItems } from '../../../baseActions/fetchCacheItems';
import { fetchFinItems } from '../../../baseActions/fetchFinItems';
import { fetchItems } from '../../../baseActions/fetchItems';
import { fetchReadItems } from '../../../baseActions/fetchReadItems';
import {
  FetchCacheItemsSources,
  FetchCacheItemsTrackTotalHits,
  FetchItemsFilters,
  FetchItemsGqlQuery,
  FetchItemsLimit,
  FetchItemsPage,
  FetchItemsSort
} from '../../../../../../../../types';

interface FetchCacheItemsOptions {
  filters: FetchItemsFilters;
  limit: FetchItemsLimit;
  page: FetchItemsPage;
  query: FetchItemsGqlQuery;
  sort: FetchItemsSort;
  source?: FetchCacheItemsSources;
  targetApi?: 'api' | 'read_api' | 'fin_api' | 'cache_api';
  trackTotalHits?: FetchCacheItemsTrackTotalHits;
}

function handleFetchCacheItems({
  filters,
  limit,
  page,
  query,
  sort,
  source = FetchCacheItemsSources.ES,
  trackTotalHits = false,
  targetApi = 'api',
  ...additionalParams
}: FetchCacheItemsOptions) {
  switch (targetApi) {
    case 'api':
      return fetchItems({
        query,
        filters,
        sort,
        page,
        limit,
        ...additionalParams
      });
    case 'cache_api':
      return fetchCacheItems({
        query,
        source,
        trackTotalHits,
        filters,
        sort,
        page,
        limit,
        ...additionalParams
      });
    case 'read_api':
      return fetchReadItems({
        query,
        filters,
        sort,
        page,
        limit,
        ...additionalParams
      });
    case 'fin_api':
      return fetchFinItems({
        query,
        filters,
        sort,
        page,
        limit,
        ...additionalParams
      });
  }
}

export default handleFetchCacheItems;
