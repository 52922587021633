import { gql } from 'graphql-request';

import {
  TaskMemberInviteUUID,
  TaskMemberInviteCreatedAt,
  TaskMemberInviteTaskID,
  TaskMemberInviteTaskUUID,
  TaskMemberInviteID,
  TaskMemberInviteTaskNanoID,
  TaskMemberInviteTaskName,
  TaskMemberInviteTaskProjectNanoID,
  TaskMemberInviteTaskProjectName,
  TaskMemberInviteTaskUserID,
  TaskMemberInviteTaskUserUUID,
  TaskMemberInviteTaskUserNanoID,
  TaskMemberInviteTaskUserFullName,
  TaskMemberInviteTaskUserClient,
  TaskMemberInviteTaskUserCurrentTeamNanoID,
  TaskMemberInviteTaskVisibleForClient,
  TaskMemberInviteTaskStatus,
  TaskMemberInviteTaskImplementationDate,
  TaskMemberInviteTaskBurning,
  TaskMemberInviteTaskAmount,
  TaskMemberInviteTaskPrepayment,
  TaskMemberInviteTaskClientPrepayment,
  TaskMemberInviteTaskLastActivity,
  TaskMemberInviteTaskCreatedAt,
  TaskMemberInviteTaskProjectTeamName,
  TaskMemberInviteTaskProjectTeamNanoID,
  TaskMemberInviteTaskProjectTeamImageFile,
  TaskMemberInviteTaskLastMessageBody,
  TaskMemberInviteTaskLastMessageCreatedAt,
  TaskMemberInviteTaskUserImageUUID,
  TaskMemberInviteTaskUserImageFile,
  TaskMemberInviteTaskMembersID,
  TaskMemberInviteTaskMembersUUID,
  TaskMemberInviteTaskMembersNanoID,
  TaskMemberInviteTaskMembersFullName,
  TaskMemberInviteTaskMembersClient,
  TaskMemberInviteTaskMembersCurrentTeamNanoID,
  TaskMemberInviteTaskMembersImageUUID,
  TaskMemberInviteTaskMembersImageFile
} from '../taskMemberInvitesTypes';
import {
  SmartContractID,
  SmartContractSmartContractType,
  SmartContractStatus
} from '../../smartContracts/smartContractsTypes';
import {
  SmartContractShareGeneralLedgerCompanyID,
  SmartContractShareGeneralLedgerCompanyNanoID,
  SmartContractShareGeneralLedgerCompanyOwnerID,
  SmartContractShareGeneralLedgerCompanyOwnerNanoID,
  SmartContractShareGeneralLedgerID,
  SmartContractShareID,
  SmartContractShareShare,
  SmartContractShareStatus
} from '../../smartContractShares/smartContractSharesTypes';

export interface FetchFinTaskMemberInvitesQueryResponse {
  id: TaskMemberInviteID;
  uuid: TaskMemberInviteUUID;
  createdAt: TaskMemberInviteCreatedAt;
  task: {
    id: TaskMemberInviteTaskID;
    uuid: TaskMemberInviteTaskUUID;
    nanoId: TaskMemberInviteTaskNanoID;
    name: TaskMemberInviteTaskName;
    visibleForClient: TaskMemberInviteTaskVisibleForClient;
    status: TaskMemberInviteTaskStatus;
    implementationDate: TaskMemberInviteTaskImplementationDate;
    burning: TaskMemberInviteTaskBurning;
    amount: TaskMemberInviteTaskAmount;
    prepayment: TaskMemberInviteTaskPrepayment;
    clientPrepayment: TaskMemberInviteTaskClientPrepayment;
    lastActivity: TaskMemberInviteTaskLastActivity;
    createdAt: TaskMemberInviteTaskCreatedAt;
    project: {
      nanoId: TaskMemberInviteTaskProjectNanoID;
      name: TaskMemberInviteTaskProjectName;
      team: {
        name: TaskMemberInviteTaskProjectTeamName;
        nanoId: TaskMemberInviteTaskProjectTeamNanoID;
        image?: {
          file: TaskMemberInviteTaskProjectTeamImageFile;
        };
      };
    };
    lastMessage: {
      body: TaskMemberInviteTaskLastMessageBody;
      createdAt: TaskMemberInviteTaskLastMessageCreatedAt;
    };
    user: {
      id: TaskMemberInviteTaskUserID;
      uuid: TaskMemberInviteTaskUserUUID;
      nanoId: TaskMemberInviteTaskUserNanoID;
      fullName: TaskMemberInviteTaskUserFullName;
      client: TaskMemberInviteTaskUserClient;
      currentTeam: {
        nanoId: TaskMemberInviteTaskUserCurrentTeamNanoID;
      };
      image: {
        uuid: TaskMemberInviteTaskUserImageUUID;
        file: TaskMemberInviteTaskUserImageFile;
      };
    };
    members: {
      id: TaskMemberInviteTaskMembersID;
      uuid: TaskMemberInviteTaskMembersUUID;
      nanoId: TaskMemberInviteTaskMembersNanoID;
      fullName: TaskMemberInviteTaskMembersFullName;
      client: TaskMemberInviteTaskMembersClient;
      currentTeam: {
        nanoId: TaskMemberInviteTaskMembersCurrentTeamNanoID;
      };
      image: {
        uuid: TaskMemberInviteTaskMembersImageUUID;
        file: TaskMemberInviteTaskMembersImageFile;
      };
    }[];
  };
  bitTask: {
    id: TaskMemberInviteTaskID;
    uuid: TaskMemberInviteTaskUUID;
    nanoId: TaskMemberInviteTaskNanoID;
    name: TaskMemberInviteTaskName;
    project: {
      nanoId: TaskMemberInviteTaskProjectNanoID;
      name: TaskMemberInviteTaskProjectName;
    };
    smartContracts: {
      id: SmartContractID;
      status: SmartContractStatus;
      smartContractType: SmartContractSmartContractType;
      smartContractShares: {
        id: SmartContractShareID;
        share: SmartContractShareShare;
        status: SmartContractShareStatus;
        generalLedger: {
          id: SmartContractShareGeneralLedgerID;
          company: {
            id: SmartContractShareGeneralLedgerCompanyID;
            nanoId: SmartContractShareGeneralLedgerCompanyNanoID;
            owner: {
              id: SmartContractShareGeneralLedgerCompanyOwnerID;
              nanoId: SmartContractShareGeneralLedgerCompanyOwnerNanoID;
            };
          };
        };
      }[];
    }[];
  };
  user: {
    id: TaskMemberInviteTaskUserID;
    uuid: TaskMemberInviteTaskUserUUID;
    nanoId: TaskMemberInviteTaskUserNanoID;
    fullName: TaskMemberInviteTaskUserFullName;
    client: TaskMemberInviteTaskUserClient;
    currentTeam: {
      nanoId: TaskMemberInviteTaskUserCurrentTeamNanoID;
    };
  };
}

export const FETCH_FIN_TASK_MEMBER_INVITES_QUERY = gql`
  query FinTaskMemberInvites(
    $filters: TaskMemberInvitesFilters
    $sort: [TaskMemberInvitesSortEnum!]
    $limit: Int
    $offset: Int
  ) {
    taskMemberInvites(
      filters: $filters
      sort: $sort
      limit: $limit
      offset: $offset
    ) {
      nodes {
        id
        uuid
        createdAt
        task {
          id
          uuid
          nanoId
          name
          visibleForClient
          status
          implementationDate
          burning
          amount
          prepayment
          clientPrepayment
          lastActivity
          createdAt
          project {
            nanoId
            name
            team {
              name
              nanoId
              image {
                file
              }
            }
          }
          lastMessage {
            body
            createdAt
          }
          user {
            id
            uuid
            nanoId
            fullName
            client
            currentTeam {
              nanoId
            }
            image {
              uuid
              file
            }
          }
          members {
            id
            uuid
            nanoId
            fullName
            client
            currentTeam {
              nanoId
            }
            image {
              uuid
              file
            }
          }
        }
        bitTask {
          id
          uuid
          nanoId
          name
          project {
            nanoId
            name
          }
          smartContracts {
            id
            status
            smartContractType
            smartContractShares {
              id
              share
              status
              generalLedger {
                id
                company {
                  id
                  nanoId
                  owner {
                    id
                    nanoId
                  }
                }
              }
            }
          }
        }
      }
      paginationInfo {
        nextPage
        totalCount
      }
      sql
    }
  }
`;
