import { gql } from 'graphql-request';

import {
  SmartContractID,
  SmartContractItemID,
  SmartContractItemItemCategoryName,
  SmartContractItemPrice
} from '../smartContractsTypes';

export interface FetchDashboardSmartContractsQueryResponse {
  id: SmartContractID;
  items: {
    id: SmartContractItemID;
    price: SmartContractItemPrice;
    itemCategoryName: SmartContractItemItemCategoryName;
  }[];
}

export const FETCH_DASHBOARD_SMART_CONTRACTS_QUERY = gql`
  query DashboardSmartContracts(
    $filters: SmartContractsFilters
    $sort: [SmartContractsSortEnum!]
    $limit: Int
    $offset: Int
  ) {
    smartContracts(
      filters: $filters
      sort: $sort
      limit: $limit
      offset: $offset
    ) {
      nodes {
        id
        items {
          id
          price
          itemCategoryName
        }
      }
      paginationInfo {
        nextPage
        totalCount
      }
      sql
    }
  }
`;
