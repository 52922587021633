import { gql } from 'graphql-request';
import {
  TeamCreatedAt,
  TeamName,
  TeamNanoID,
  TeamTeamType,
  TeamUpdatedAt,
  TeamUUID
} from '../teamsTypes';

export interface FetchTeamsFilterQueryResponse {
  nanoId: TeamNanoID;
  uuid: TeamUUID;
  name: TeamName;
  createdAt: TeamCreatedAt;
  teamType: TeamTeamType;
  updatedAt: TeamUpdatedAt;
}

export const FETCH_TEAMS_FILTER_QUERY = gql`
  query TeamsFilter(
    $filters: TeamsFilters
    $sort: [TeamsSortEnum!]
    $limit: Int
    $offset: Int
  ) {
    teams(filters: $filters, sort: $sort, offset: $offset, limit: $limit) {
      nodes {
        nanoId
        uuid
        name
        teamType
        createdAt
        updatedAt
      }
      paginationInfo {
        currentPage
        firstPage
        lastPage
        limitValue
        nextPage
        outOfRange
        prevPage
        totalCount
        totalPages
      }
      sql
    }
  }
`;
