import { useEffect } from 'react';
import isEqual from 'lodash/isEqual';

import {
  FetchProductCategoriesCacheKey,
  FetchProductsFilters
} from '../../productsTypes';
import { TextFilterType } from '../../../../types';
import { FetchFinCategoriesFiltersProductFilters } from '../../../categories/categoriesTypes';

import { usePreviousValue } from '../../../../common/hooks/usePreviousValue';
import { useCacheIndexQuery } from '../../../common/hooks/base/reactQuery/useCacheIndexQuery';
import { FETCH_CACHE_PRODUCT_CATEGORIES_QUERY } from '../../queries/fetchCacheProductCategories.query';
import {
  FETCH_PRODUCT_CATEGORIES_QUERY,
  FetchProductCategoriesQueryResponse
} from '../../queries/fetchProductCategories.query';
import { CategoriesPermissions } from '../../../categories/categoriesConstants';

const defaultInitialFilters: { scope: TextFilterType } = {
  scope: {
    eq: 'facility_groups'
  }
};
const initialSort = ['CREATED_AT_ASC'];
const options = {
  staleTime: 1000 * 60 * 60,
  withoutPrefetch: true
};

const scope = 'categories';

function transformFacilityGroupsByProduct(
  facilityGroupsByProduct: FetchProductsFilters
): FetchFinCategoriesFiltersProductFilters {
  const blocked = facilityGroupsByProduct?.blocked;
  const brandId = facilityGroupsByProduct?.brandId;
  const favorite = facilityGroupsByProduct?.favorite;
  const nda = facilityGroupsByProduct?.nda;
  const productTypeId = facilityGroupsByProduct?.productTypeId;
  const seasonalId = facilityGroupsByProduct?.seasonalId;
  const styleId = facilityGroupsByProduct?.styleId;

  const colorId = facilityGroupsByProduct?.colorIds
    ? { in: facilityGroupsByProduct.colorIds }
    : undefined;
  const materialId = facilityGroupsByProduct?.materialIds
    ? { in: facilityGroupsByProduct.materialIds }
    : undefined;
  const clientId = facilityGroupsByProduct?.clientIds
    ? { in: facilityGroupsByProduct?.clientIds }
    : undefined;

  return {
    blocked,
    brandId,
    colorId,
    favorite,
    materialId,
    nda,
    productTypeId,
    seasonalId,
    styleId,
    clientId
  };
}

interface FinProductCategoriesOptions {
  cacheKey?: FetchProductCategoriesCacheKey;
  facilityGroupsByProduct?: FetchProductsFilters;
}

function useFinProductCategories({
  cacheKey,
  facilityGroupsByProduct: initFacilityGroupsByProduct
}: FinProductCategoriesOptions = {}) {
  const facilityGroupsByProduct = transformFacilityGroupsByProduct(
    initFacilityGroupsByProduct
  );

  const initialFilters = {
    ...defaultInitialFilters,
    facilityGroupsByProduct
  };

  const {
    items,
    itemsError,
    isFetched,
    isPlaceholderData,
    changeItemsFilters
  } = useCacheIndexQuery<FetchProductCategoriesQueryResponse>({
    action: CategoriesPermissions.READ_PRODUCT_CATEGORIES_INDEX_ES_QUERY,
    query: FETCH_PRODUCT_CATEGORIES_QUERY,
    cacheQuery: FETCH_CACHE_PRODUCT_CATEGORIES_QUERY,
    cacheKey,
    defaultApi: 'fin_api',
    initialFilters,
    initialSort,
    initialLimit: 1000,
    scope,
    trackTotalHits: true,
    options
  });

  const prevFacilityGroupsByProduct = usePreviousValue(
    initFacilityGroupsByProduct
  );

  useEffect(() => {
    if (!isEqual(prevFacilityGroupsByProduct, initFacilityGroupsByProduct)) {
      changeItemsFilters({
        facilityGroupsByProduct: transformFacilityGroupsByProduct(
          initFacilityGroupsByProduct
        )
      });
    }
  }, [
    changeItemsFilters,
    initFacilityGroupsByProduct,
    prevFacilityGroupsByProduct
  ]);

  return {
    productCategories: items,
    productCategoriesErrorMessage: itemsError,
    productCategoriesFetched: isFetched,
    productCategoriesIsPlaceholderData: isPlaceholderData,
    changeProductCategoriesFilters: changeItemsFilters
  };
}

export default useFinProductCategories;
