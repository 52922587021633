export default {
  addYourCommentHere: 'Add your comment here',
  addDescriptionToYourTaskToProvideMoreInformationToYourTeam:
    'Add a description to your task to provide more information to your team.',
  accordingToTheRulesForRoundingContractorInvoiceAmountsUpTo001TheAmountMayDifferFromThePaidByAFewCents:
    'According to the rules for rounding contractor invoice amounts up to $0.01, the Amount may differ from the Paid by a few cents.',
  applyToAll: 'Apply to all',
  allInvoiceTagsWillBeUpdated: 'All invoice tags will be updated',
  alreadyAdded: 'Already added',
  areYouSureYouWantToDeleteThisRecordThisActionCannotBeUndone:
    'Are you sure you want to delete this record? This action cannot be undone.',
  areYouSureYouWantToDeleteThisTransactionImports:
    'Are you sure you want to delete this transaction imports?',
  areYouSureYouWantToAbortTheTask: 'Are you sure you want to abort the task?',
  areYouSureYouWantToDeleteTheTask: 'Are you sure you want to delete the task?',
  areYouSureYouWantToCancelTheTask: 'Are you sure you want to cancel the task?',
  areYouSureYouWantToCloseTheTask: 'Are you sure you want to close the task?',
  areYouSureYouWantToCancelCreatingTasks:
    'Are you sure you want to cancel creating tasks?',
  areYouSureYouWantToClearSelected: 'Are you sure you want to clear selected?',
  areYouSureYouWantToCreateMissingWhiteboardShapes:
    'Are you sure you want to create missing whiteboard shapes?',
  areYouSureYouWantToClearAllFormFields:
    'Are you sure you want to clear all form fields?',
  areYouSureYouWantToDeletTheBillingAddress:
    'Are you sure you want to delete the billing address?',
  areYouSureYouWantToDeleteAccount: 'Are you sure you want to delete account?',
  areYouSureYouWantToBlockThisAccount:
    'Are you sure you want to block this account?',
  areYouSureYouWantToUnblockThisAccount:
    'Are you sure you want to unblock this account?',
  areYouSureYouWantToDeleteThisAccount:
    'Are you sure you want to delete this account? You will lose access to it forever.\n\nIf you are sure, confirm by entering the password below.',
  areYouSureYouWantToDeleteThisThread:
    'Are you sure you want to delete this thread?',
  areYouSureYouWantToDeleteThisTemplate:
    'Are you sure you want to delete this template?',
  areYouSureYouWantToDeletTheField:
    'Are you sure you want to delete the field?',
  areYouSureYouWantToRemoveThisUserFromCompany:
    'Are you sure you want to remove this user from company?',
  areYouSureYouWantToRemoveItemsFromDraft:
    'Are you sure you want to remove this items from draft invoice?',
  areYouSureYouWantToBlockThisCompany:
    'Are you sure you want to block this company? All members will lose access to it.',
  areYouSureYouWantToReplaceActiveWhiteboardWithBackup:
    'Are you sure you want to replace active whiteboard with backup?',
  areYouSureYouWantToDeleteSavedFilterThisActionCannotBeUndone:
    'Are you sure you want to delete saved filter? This action cannot be undone.',
  atLeastOneTaskMustBeEntered: 'At least one task must be entered',
  clearFilters: 'clear filters',
  convertFromMarkdown: 'Convert from markdown',
  changeStudioPage: 'Change studio page',
  changeStudio: 'Change studio',
  createStudioPage: 'Create studio page',
  createStudio: 'Create studio',
  dropTheFilesHere: 'Drop the files here ...',
  byRatingTasksYouAcceptTaskResultsForTheClient:
    'By rating tasks, you accept task results for the client',
  changeVisibility: 'Change visibility',
  deleteThread: 'Delete thread',
  dragAndDropFilesOr: 'Drag and drop files, or:',
  fileIsUploading: 'File is uploading...',
  filterByDate: 'Filter by date',
  helpWithTextFormatting: 'Help with text formatting',
  indicateHowMuchDoYouAgreeWithTheFollowingStatement:
    'Please rate the degree to which you agree or disagree with each of the following statements',
  iHaveReadAndAgreeToTheUpdatedInformation:
    'I have read and agree to the updated information',
  pleaseConsiderCreatingANewTaskWithFurtherInstructionsOrContactUsAtProjectThreadDirectly:
    'Please consider creating a new task with further instructions or contact us at project thread directly.',
  pleaseProvideYourAddress: 'Please, provide your address',
  pleaseRateTheTask: 'Please, rate the task',
  pleaseRateTaskRusult: 'Please, rate task result',
  previewTextFormatting: 'Preview text formatting',
  iConfirmThatIHaveReadAndAgreeTo: 'I confirm that I have read and agree to ',
  inviteYourTeamMembers: 'Invite your team members',
  learnAboutItemTypes: 'Learn about item types',
  letsGetToKnowEachOther: "Let's get to know each other",
  acceptTaskResult: 'Accept task result',
  approveTaskResult: 'Approve task results',
  weAreOnline: "We're online 08:00 AM to 05:00 PM GMT Monday-Friday.",
  weAreReadyToHelp:
    "At that time we're ready to help and answer any questions.",
  whiteboardDataHasBeenUpdatedPleaseReloadThePage:
    'Whiteboard data has been updated. Please reload the page.',
  writeHereWarning:
    'The team may not track this task since it has been completed, are you sure you want to leave a message here?',
  selectInvoiceFromTheList: 'Select invoice from the list',
  sendEmailNotification: 'Send email notification',
  sendToWhiteboard: 'Send to whiteboard',
  textFormattingHelp: 'Text formatting help',
  thePaymentHasBeenAcceptedForProcessingUsually:
    'The payment has been accepted for processing. Usually the payment is processed and credited, or canceled by the payment system/bank that has issued the card within 10—30 minutes. One should not make new payment attempts during this time.',
  thePaymentHasBeenCanceled: 'The payment has been canceled.',
  theTaskResultHasBeenApproved:
    'The task result has been approved by the quality control manager. Waiting for the client to rate and accept the result.',
  toSetUpTheInterfaceForTheRightWorkflow:
    'To set up the interface for the right workflow, we need to know a bit about you.',
  pleaseClickOnStarsToRateTheResult:
    'Please click on stars to rate the results and then Accept in case you are satisfied and want to download the final files',
  thisCommentWouldBeVisibleOnlyInTimeline:
    'This comment would be visible only in Timeline',
  thereIsNoBillingInfoInTheCompany: 'There is no billing info in the company.',
  youWillBeRedirectedToTheProjectPageIn:
    'You will be redirected to the project page in',
  youWillBeRedirectedToThePaymentsPageIn:
    'You will be redirected to the payments page in',
  startTypingToSearchForClients: 'Start typing to search for clients',
  studioEmbedId: 'Studio embed id',
  errorLoadingImage: 'Error loading image',
  thisActionWillAffect: 'This action will affect',
  updateCost: 'Update cost',
  updateDueDate: 'Update due date',
  pleaseWaitYourDownloadWillBeReadyInSeveralMinutes:
    'Please, wait. Your download will be ready in several minutes.',
  orChangeTableSettingsToAlwaysShowHiddenElements:
    'or change table settings to always show hidden elements.',
  hiddenAnd: 'hidden and',
  hereIsTheExampleOfWhatYoullGet: 'Here is the example of what you’ll get',
  beforeYouAcceptTheTaskWeLikeToRemindYouThat: `Before you accept the task, we'd like to remind you that:`,
  onceYouAcceptTheTaskItWillCloseAndYouWillNotBeAbleToLeaveNewCommentsInIt: `Once you accept the task, it will close and you won't be able to leave new comments in it.`,
  ifYouAreUnsureWhoToInviteNowYouCanSkip:
    'If you’re unsure who to invite now, you can skip this step, and we’ll help you add your team later.',
  ifYouWantTheResultTweakedOrAdjustedAfterThatItWillBeANewTaskAndChargedAccordingly:
    'If you want the result tweaked or adjusted after that, it will be a new task and charged accordingly.',
  pleaseRateResults: 'Please rate results',
  thereAreNoNonValidTasks: 'There are no non-valid tasks.',
  thereAreNoValidTasks: 'There are no valid tasks.',
  youCanAlwaysDownloadTheFilesToSeeThemOnYourDeviceBeforeAcceptingTheTask:
    'You can always download the files to see them on your device before accepting the task',
  linkToTheCRMaUsernameAndPasswordCombinationUsedForLoggingInToOnlineAccountEtc:
    'Link to the CRM, a username and password combination used for logging in to online account etc.',
  youAreAboutToChangeTheClientExistingTasksItemsWillBeDeletedAreYouSureYouWantToProceed:
    'You are about to change the client. Existing tasks items will be deleted. Are you sure you want to proceed?',
  pleaseAdjustYourFiltersAndTryAgainOr:
    'Please adjust your filters and try again or',
  noItemsMatchYourSearchCriteria: 'No items match your search criteria.',
  sorryNoItemsMatchYourSearchCriteria:
    'Sorry, no items match your search criteria.',
  letsCreateYourFirstProject: "Let's create your first project",
  letsDiveIn: "Let's dive in!",
  startByOutliningYourProjectVisionBe:
    'Start by outlining your project vision—be it for architecture, product prototyping, or virtual environments.',
  ourExpertTeamWillWorkCloselyWith:
    'Our expert team will work closely with you to transform your concepts into captivating 3D visuals.',
  hereIsABriefOfOurServicesAndPricesNo:
    "Here's a brief of our services and prices. No commitment now; adjust anytime.",
  unsureClickStartProjectAnd:
    'Unsure? Click "Start project", and we\'ll sort it out.',
  asTeamMemberOf: 'as team member of:',
  generateApplicationToken1: 'Generate Application Token 1',
  generateApplicationToken2: 'Generate Application Token 2',
  refreshAccessToken: 'Refresh Access Token',
  reverseChargeForServices:
    'Reverse charge for services. Customer to account for the VAT.',
  yourPaymentIsBeingProcessedBeAdvisedThat:
    'Your payment is being processed. Be advised that the transaction may require 10 to 20 minutes to fully complete.',
  uploadASinglePhotoOfTheRoomAndOurAIWill:
    'Upload a single photo of the room, and our AI will detect the furniture pieces.',
  selectTheObjectYouWantToSearchInOur3DLibrary:
    'Select the object you want to search in our 3D library.',
  saveFieldInCompanyProfile: 'Save field in company profile',
  theFunctionalityIsNotAvailableForSearchWithAI:
    'The functionality is not available for search with AI',
  thisAccountHasBeenDeletedPleaseContactSupport:
    'This account has been deleted. Please contact support.',
  uploadAPhotoOfARoomSelectACategory:
    'Upload a photo of a room, select a category in which you want to search, and our AI will find furniture items for you.'
};
