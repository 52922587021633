import { gql } from 'graphql-request';
import {
  TeamsUserUUID,
  TeamsUserLibrary,
  TeamsUserFinance,
  TeamsUserRequirements,
  TeamsUserUserNanoID,
  TeamsUserUserBlocked,
  TeamsUserUserFullName,
  TeamsUserUserClientStatus,
  TeamsUserUserLastMessageDate,
  TeamsUserUserImageFile,
  TeamsUserUserEmail,
  TeamsUserUserName,
  TeamsUserUserID,
  TeamsUserUserClient
} from '../teamsUsersTypes';

export interface FetchTeamsUsersQueryResponse {
  uuid: TeamsUserUUID;
  library: TeamsUserLibrary;
  finance: TeamsUserFinance;
  requirements: TeamsUserRequirements;
  user: {
    id: TeamsUserUserID;
    nanoId: TeamsUserUserNanoID;
    blocked: TeamsUserUserBlocked;
    name: TeamsUserUserName;
    fullName: TeamsUserUserFullName;
    email: TeamsUserUserEmail;
    client: TeamsUserUserClient;
    // clientStatus: TeamsUserUserClientStatus;
    // lastMessageDate: TeamsUserUserLastMessageDate;
    image: {
      file: TeamsUserUserImageFile;
    };
  };
}

export const FETCH_TEAMS_USERS_QUERY = gql`
  query TeamsUsers(
    $filters: TeamUsersFilters
    $sort: [TeamUsersSortEnum!]
    $limit: Int
    $offset: Int
  ) {
    teamsUsers(filters: $filters, sort: $sort, offset: $offset, limit: $limit) {
      nodes {
        uuid
        library
        finance
        requirements
        user {
          id
          nanoId
          blocked
          fullName
          email
          client
          # lastMessageDate
          # clientStatus
          image {
            file
          }
        }
      }
      paginationInfo {
        currentPage
        firstPage
        lastPage
        limitValue
        nextPage
        outOfRange
        prevPage
        totalCount
        totalPages
      }
      sql
    }
  }
`;
