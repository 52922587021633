import { useCallback, useMemo, useReducer } from 'react';
import { useInfiniteQuery, useQueryClient } from 'react-query';
import flatten from 'lodash/flatten';
import last from 'lodash/last';
import isEqual from 'lodash/isEqual';
import size from 'lodash/size';

import {
  FetchCacheItemSources,
  FetchCacheItemsSources,
  FetchCacheItemsTrackTotalHits,
  FetchItemsFilters,
  FetchItemsGqlQuery,
  FetchItemsLimit,
  FetchItemsSort,
  UUID
} from '../../../../../../types';

import {
  CacheInfiniteIndexErrorType,
  CacheInfiniteIndexQueryBaseNodeType,
  CacheInfiniteIndexQueryData,
  CacheInfiniteIndexQueryDefaultOptionsOpts,
  CacheInfiniteIndexQueryResponse,
  CacheInfiniteIndexQueryWithFetchItemOptions,
  CacheInfiniteIndexQueryWithoutFetchItemOptions
} from './useCacheInfiniteIndexQuery.types';
import { PermissionAction } from '../../../../../permissions/permissionsTypes';

import { useAddInfiniteIndexQueryItemCache } from '../useInfiniteIndexQuery/hooks/useAddInfiniteIndexQueryItemCache';
import { useCurrentUser } from '../../../../../../auth/hooks/useAuth';
import { useUpdateInfiniteIndexQueryItemCache } from '../useInfiniteIndexQuery/hooks/useUpdateInfiniteIndexQueryItemCache';

import {
  indexRequestReducer,
  IndexRequestReducerType
} from '../useInfiniteIndexQuery/reducers/indexRequestReducer';

import { changeItemsFiltersAction } from '../useInfiniteIndexQuery/actions/changeItemsFiltersAction';
import { clearItemsFiltersAction } from '../useInfiniteIndexQuery/actions/clearItemsFiltersAction';
import { filterItemsAction } from '../useInfiniteIndexQuery/actions/filterItemsAction';
import { limitItemsAction } from '../useInfiniteIndexQuery/actions/limitItemsAction';
import { sortItemsAction } from '../useInfiniteIndexQuery/actions/sortItemsAction';

import { LocalForage } from '../../../../../../utils/LocalForage';
import { handleFetchCacheItem } from '../useCacheIndexQuery/utils/handleFetchCacheItem';
import { handleFetchCacheItems } from '../useCacheIndexQuery/utils/handleFetchCacheItems';
import { parseRequestError } from '../../../../../../utils/parseRequestError';
import { retryCustomFunction } from '../useInfiniteIndexQuery/utils/retryCustomFunction';

import {
  INITIAL_FILTERS,
  INITIAL_LIMIT,
  INITIAL_PAGE,
  INITIAL_SORT
} from '../useInfiniteIndexQuery/infiniteIndexRequestConstants';
import { CommonPermissions } from '../../../../commonConstants';

interface CacheInfiniteIndexQueryOptions<NodeType, MetaType = unknown> {
  action: PermissionAction;
  additionalParams?: Record<string, unknown>;
  cacheKey: string;
  cacheQuery?: FetchItemsGqlQuery;
  defaultApi?: 'api' | 'read_api' | 'fin_api';
  initialFilters?: FetchItemsFilters;
  initialLimit?: FetchItemsLimit;
  initialSort?: FetchItemsSort;
  options?: CacheInfiniteIndexQueryDefaultOptionsOpts<NodeType, MetaType>;
  query: FetchItemsGqlQuery;
  scope: string;
  source?: FetchCacheItemsSources;
  trackTotalHits?: FetchCacheItemsTrackTotalHits;
}

function useCacheInfiniteIndexQuery<
  NodeType extends CacheInfiniteIndexQueryBaseNodeType,
  MetaType = unknown
>({
  action,
  additionalParams,
  cacheKey,
  cacheQuery,
  defaultApi = 'read_api',
  fetchCacheItemSource = FetchCacheItemSources.MNESIA,
  fetchItemCacheKey,
  fetchItemQuery,
  fetchCacheItemQuery,
  initialFilters = INITIAL_FILTERS,
  initialLimit = INITIAL_LIMIT,
  initialSort = INITIAL_SORT,
  options = {},
  query,
  scope,
  source = FetchCacheItemsSources.ES,
  trackTotalHits = false
}: CacheInfiniteIndexQueryOptions<NodeType, MetaType> &
  (
    | CacheInfiniteIndexQueryWithFetchItemOptions
    | CacheInfiniteIndexQueryWithoutFetchItemOptions
  )) {
  const currentUser = useCurrentUser();

  const localForageCacheKey = `${cacheKey}-infinite-index`;

  const targetApi =
    currentUser?.hasPermissions(action) &&
    currentUser.hasPermissions(CommonPermissions.READ_CACHE_QUERY) &&
    cacheQuery
      ? 'cache_api'
      : defaultApi;

  const currentQuery = targetApi === 'cache_api' ? cacheQuery : query;

  const currentFetchItemQuery =
    targetApi === 'cache_api' ? fetchCacheItemQuery : fetchItemQuery;

  const [{ currentFilters, currentLimit, currentSort }, dispatch] =
    useReducer<IndexRequestReducerType>(indexRequestReducer, {
      currentLimit: initialLimit,
      currentFilters: initialFilters,
      currentSort: initialSort
    });

  const { data: placeholderData, isFetched: placeholderDataFetched } =
    useInfiniteQuery<CacheInfiniteIndexQueryResponse<
      NodeType,
      MetaType
    > | null>(
      `${cacheKey}-placeholder`,
      () =>
        LocalForage.getItem<
          CacheInfiniteIndexQueryResponse<NodeType, MetaType>
        >(localForageCacheKey),
      {
        enabled: options.enabledPlaceholder
      }
    );

  const currentParams = useMemo(() => {
    return {
      filters: currentFilters,
      sort: currentSort,
      limit: currentLimit,
      ...additionalParams
    };
  }, [additionalParams, currentFilters, currentSort, currentLimit]);

  const fullCacheKey = useMemo(() => {
    return [cacheKey, currentParams];
  }, [cacheKey, currentParams]);

  const {
    data,
    isFetched,
    isLoading,
    error,
    isPlaceholderData,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage
  } = useInfiniteQuery<
    CacheInfiniteIndexQueryResponse<NodeType, MetaType>,
    CacheInfiniteIndexErrorType
  >(
    fullCacheKey,
    useCallback(
      ({ pageParam = INITIAL_PAGE }) =>
        handleFetchCacheItems({
          page: pageParam,
          query: currentQuery,
          source,
          targetApi,
          trackTotalHits,
          ...currentParams
        }),
      [currentParams, currentQuery, source, targetApi, trackTotalHits]
    ),
    {
      enabled:
        options.enabled ||
        (options.enabledPlaceholder && placeholderDataFetched),
      cacheTime: options.cacheTime,
      staleTime: options.staleTime,
      retry: retryCustomFunction,
      onSuccess: useCallback(
        (data) => {
          options.onSuccess?.(data);
          if (
            data?.pages[0] &&
            size(data?.pages) === 1 &&
            isEqual(currentFilters, initialFilters) &&
            isEqual(currentSort, initialSort) &&
            currentLimit === initialLimit
          ) {
            return LocalForage.setItem<
              CacheInfiniteIndexQueryResponse<NodeType, MetaType>
            >(localForageCacheKey, data.pages[0]);
          }
        },
        [
          currentFilters,
          currentSort,
          currentLimit,
          initialFilters,
          initialSort,
          initialLimit,
          localForageCacheKey,
          options
        ]
      ),
      placeholderData: useCallback(() => {
        if (
          placeholderData?.pages?.[0] &&
          isEqual(currentFilters, initialFilters) &&
          isEqual(currentSort, initialSort) &&
          currentLimit === initialLimit
        ) {
          return placeholderData as CacheInfiniteIndexQueryData<
            NodeType,
            MetaType
          >;
        }
      }, [
        currentFilters,
        currentSort,
        currentLimit,
        initialFilters,
        initialSort,
        initialLimit,
        placeholderData
      ]),
      getNextPageParam: useCallback(
        (lastPage) => {
          return lastPage?.[scope]?.paginationInfo?.nextPage ?? undefined;
        },
        [scope]
      )
    }
  );

  const loadMoreItems = useCallback(() => fetchNextPage(), [fetchNextPage]);

  const lastQueryResponseValue = last(data?.pages)?.[scope];
  const placeholderResponseValue = placeholderData?.pages?.[0]?.[scope];

  const addItemCache = useAddInfiniteIndexQueryItemCache<NodeType>({
    fullCacheKey,
    scope
  });

  const updateItemCache = useUpdateInfiniteIndexQueryItemCache<NodeType>({
    fullCacheKey,
    scope
  });

  const items = useMemo<NodeType[]>(() => {
    const pagesNodes = data?.pages?.map((page) => page?.[scope]?.nodes);
    return pagesNodes ? flatten(pagesNodes) : [];
  }, [data, scope]);

  const isLoadingTotalCount = isLoading
    ? placeholderResponseValue?.paginationInfo?.totalCount
    : null;

  const queryClient = useQueryClient();

  const handlePrefetchCacheItem = useCallback(
    (itemUuid: UUID) =>
      queryClient.prefetchQuery(
        [fetchItemCacheKey, itemUuid],
        () =>
          handleFetchCacheItem({
            query: currentFetchItemQuery,
            source: fetchCacheItemSource,
            targetApi,
            uuid: itemUuid
          }),
        { staleTime: 5000 }
      ),
    [
      queryClient,
      fetchItemCacheKey,
      currentFetchItemQuery,
      fetchCacheItemSource,
      targetApi
    ]
  );

  return {
    data,
    items,
    itemsError: parseRequestError(error),
    itemsTotalCount:
      lastQueryResponseValue?.paginationInfo?.totalCount ||
      isLoadingTotalCount ||
      0,
    meta: lastQueryResponseValue?.meta || <MetaType>{},
    isFetched,
    isLoading,
    isFetchingNextPage,
    isPlaceholderData,
    currentFilters,
    currentSort,
    currentPage: lastQueryResponseValue?.paginationInfo?.currentPage,
    currentLimit,
    hasNextPage,
    addItemCache,
    updateItemCache,
    loadMoreItems,
    filterItems: useCallback(
      (nextFilters: FetchItemsFilters) =>
        dispatch(filterItemsAction(nextFilters)),
      [dispatch]
    ),
    changeItemsFilters: useCallback(
      (
        changedFilters: Partial<FetchItemsFilters>,
        removeFilters: string[] = []
      ) => dispatch(changeItemsFiltersAction(changedFilters, removeFilters)),
      [dispatch]
    ),
    clearItemsFilters: useCallback(
      () => dispatch(clearItemsFiltersAction()),
      [dispatch]
    ),
    clearItemsFiltersPersistInitial: useCallback(
      () => dispatch(filterItemsAction(initialFilters)),
      [dispatch, initialFilters]
    ),
    sortItems: useCallback(
      (nextSort: FetchItemsSort) => dispatch(sortItemsAction(nextSort)),
      [dispatch]
    ),
    limitItems: useCallback(
      (nextLimit: FetchItemsLimit) => dispatch(limitItemsAction(nextLimit)),
      [dispatch]
    ),
    prefetchItem: handlePrefetchCacheItem
  };
}

export default useCacheInfiniteIndexQuery;
