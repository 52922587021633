import * as yup from 'yup';

import {
  TaskApproveResultsFields,
  TaskNanoID,
  TaskUUID
} from '../../../../../tasksTypes';
import { ApproveResultsInTaskFormData } from '../../ApproveResultsInTaskForm.types';
import { ReactHookFormMode } from '../../../../../../common/hooks/base/useReactHookForm/useReactHookForm.types';

import {
  APPROVE_RESULTS_IN_TASK_QUERY,
  ApproveResultsInTaskQueryResponse
} from '../../../../../queries/approveResultsInTask.query';

import { useReactHookForm } from '../../../../../../common/hooks/base/useReactHookForm';
import { useApproveResultsInTask } from '../../../../../hooks/useApproveResultsInTask';

import { TaskCache } from '../../../../../TaskCache';
import { SmartContractCache } from '../../../../../../smartContracts/SmartContractCache';

import { formsErrors } from '../../../../../../../locales/keys';

const approveResultsInTaskFormData: ApproveResultsInTaskFormData = {
  supportMark: 0,
  final: true
};

interface ApproveResultsInTaskFormOptions {
  taskUuid: TaskUUID;
  taskNanoId: TaskNanoID;
  withoutClose?: boolean;
}

const acceptTaskValidationSchema = yup.object({
  [TaskApproveResultsFields.SUPPORT_MARK]: yup
    .number()
    .min(1, formsErrors.required)
    .nullable(true)
    .required(formsErrors.required)
});

function useApproveResultsInTaskForm({
  taskUuid,
  taskNanoId,
  withoutClose
}: ApproveResultsInTaskFormOptions) {
  const {
    handleSubmitReactHookForm,
    register,
    errors,
    control,
    isValid,
    resetForm
  } = useReactHookForm<ApproveResultsInTaskFormData>({
    defaultValues: approveResultsInTaskFormData,
    schema: acceptTaskValidationSchema,
    mode: ReactHookFormMode.ON_CHANGE
  });

  const {
    approveResultsInTask,
    approveResultsInTaskLoading,
    approveResultsInTaskErrorMessage,
    approveResultsInTaskReset
  } = useApproveResultsInTask<ApproveResultsInTaskQueryResponse>({
    query: APPROVE_RESULTS_IN_TASK_QUERY,
    cacheKeys: [
      SmartContractCache.selectByTaskFieldCacheKey(taskNanoId),
      SmartContractCache.showCacheKey(),
      TaskCache.indexCacheKey(),
      TaskCache.showQueryKey(taskNanoId),
      TaskCache.showSidebarCacheKey(),
      TaskCache.showDashboardCacheKey(),
      TaskCache.showHeaderActionsCacheKey(),
      TaskCache.messagesCacheKey(taskNanoId),
      TaskCache.itemsResultsCacheKey(),
      TaskCache.withoutSourceFiles(),
      TaskCache.dashboardWithoutSourceFiles(),
      TaskCache.userResultsCacheKey(),
      TaskCache.userActiveTasksCacheKey(),
      TaskCache.activeTaskCacheKey(taskNanoId)
    ]
  });

  return {
    control,
    approveResultsInTask,
    approveResultsInTaskLoading,
    approveResultsInTaskErrorMessage,
    approveResultsInTaskReset,
    isValid,
    resetForm,
    handleApproveResultsInTask: handleSubmitReactHookForm({
      onSubmit: async (data: ApproveResultsInTaskFormData) =>
        approveResultsInTask({
          withoutClose,
          uuid: taskUuid,
          ...data
        }),
      dirtyFieldsOnly: false
    }),
    registerSupportMark: register(TaskApproveResultsFields.SUPPORT_MARK),
    validationErrors: {
      supportMarkValidationError: errors?.supportMark?.message
    }
  };
}

export default useApproveResultsInTaskForm;
