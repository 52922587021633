import { IconsEnum } from '../../assets/icons/types';
import {
  FetchCategoriesSort,
  FetchCategoriesSortTypes
} from '../categories/categoriesTypes';
import {
  FetchLifestylesSortTypes,
  FetchLifestylesSort,
  FetchLifestylesFilters
} from './lifestylesTypes';

export const INITIAL_LIFESTYLES_FILTERS: FetchLifestylesFilters = {};
export const INITIAL_LIFESTYLES_SORT: FetchLifestylesSort = [
  FetchLifestylesSortTypes.CREATED_AT_DESC
];
export const INITIAL_LIFESTYLES_LIMIT = 72;

export const INITIAL_LIFESTYLE_STYLES_FILTERS = {};
export const INITIAL_LIFESTYLE_STYLES_SORT: FetchCategoriesSort = [
  FetchCategoriesSortTypes.NAME_ASC
];
export const INITIAL_LIFESTYLE_STYLES_LIMIT = 1000;

export const INITIAL_LIFESTYLE_GAMMAS_FILTERS = {};
export const INITIAL_LIFESTYLE_GAMMAS_SORT: FetchCategoriesSort = [
  FetchCategoriesSortTypes.NAME_ASC
];
export const INITIAL_LIFESTYLE_GAMMAS_LIMIT = 1000;

export const INITIAL_LIFESTYLE_TONES_FILTERS = {};
export const INITIAL_LIFESTYLE_TONES_SORT: FetchCategoriesSort = [
  FetchCategoriesSortTypes.NAME_ASC
];
export const INITIAL_LIFESTYLE_TONES_LIMIT = 1000;

export const INITIAL_LIFESTYLE_MATERIALS_FILTERS = {};
export const INITIAL_LIFESTYLE_MATERIALS_SORT: FetchCategoriesSort = [
  FetchCategoriesSortTypes.NAME_ASC
];
export const INITIAL_LIFESTYLE_MATERIALS_LIMIT = 1000;

export const INITIAL_LIFESTYLE_COLORS_FILTERS = {};
export const INITIAL_LIFESTYLE_COLORS_SORT: FetchCategoriesSort = [
  FetchCategoriesSortTypes.NAME_ASC
];
export const INITIAL_LIFESTYLE_COLORS_LIMIT = 1000;

export const INITIAL_LIFESTYLE_AUTHORS_FILTERS = {};
export const INITIAL_LIFESTYLE_AUTHORS_SORT: FetchCategoriesSort = [
  FetchCategoriesSortTypes.NAME_ASC
];
export const INITIAL_LIFESTYLE_AUTHORS_LIMIT = 200;

export const INITIAL_LIFESTYLE_AUTHORS_SELECTED_FILTERS = {};
export const INITIAL_LIFESTYLE_AUTHORS_SELECTED_SORT: FetchCategoriesSort = [
  FetchCategoriesSortTypes.NAME_ASC
];
export const INITIAL_LIFESTYLE_AUTHORS_SELECTED_LIMIT = 1000;
export const INITIAL_LIFESTYLE_AUTHORS_SELECTED_PAGE = 1;

export const INITIAL_LIFESTYLE_TYPES_FILTERS = {};
export const INITIAL_LIFESTYLE_TYPES_SORT: FetchCategoriesSort = [
  FetchCategoriesSortTypes.NAME_ASC
];
export const INITIAL_LIFESTYLE_TYPES_LIMIT = 1000;

export const enum LifestyleRenderTypes {
  CORONA = 'Corona',
  V_RAY = 'V-Ray'
}

export const enum LifestylesLicenses {
  NULL = 'null',
  FREE = 'free',
  PREMIUM = 'premium'
}

export const LifestyleRenderTypeIcons = {
  [LifestyleRenderTypes.CORONA]: IconsEnum.CORONA_SOLID,
  [LifestyleRenderTypes.V_RAY]: IconsEnum.VRAY_SOLID
};

export enum LifestylesPermissions {
  READ_CLEAR_LIFESTYLE_CACHE_BUTTON = 'read_clear_lifestyle_cache_button',
  READ_CLEAR_LIFESTYLES_CACHE_BUTTON = 'read_clear_lifestyles_cache_button',
  READ_LIFESTYLE_SHOW_MNESIA_QUERY = 'read_lifestyle_show_mnesia_query',
  READ_LIFESTYLE_SHOW_ES_QUERY = 'read_lifestyle_show_es_query',
  READ_LIFESTYLES_INDEX_ES_QUERY = 'read_lifestyles_index_es_query',
  READ_SELF_LIFESTYLES_LIBRARY_INDEX_PAGE = 'read_self_lifestyles_library_index_page',
  READ_USER_LIFESTYLES_LIBRARY_INDEX_PAGE = 'read_user_lifestyles_library_index_page',
  READ_LIFESTYLE_EDIT_PAGE = 'read_lifestyle_edit_page',
  READ_LIFESTYLE_PREVIEW_LINK = 'read_lifestyle_preview_link',
  READ_STOCK_MENU_LIFESTYLES_LINK = 'read_stock_menu_lifestyles_link',
  READ_LIFESTYLES_INDEX_PAGE = 'read_lifestyles_index_page',
  READ_LIFESTYLES_PREMIUM_PAGE = 'read_lifestyles_premium_page',
  READ_LIFESTYLES_FREE_PAGE = 'read_lifestyles_free_page',
  READ_LIFESTYLE_SHOW_PAGE = 'read_lifestyle_show_page',
  READ_LIFESTYLE_CREATE_PAGE = 'read_lifestyle_create_page',
  READ_LIFESTYLE_FAVORITE_BUTTON = 'read_lifestyle_favorite_button',
  READ_LIFESTYLE_DOWNLOAD_BUTTON = 'read_lifestyle_download_button',
  READ_SELF_COMPANY_LIBRARY_LIFESTYLE_DOWNLOAD_BUTTON = 'read_self_company_library_lifestyle_download_button',
  READ_OTHER_COMPANY_LIBRARY_LIFESTYLE_DOWNLOAD_BUTTON = 'read_other_company_library_lifestyle_download_button',
  READ_LIFESTYLE_COPY_LINK_BUTTON = 'read_lifestyle_copy_link_button',
  READ_LIFESTYLE_ATTACH_BUTTON = 'read_lifestyle_attach_button',
  READ_LIFESTYLE_SUBMIT_TO_TASK_OR_PROJECT_BUTTON = 'read_lifestyle_submit_to_task_or_project_button',
  READ_LIFESTYLE_UPDATE_IMAGE_VERSION_BUTTON = 'read_lifestyle_update_image_version_button',
  READ_LIFESTYLE_EDIT_BUTTON = 'read_lifestyle_edit_button',
  READ_LIFESTYLE_BLOCK_BUTTON = 'read_lifestyle_block_button',
  READ_LIFESTYLES_AUTHORS_FILTER = 'read_lifestyles_authors_filter',
  READ_LIFESTYLES_COLORS_FILTER = 'read_lifestyles_colors_filter',
  READ_LIFESTYLES_MATERIALS_FILTER = 'read_lifestyles_materials_filter',
  READ_LIFESTYLES_LIGHT_BOX_TITLE_AUTHOR = 'read_lifestyles_light_box_title_author',
  READ_NEW_LIFESTYLE_BUTTON = 'read_new_lifestyle_button',
  READ_LIFESTYLES_BLOCKED_FILTER = 'read_lifestyles_blocked_filter',
  READ_LIFESTYLES_CLIENTS_FILTER = 'read_lifestyles_clients_filter',
  READ_LIFESTYLES_TYPE_FILTER = 'read_lifestyles_type_filter',
  READ_LIFESTYLE_DELETE_BUTTON = 'read_lifestyle_delete_button',
  READ_LIFESTYLE_RENDER_TYPES = 'read_lifestyle_render_types',
  READ_LIFESTYLES_NDA_FILTER = 'read_lifestyles_nda_filter',
  READ_LIFESTYLES_MY_LIBRARY_FILTER = 'read_lifestyles_my_library_filter',
  READ_LIFESTYLES_FAVORITE_CHECKBOX_FILTER = 'read_lifestyles_favorite_checkbox_filter',
  READ_LIFESTYLES_FAVORITE_BUTTON_FILTER = 'read_lifestyles_favorite_button_filter',
  READ_LIFESTYLES_PREMIUM_CONTENT_AT_FILTER = 'read_lifestyles_premium_content_at_filter',
  READ_LIFESTYLES_LIBRARY_PREMIUM_CONTENT_AT_FILTER = 'read_lifestyles_library_premium_content_at_filter',
  READ_LIFESTYLES_COMPANY_LIBRARY_PREMIUM_CONTENT_AT_FILTER = 'read_lifestyles_company_library_premium_content_at_filter',
  READ_SELECT_LIFESTYLES_MODAL_BUTTON_PREMIUM_CONTENT_AT_FILTER = 'read_select_lifestyles_modal_button_premium_content_at_filter',
  READ_LIFESTYLES_TOTAL_COUNT = 'read_lifestyles_total_count',
  READ_LIFESTYLES_LOAD_MORE_BUTTON_ITEMS_COUNT = 'read_lifestyles_load_more_button_items_count',
  CHANGE_LIFESTYLE_NDA = 'change_lifestyle_nda',
  CHANGE_LIFESTYLE_PREMIUM_CONTENT_AT = 'change_lifestyle_premium_content_at',
  CHANGE_LIFESTYLE_CLIENT_IDS = 'change_lifestyle_client_ids',
  CHANGE_LIFESTYLE_ASSOCIATED_ASSETS = 'change_lifestyle_associated_assets',
  CHANGE_LIFESTYLE_ASSOCIATED_SCENES = 'change_lifestyle_associated_scenes',
  CHANGE_LIFESTYLE_NAME = 'change_lifestyle_name',
  CHANGE_LIFESTYLE_AUTHOR_ID = 'change_lifestyle_author_id',
  CHANGE_LIFESTYLE_PROJECT_NAME_ID = 'change_lifestyle_project_name_id',
  CHANGE_LIFESTYLE_CATEGORY_ID = 'change_lifestyle_category_id',
  CHANGE_LIFESTYLE_STYLE_IDS = 'change_lifestyle_style_ids',
  CHANGE_LIFESTYLE_TONE_ID = 'change_lifestyle_tone_id',
  CHANGE_LIFESTYLE_GAMMA_ID = 'change_lifestyle_gamma_id',
  CHANGE_LIFESTYLE_MAIN_COLOR_IDS = 'change_lifestyle_main_color_ids',
  CHANGE_LIFESTYLE_ACCENT_COLOR_IDS = 'change_lifestyle_accent_color_ids',
  CHANGE_LIFESTYLE_MATERIAL_IDS = 'change_lifestyle_material_ids',
  CHANGE_LIFESTYLE_TAG_IDS = 'change_lifestyle_tag_ids',
  CHANGE_LIFESTYLE_MAX_FILE_IDS = 'change_lifestyle_max_file_ids',
  CHANGE_LIFESTYLE_IMAGE_IDS = 'change_lifestyle_image_ids',
  CHANGE_LIFESTYLE_DEFAULT_IMAGE = 'change_lifestyle_default_image',
  CHANGE_LIFESTYLE_RENDER_TYPE_IDS = 'change_lifestyle_render_type_ids',
  CHANGE_LIFESTYLE_PRICE = 'change_lifestyle_price',
  CHANGE_LIFESTYLE_TRACKED_HOURS = 'change_lifestyle_tracked_hours',
  CHANGE_LIFESTYLE_LIFESTYLE_TYPE_ID = 'change_lifestyle_lifestyle_type_id',
  CHANGE_LIFESTYLE_CUSTOM_TAG_IDS = 'change_lifestyle_custom_tag_ids',
  READ_LIFESTYLE_CUSTOM_TAGS = 'read_lifestyle_custom_tags',
  READ_LIFESTYLE_PREMIUM_CONTENT_AT = 'read_lifestyle_premium_content_at',
  READ_LIFESTYLE_AUTHOR = 'read_lifestyle_author',
  READ_LIFESTYLE_CLIENTS = 'read_lifestyle_clients',
  READ_LIFESTYLE_PROJECT_NAME = 'read_lifestyle_project_name',
  READ_LIFESTYLE_CATEGORY = 'read_lifestyle_category',
  READ_LIFESTYLE_STYLES = 'read_lifestyle_styles',
  READ_LIFESTYLE_TONE = 'read_lifestyle_tone',
  READ_LIFESTYLE_GAMMA = 'read_lifestyle_gamma',
  READ_LIFESTYLE_MAIN_COLORS = 'read_lifestyle_main_colors',
  READ_LIFESTYLE_ACCENT_COLORS = 'read_lifestyle_accent_colors',
  READ_LIFESTYLE_MATERIALS = 'read_lifestyle_materials',
  READ_LIFESTYLE_TAGS = 'read_lifestyle_tags',
  READ_LIFESTYLE_ASSOCIATED_ASSETS = 'read_lifestyle_associated_assets',
  READ_LIFESTYLE_ASSOCIATED_SCENES = 'read_lifestyle_associated_scenes',
  READ_LIFESTYLE_LIFESTYLE_TYPE = 'read_lifestyle_lifestyle_type',
  READ_LIFESTYLE_UPLOADED = 'read_lifestyle_uploaded'
}

export enum LifestylesCategories {
  LIVING_APARTMENTS = '1980',
  BEDROOM = '1981',
  DINING = '1979',
  COMMERCIAL_AREAS = '676',
  EXTERIORS = '6033',
  SANITARY_FACILITIES = '1982',
  TRANSIT_AREAS = '1975',
  UTILITY_ROOM = '1978'
}
