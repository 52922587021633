import { graphQLCacheClient } from '../../graphQLClients';

import {
  FetchCacheItemsSources,
  FetchCacheItemsTrackTotalHits,
  FetchItemsFilters,
  FetchItemsGqlQuery,
  FetchItemsLimit,
  FetchItemsPage,
  FetchItemsSort
} from '../../../../../../../types';

import { generateUuid } from '../../../../../../../utils/generateUuid';
import { getQueryName } from '../../../../../../../utils/getQueryName';
import { productionConsole } from '../../../../../../../utils/productionConsole';

interface FetchCacheItemsProps {
  filters: FetchItemsFilters;
  limit: FetchItemsLimit;
  page: FetchItemsPage;
  query: FetchItemsGqlQuery;
  sort: FetchItemsSort;
  source?: FetchCacheItemsSources;
  trackTotalHits?: FetchCacheItemsTrackTotalHits;
}

export function fetchCacheItems({
  filters,
  limit,
  page,
  query,
  sort,
  source = FetchCacheItemsSources.ES,
  trackTotalHits = false,
  ...additionalParams
}: FetchCacheItemsProps) {
  const { rawName, name } = getQueryName(query);

  productionConsole(
    'log',
    'fetchCacheItems',
    { filters, sort, page, limit, source, trackTotalHits, ...additionalParams },
    rawName,
    JSON.stringify(filters)
  );

  const requestId = generateUuid();

  graphQLCacheClient.addQueryParams(name, requestId);

  return graphQLCacheClient.request(
    query,
    {
      filters,
      sort,
      limit,
      offset: (page - 1) * limit,
      source,
      trackTotalHits,
      ...additionalParams
    },
    { requestId }
  );
}
