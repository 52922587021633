import { gql } from 'graphql-request';
import {
  ItemCategoryCreatedAt,
  ItemCategoryID,
  ItemCategoryName,
  ItemCategoryNanoID,
  ItemCategoryUUID,
  ItemCategoryUpdatedAt
} from '../itemCategoriesTypes';
import {
  UserID,
  UserImageFile,
  UserName,
  UserNanoID,
  UserUUID
} from '../../users/usersTypes';

export interface FetchFinItemCategoriesItemCategoryType {
  id: ItemCategoryID;
  uuid: ItemCategoryUUID;
  nanoId: ItemCategoryNanoID;
  createdAt: ItemCategoryCreatedAt;
  updatedAt: ItemCategoryUpdatedAt;
  name: ItemCategoryName;

  user: {
    id: UserID;
    uuid: UserUUID;
    nanoId: UserNanoID;
    name: UserName;
    image: {
      file: UserImageFile;
    } | null;
  };
}

export const FETCH_ITEM_CATEGORIES_QUERY = gql`
  query ItemsCategories(
    $filters: ItemCategoriesFilters
    $limit: Int
    $offset: Int
    $sort: [ItemCategoriesSortEnum!]
  ) {
    itemCategories(
      filters: $filters
      sort: $sort
      limit: $limit
      offset: $offset
    ) {
      nodes {
        id
        uuid
        nanoId
        createdAt
        updatedAt
        name
        user {
          id
          uuid
          nanoId
          name
          image {
            file
          }
        }
      }
      paginationInfo {
        nextPage
        totalCount
      }
      sql
    }
  }
`;
