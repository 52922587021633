import { gql } from 'graphql-request';

import {
  CategoryID,
  CategoryUUID,
  CategoryNameEn,
  CategoryLocalizedName
} from '../../categories/categoriesTypes';

export interface CacheFetchLifestyleColorsQueryResponse {
  uuid: CategoryUUID;
  id: CategoryID;
  nameEn: CategoryNameEn;
  localizedName: CategoryLocalizedName;
}

export const CACHE_FETCH_LIFESTYLE_COLORS_QUERY = gql`
  query CacheLifestyleColorsCategories(
    $filters: CategoriesFilters
    $sort: [CategoriesSortEnum!]
    $limit: Int
    $offset: Int
    $source: String
    $trackTotalHits: Boolean
  ) {
    categories(
      filters: $filters
      sort: $sort
      limit: $limit
      offset: $offset
      source: $source
      trackTotalHits: $trackTotalHits
    ) {
      nodes {
        uuid
        id
        nameEn
        localizedName
      }
      sql
    }
  }
`;
