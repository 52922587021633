import React, { useCallback, useRef, useState } from 'react';
import isNaN from 'lodash/isNaN';

import { useCurrentUser } from '../../../auth/hooks/useAuth';

import { SelectLifestylesModalButtonRequiredProps } from './SelectLifestylesModalButton.types';
import { ErrorMessage, I18nText } from '../../../types';
import { FetchLifestylesSetByUserIdScopeType } from '../../../main/lifestylesSets/lifestylesSetsTypes';

import {
  SelectLifestylesContent,
  SelectLifestylesContentOnLifestylesSelect
} from '../../../main/lifestyles/components/contents/SelectLifestylesContent';

import { SimpleModalButton } from '../../buttons/SimpleModalButton';

import { LifestylesPermissions } from '../../../main/lifestyles/lifestylesConstants';

import { TEMP_SELECTED_LIFESTYLE_LAST_ID } from '../../../main/lifestylesSets/lifestylesSetsConstants';

interface SelectLifestylesModalButtonBaseProps {
  value: string[];
  disabled?: boolean;
  submitErrorMessage?: ErrorMessage;
  resetOnClose?: boolean;
  lifestylesSetScope?: FetchLifestylesSetByUserIdScopeType;
}

interface SelectLifestylesModalButtonWithTextProps {
  buttonText: string;
  i18nText?: never;
}

interface SelectLifestylesModalButtonWithI18nTextProps {
  buttonText?: never;
  i18nText: I18nText;
}

interface SelectLifestylesModalButtonWithOnSubmitProps {
  onSubmit: (value: string[]) => Promise<unknown>;
  onChange?: never;
}

interface SelectLifestylesModalButtonWithOnChangeProps {
  onSubmit?: never;
  onChange: (value: string[]) => void;
}

type SelectLifestylesModalButtonProps = SelectLifestylesModalButtonBaseProps &
  (
    | SelectLifestylesModalButtonWithTextProps
    | SelectLifestylesModalButtonWithI18nTextProps
  ) &
  (
    | SelectLifestylesModalButtonWithOnChangeProps
    | SelectLifestylesModalButtonWithOnSubmitProps
  );

function SelectLifestylesModalButton({
  value,
  disabled,
  onChange,
  onSubmit,
  className,
  i18nTitle,
  icon,
  iconClassName,
  modalIcon,
  i18nSubmitText,
  tooltipPlacement,
  tooltipI18nText,
  i18nText,
  buttonText,
  resetOnClose,
  withoutInitialFocus,
  lifestylesSetScope
}: SelectLifestylesModalButtonProps &
  SelectLifestylesModalButtonRequiredProps) {
  const currentUser = useCurrentUser();

  const [selectedLifestyleIdsBeforeOpen, setSelectedLifestyleIdsBeforeOpen] =
    useState<string[]>(value);
  const [selectedLifestyleIds, setSelectedLifestyleIds] =
    useState<string[]>(value);
  const [imageLightboxOpen, setImageLightboxOpen] = useState<boolean>(false);
  const fetchedSelectedLifestyleIds = useRef<boolean>(true);
  const loadingSelectedLifestyleIds = useRef<boolean>(false);

  const handleLifestylesSelect =
    useCallback<SelectLifestylesContentOnLifestylesSelect>(
      (selectedLifestyles) => {
        fetchedSelectedLifestyleIds.current = selectedLifestyles.every(
          (selectedLifestyle) =>
            isNaN(+selectedLifestyle.id) ||
            +selectedLifestyle.id < TEMP_SELECTED_LIFESTYLE_LAST_ID
        );

        setSelectedLifestyleIds(
          selectedLifestyles.map(
            (selectedLifestyle) => selectedLifestyle.id as string
          )
        );
      },
      [setSelectedLifestyleIds]
    );

  const handleOpen = useCallback<() => void>(() => {
    fetchedSelectedLifestyleIds.current = true;
    loadingSelectedLifestyleIds.current = false;
    setSelectedLifestyleIdsBeforeOpen(selectedLifestyleIds);
  }, [selectedLifestyleIds, setSelectedLifestyleIdsBeforeOpen]);

  const handleCancel = useCallback<() => void>(
    () => setSelectedLifestyleIds(selectedLifestyleIdsBeforeOpen),
    [selectedLifestyleIdsBeforeOpen, setSelectedLifestyleIds]
  );

  const handleAfterSubmit = useCallback<() => void>(() => {
    if (resetOnClose) {
      setSelectedLifestyleIdsBeforeOpen([]);
    } else {
      setSelectedLifestyleIdsBeforeOpen(selectedLifestyleIds);
    }
  }, [resetOnClose, selectedLifestyleIds]);

  const handleSubmit = useCallback<() => Promise<void>>(async () => {
    if (onChange) {
      onChange(selectedLifestyleIds);
      handleAfterSubmit();
    } else {
      return onSubmit?.(selectedLifestyleIds).then(() => handleAfterSubmit());
    }
  }, [onChange, selectedLifestyleIds, handleAfterSubmit, onSubmit]);

  const checkAndSubmit = useCallback<() => Promise<void>>(async () => {
    while (!fetchedSelectedLifestyleIds.current) {
      loadingSelectedLifestyleIds.current = true;
      await new Promise((resolve) => setTimeout(resolve, 100));
    }

    loadingSelectedLifestyleIds.current = false;

    return handleSubmit();
  }, [fetchedSelectedLifestyleIds, handleSubmit]);

  const togglePreventModalClose = useCallback<() => void>(
    () => setImageLightboxOpen((prevState) => !prevState),
    [setImageLightboxOpen]
  );

  return (
    <SimpleModalButton
      className={className}
      childrenClassName="flex-1 flex overflow-hidden"
      i18nTitle={i18nTitle}
      icon={icon}
      iconClassName={iconClassName}
      modalIcon={modalIcon}
      modalSize="full"
      {...(i18nText ? { i18nText } : { buttonText })}
      i18nSubmitText={i18nSubmitText}
      disabled={disabled}
      isLoading={disabled || loadingSelectedLifestyleIds.current}
      tooltipPlacement={tooltipPlacement}
      tooltipI18nText={tooltipI18nText}
      preventModalClose={imageLightboxOpen}
      onOpen={handleOpen}
      onCancel={handleCancel}
      onSubmit={checkAndSubmit}
      withoutInitialFocus={withoutInitialFocus}
    >
      <SelectLifestylesContent
        initialLimit={48}
        withPremiumContentAtFilter={currentUser.hasPermissions(
          LifestylesPermissions.READ_SELECT_LIFESTYLES_MODAL_BUTTON_PREMIUM_CONTENT_AT_FILTER
        )}
        onLifestylesSelect={handleLifestylesSelect}
        togglePreventModalClose={togglePreventModalClose}
        lifestylesSetScope={lifestylesSetScope}
      />
    </SimpleModalButton>
  );
}

export default SelectLifestylesModalButton;
