import React, { useState } from 'react';

import { ProductsListItemUploadedProduct } from './ProductsListItemUploaded.types';

import { Tooltip } from '../../../../../../../helpers/tooltips/Tooltip';
import { TooltipPlacement } from '../../../../../../../helpers/tooltips/tooltipsConstants';
import { DateHelper } from '../../../../../../../helpers/DateHelper';
import { Translate } from '../../../../../../../helpers/Translate';

import { words } from '../../../../../../../locales/keys';

interface ProductsListItemUploadedProps {
  product: ProductsListItemUploadedProduct;
}

function ProductsListItemUploaded({ product }: ProductsListItemUploadedProps) {
  const [referenceTooltipElement, setReferenceTooltipElement] =
    useState<HTMLSpanElement | null>(null);

  if (!product.maxFile?.createdAt) {
    return null;
  }

  return (
    <div
      className="text-gray-600 dark:text-gray-500 text-2xs truncate"
      ref={setReferenceTooltipElement}
    >
      <span>
        <Translate id={words.uploaded} />
        {': '}
        <DateHelper
          date={product.maxFile.createdAt}
          // customFormat={getCustomDateFormat(product.maxFile.createdAt)}
          customFormat="MMM dd, yyyy, h:mm a"
        />
      </span>

      <Tooltip
        withArrow
        placement={TooltipPlacement.TOP}
        referenceElement={referenceTooltipElement}
      >
        <DateHelper date={product.maxFile.createdAt} />
      </Tooltip>
    </div>
  );
}

export default ProductsListItemUploaded;
