import {
  FetchCacheItemsSources,
  FetchCacheItemsTrackTotalHits,
  FetchItemCacheKey,
  FetchItemsGqlQuery
} from '../../../../types';
import {
  FetchCategoriesCacheKey,
  FetchCategoriesGqlQuery,
  FetchCategoriesLimit,
  FetchCategoriesPage,
  FetchCategoriesSort,
  FetchFinCategoriesFilters
} from '../../categoriesTypes';

import { useCacheIndexQuery } from '../../../common/hooks/base/reactQuery/useCacheIndexQuery';

import {
  CategoriesPermissions,
  INITIAL_CATEGORIES_FILTERS,
  INITIAL_CATEGORIES_LIMIT,
  INITIAL_CATEGORIES_PAGE,
  INITIAL_CATEGORIES_SORT
} from '../../categoriesConstants';

interface CategoriesDefaultOptions {
  cacheKey: FetchCategoriesCacheKey;
  cacheQuery?: FetchCategoriesGqlQuery;
  initialFilters?: FetchFinCategoriesFilters;
  initialLimit?: FetchCategoriesLimit;
  initialPage?: FetchCategoriesPage;
  initialSort?: FetchCategoriesSort;
  query: FetchCategoriesGqlQuery;
  source?: FetchCacheItemsSources;
  trackTotalHits?: FetchCacheItemsTrackTotalHits;
  options?: {
    cacheTime?: number;
    withoutPrefetch?: boolean;
    enabled?: boolean;
    enabledPlaceholder?: boolean;
    keepPreviousData?: boolean;
    staleTime?: number;
  };
  action?: CategoriesPermissions;
}

interface CategoriesWithPrefetchItemOptions {
  fetchItemCacheKey: FetchItemCacheKey;
  fetchItemQuery: FetchItemsGqlQuery;
}

interface CategoriesWithoutPrefetchItemOptions {
  fetchItemCacheKey?: never;
  fetchItemQuery?: never;
}

type CategoriesOptions = CategoriesDefaultOptions &
  (CategoriesWithPrefetchItemOptions | CategoriesWithoutPrefetchItemOptions);

const scope = 'categories';

function useFinPaginatedCategories<ItemType>({
  cacheKey,
  fetchItemCacheKey,
  fetchItemQuery,
  initialFilters = INITIAL_CATEGORIES_FILTERS,
  initialLimit = INITIAL_CATEGORIES_LIMIT,
  initialPage = INITIAL_CATEGORIES_PAGE,
  initialSort = INITIAL_CATEGORIES_SORT,
  options = {},
  query,
  cacheQuery,
  source = FetchCacheItemsSources.ES,
  trackTotalHits = false,
  action = CategoriesPermissions.READ_CATEGORIES_INDEX_ES_QUERY
}: CategoriesOptions) {
  const {
    data,
    items,
    itemsError,
    itemsTotalCount,
    isFetched,
    isLoading,
    isPlaceholderData,
    currentPage,
    currentLimit,
    currentFilters,
    currentSort,
    clearItemsFilters,
    filterItems,
    changeItemsFilters,
    prefetchItems,
    sortItems,
    paginateItems,
    limitItems,
    prefetchItem
  } = useCacheIndexQuery<ItemType>({
    action,
    cacheKey,
    cacheQuery,
    defaultApi: 'fin_api',
    fetchItemCacheKey,
    fetchItemQuery,
    initialFilters,
    initialLimit,
    initialPage,
    initialSort,
    options,
    query,
    scope,
    source,
    trackTotalHits
  });

  return {
    categoriesData: data,
    categories: items,
    categoriesError: itemsError,
    categoriesTotalCount: itemsTotalCount,
    categoriesFetched: isFetched,
    categoriesLoading: isLoading,
    categoriesIsPlaceholderData: isPlaceholderData,
    categoriesFilters: currentFilters,
    categoriesSort: currentSort,
    categoriesPage: currentPage,
    categoriesLimit: currentLimit,
    filterCategories: filterItems,
    changeCategoriesFilters: changeItemsFilters,
    clearCategoriesFilters: clearItemsFilters,
    sortCategories: sortItems,
    paginateCategories: paginateItems,
    limitCategories: limitItems,
    prefetchCategories: prefetchItems,
    prefetchCategory: prefetchItem
  };
}

export default useFinPaginatedCategories;
