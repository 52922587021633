import { gql } from 'graphql-request';

import {
  TeamsUpsalerFullName,
  TeamsUpsalerNanoID,
  TeamsUpsalerTeamID,
  TeamsUpsalerUpsalerCurrentTeamNanoID,
  TeamsUpsalerUpsalerID,
  TeamsUpsalerUUID
} from '../teamsUpsalerTypes';

export interface FetchSelectTeamsUpsalersQueryResponse {
  uuid: TeamsUpsalerUUID;
  upsalerId: TeamsUpsalerUpsalerID;
  teamId: TeamsUpsalerTeamID;
  upsaler: {
    nanoId: TeamsUpsalerNanoID;
    fullName: TeamsUpsalerFullName;
    currentTeam: {
      nanoId: TeamsUpsalerUpsalerCurrentTeamNanoID;
    };
  };
}

export const FETCH_SELECT_TEAMS_UPSALERS_QUERY = gql`
  query SelectedTeamsUpsalers(
    $filters: TeamsUpsalersFilters
    $sort: [TeamsUpsalersSortEnum!]
    $limit: Int
    $offset: Int
  ) {
    teamsUpsalers(
      filters: $filters
      sort: $sort
      limit: $limit
      offset: $offset
    ) {
      nodes {
        uuid
        upsalerId
        upsaler {
          nanoId
          fullName
          currentTeam {
            nanoId
          }
        }
        teamId
      }
      sql
    }
  }
`;
