import { gql } from 'graphql-request';

import {
  CompanySmartContractSettingCompanyID,
  CompanySmartContractSettingID,
  CompanySmartContractSettingSplitPartPercents,
  CompanySmartContractSettingUUID
} from '../companySmartContractSettingsTypes';

export interface FetchCompanySmartContractSettingsQueryResponse {
  companyId: CompanySmartContractSettingCompanyID;
  id: CompanySmartContractSettingID;
  uuid: CompanySmartContractSettingUUID;
  splitPartPercents: CompanySmartContractSettingSplitPartPercents;
}

export const FETCH_COMPANY_SMART_CONTRACT_SETTINGS_QUERY = gql`
  query CompanySmartContractSettings(
    $filters: CompanySmartContractSettingsFilters
    $limit: Int
    $offset: Int
    $sort: [CompanySmartContractSettingsSortEnum!]
  ) {
    companySmartContractSettings(
      filters: $filters
      limit: $limit
      offset: $offset
      sort: $sort
    ) {
      appVersion
      nodes {
        companyId
        id
        uuid
        splitPartPercents
      }
      paginationInfo {
        currentPage
        firstPage
        lastPage
        limitValue
        nextPage
        outOfRange
        prevPage
      }
      sql
    }
  }
`;
