import { gql } from 'graphql-request';

export const CACHE_GET_CATEGORIES_QUERY = gql`
  query CacheIndexCategories(
    $filters: CategoriesFilters
    $sort: [CategoriesSortEnum!]
    $limit: Int
    $offset: Int
    $source: String
    $trackTotalHits: Boolean
  ) {
    categories(
      filters: $filters
      sort: $sort
      limit: $limit
      offset: $offset
      source: $source
      trackTotalHits: $trackTotalHits
    ) {
      nodes {
        id
        uuid
        name
        nameEn
        nameUk
        order
        pdfExport
        categoriesCount
        blocked
        section {
          id
          uuid
          name
        }
        parent {
          id
          uuid
          localizedName
        }
        user {
          uuid
          fullName
          image {
            uuid
            file
          }
        }
        createdAt
        updatedAt
      }
      paginationInfo {
        nextPage
        totalCount
      }
      sql
    }
  }
`;
