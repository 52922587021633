import { gql } from 'graphql-request';

import {
  CategoryID,
  CategoryLocalizedName,
  CategoryNameEn,
  CategoryName,
  CategoryParentID
} from '../../categories/categoriesTypes';

export interface FetchCacheCategoriesSelectFieldQueryResponse {
  id: CategoryID;
  localizedName: CategoryLocalizedName;
  nameEn: CategoryNameEn;
  name: CategoryName;
  parentId: CategoryParentID;
  parent: {
    id: CategoryParentID;
  };
}

export const CACHE_FETCH_CATEGORIES_SELECT_FIELD_QUERY = gql`
  query CacheCategoriesSelectField(
    $filters: CategoriesFilters
    $sort: [CategoriesSortEnum!]
    $limit: Int
    $offset: Int
    $source: String
    $trackTotalHits: Boolean
  ) {
    categories(
      filters: $filters
      sort: $sort
      limit: $limit
      offset: $offset
      source: $source
      trackTotalHits: $trackTotalHits
    ) {
      nodes {
        id
        localizedName
        nameEn
        name
        parentId
        parent {
          id
        }
      }
      sql
    }
  }
`;
