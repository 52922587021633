import { IconsEnum } from '../../assets/icons/types';
import {
  OperationStates,
  OperationTypes,
  FetchOperationsSortTypes
} from './operationsTypes';

import { operationsKeys } from '../../locales/keys';

export const INITIAL_OPERATIONS_FILTERS = {};
export const INITIAL_OPERATIONS_SORT = [
  FetchOperationsSortTypes.CREATED_AT_DESC
];
export const INITIAL_OPERATIONS_LIMIT = 50;
export const INITIAL_OPERATIONS_PAGE = 1;

export const operationTypeIcon = {
  event: IconsEnum.CHAT,
  task: IconsEnum.MAIL,
  call: IconsEnum.PHONE_SOLID
};

export const operationsStates = [
  {
    value: OperationStates.CANCELED,
    i18nLabel: operationsKeys.statuses.canceled
  },
  {
    value: OperationStates.COMPLETED,
    i18nLabel: operationsKeys.statuses.completed
  },
  {
    value: OperationStates.DEFERRED,
    i18nLabel: operationsKeys.statuses.deferred
  },
  {
    value: OperationStates.IN_PROGRESS,
    i18nLabel: operationsKeys.statuses.in_progress
  },
  {
    value: OperationStates.NOT_STARTED,
    i18nLabel: operationsKeys.statuses.not_started
  },
  {
    value: OperationStates.PLANNED,
    i18nLabel: operationsKeys.statuses.planned
  },
  {
    value: OperationStates.WAITING_FOR_INPUT,
    i18nLabel: operationsKeys.statuses.waiting_for_input
  }
];

export const operationsTypes = [
  {
    value: OperationTypes.CALL,
    i18nLabel: operationsKeys.types.call,
    icon: IconsEnum.PHONE_SOLID
  },
  {
    value: OperationTypes.EVENT,
    i18nLabel: operationsKeys.types.event,
    icon: IconsEnum.CHAT
  },
  {
    value: OperationTypes.TASK,
    i18nLabel: operationsKeys.types.task,
    icon: IconsEnum.MAIL
  }
];

export const operationStatuses = {
  [OperationStates.CANCELED]: operationsKeys.statuses.canceled,
  [OperationStates.COMPLETED]: operationsKeys.statuses.completed,
  [OperationStates.DEFERRED]: operationsKeys.statuses.deferred,
  [OperationStates.IN_PROGRESS]: operationsKeys.statuses.in_progress,
  [OperationStates.NOT_STARTED]: operationsKeys.statuses.not_started,
  [OperationStates.PLANNED]: operationsKeys.statuses.planned,
  [OperationStates.WAITING_FOR_INPUT]: operationsKeys.statuses.waiting_for_input
};

export const LEAVE_OF_ABSENCE_SUBJECT = 'LOA';
export const T_A_T_SUBJECT = 'TAT';

export const MY_OPERATION_SUBJECT = 'Client allocation';
export const MY_OPERATION_BODY = '##MY';

export const LOA_START_HOURS = 10;
export const LOA_END_HOURS = 19;
