import { gql } from 'graphql-request';

import {
  SmartContractCreatedAt,
  SmartContractID,
  SmartContractNanoID,
  SmartContractRuleRuleType,
  SmartContractRuleID,
  SmartContractRuleName,
  SmartContractStatus,
  SmartContractUpdatedAt,
  SmartContractUUID,
  SmartContractOpenedAt,
  SmartContractDoneAt,
  SmartContractCanceledAt,
  SmartContractRuleCompetencyContractorName,
  SmartContractRuleCompetencyContractorSurName,
  SmartContractDueDate,
  SmartContractItemID,
  SmartContractItemPrice,
  SmartContractItemInvoiceID,
  SmartContractItemInvoiceStatus,
  SmartContractItemInvoiceInvoiceType,
  SmartContractItemInvoiceAmount,
  SmartContractRuleCompetencyContractorImageFile,
  SmartContractItemInvoiceCreatedAt,
  SmartContractItemUpdatedAt,
  SmartContractRulePresenceType,
  SmartContractRuleCompetencyContractorNanoID,
  SmartContractSmartContractType,
  SmartContractSmartContractShareID,
  SmartContractSmartContractShareGeneralLedgerID,
  SmartContractSmartContractShareGeneralLedgerCompanyID,
  SmartContractSmartContractShareGeneralLedgerCompanyNanoID,
  SmartContractSmartContractShareGeneralLedgerCompanyName,
  SmartContractSmartContractShareGeneralLedgerCompanyImageFile,
  SmartContractSmartContractShareGeneralLedgerCompanyOwnerID,
  SmartContractSmartContractShareGeneralLedgerCompanyOwnerNanoID,
  SmartContractSmartContractShareGeneralLedgerCompanyOwnerClient,
  SmartContractSmartContractShareGeneralLedgerCompanyOwnerFullName,
  SmartContractSmartContractShareGeneralLedgerCompanyOwnerImageFile,
  SmartContractSmartContractShareGeneralLedgerName,
  SmartContractSmartContractShareStatus,
  SmartContractItemViewSplitPartPercent,
  SmartContractItemViewSplitPartNumber
} from '../smartContractsTypes';

export interface FetchTaskSmartContractsQueryResponse {
  id: SmartContractID;
  uuid: SmartContractUUID;
  nanoId: SmartContractNanoID;
  createdAt: SmartContractCreatedAt;
  updatedAt: SmartContractUpdatedAt;
  openedAt: SmartContractOpenedAt;
  doneAt: SmartContractDoneAt;
  dueDate: SmartContractDueDate;
  canceledAt: SmartContractCanceledAt;
  status: SmartContractStatus;
  smartContractType: SmartContractSmartContractType;
  smartContractRules: {
    id: SmartContractRuleID;
    name: SmartContractRuleName;
    ruleType: SmartContractRuleRuleType;
    presenceType: SmartContractRulePresenceType;
    competencyContractor: {
      nanoId: SmartContractRuleCompetencyContractorNanoID;
      name: SmartContractRuleCompetencyContractorName;
      surname: SmartContractRuleCompetencyContractorSurName;
      image: {
        file: SmartContractRuleCompetencyContractorImageFile;
      };
    };
  }[];
  smartContractShares: {
    id: SmartContractSmartContractShareID;
    status: SmartContractSmartContractShareStatus;
    generalLedger: {
      id: SmartContractSmartContractShareGeneralLedgerID;
      name: SmartContractSmartContractShareGeneralLedgerName;
      company: {
        id: SmartContractSmartContractShareGeneralLedgerCompanyID;
        nanoId: SmartContractSmartContractShareGeneralLedgerCompanyNanoID;
        name: SmartContractSmartContractShareGeneralLedgerCompanyName;
        image: {
          file: SmartContractSmartContractShareGeneralLedgerCompanyImageFile;
        };
        owner: {
          id: SmartContractSmartContractShareGeneralLedgerCompanyOwnerID;
          nanoId: SmartContractSmartContractShareGeneralLedgerCompanyOwnerNanoID;
          client: SmartContractSmartContractShareGeneralLedgerCompanyOwnerClient;
          fullName: SmartContractSmartContractShareGeneralLedgerCompanyOwnerFullName;
          image: {
            file: SmartContractSmartContractShareGeneralLedgerCompanyOwnerImageFile;
          };
        };
      };
    };
  }[];
  items: {
    id: SmartContractItemID;
    price: SmartContractItemPrice;
    updatedAt: SmartContractItemUpdatedAt;
    invoices: {
      id: SmartContractItemInvoiceID;
      amount: SmartContractItemInvoiceAmount;
      status: SmartContractItemInvoiceStatus;
      createdAt: SmartContractItemInvoiceCreatedAt;
      invoiceType: SmartContractItemInvoiceInvoiceType;
    }[];
    viewSplitPartPercent: SmartContractItemViewSplitPartPercent;
    viewSplitPartNumber: SmartContractItemViewSplitPartNumber;
  }[];
}

export const FETCH_TASK_SMART_CONTRACTS_QUERY = gql`
  query TaskSmartContracts(
    $filters: SmartContractsFilters
    $sort: [SmartContractsSortEnum!]
    $limit: Int
    $offset: Int
  ) {
    smartContracts(
      filters: $filters
      sort: $sort
      limit: $limit
      offset: $offset
    ) {
      nodes {
        id
        uuid
        nanoId
        createdAt
        updatedAt
        openedAt
        doneAt
        dueDate
        canceledAt
        status
        smartContractType
        smartContractRules {
          id
          name
          ruleType
          presenceType
          competencyContractor {
            name
            surname
            image {
              file
            }
          }
        }
        smartContractShares {
          id
          status
          generalLedger {
            id
            name
            company {
              id
              nanoId
              name
              image {
                file
              }
              owner {
                id
                nanoId
                client
                fullName
                image {
                  file
                }
              }
            }
          }
        }
        items {
          id
          price
          updatedAt
          invoices {
            id
            createdAt
            amount
            status
            invoiceType
          }
          viewSplitPartPercent
          viewSplitPartNumber
        }
      }
      paginationInfo {
        nextPage
        totalCount
      }
      sql
    }
  }
`;
