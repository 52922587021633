import {
  CreatedAt,
  DateType,
  FetchItemCacheKey,
  FetchItemsFilterItems,
  FetchItemsGqlQuery,
  FetchItemsLimit,
  FetchItemsPage,
  FetchItemsSort,
  FetchItemsSortItems,
  ID,
  IdFilterType,
  UUID,
  UpdatedAt
} from '../../types';
import { UpdateItemsCacheKeys } from '../../types/updateItemsTypes';
import { ProjectNanoID } from '../projects/projectsTypes';

import { InvoiceID, InvoiceNanoID } from '../invoices/invoicesTypes';
import { UserID } from '../users/usersTypes';

export type InvoiceProcessingJobID = ID;
export type InvoiceProcessingJobUUID = UUID;
export type InvoiceProcessingJobCreatedAt = CreatedAt;
export type InvoiceProcessingJobUpdatedAt = UpdatedAt;
export type InvoiceProcessingJobStartedAt = DateType;
export type InvoiceProcessingJobCompletedAt = DateType;
export type InvoiceProcessingJobFailedAt = DateType;
export type InvoiceProcessingJobActionName = string;
export type InvoiceProcessingJobStatus = InvoiceProcessingJobStatuses;
export type InvoiceProcessingJobProgress = number;
export type InvoiceProcessingJobParams = Record<string, any>;

export type InvoiceProcessingJobUserID = UserID;
export type InvoiceProcessingJobInvoiceID = InvoiceID;

export type InvoiceProcessingJobErrorMessage = string;
export type InvoiceProcessingJobErrorBacktrace = string;

interface InvoiceProcessingJobStatusFilter {
  eq?: InvoiceProcessingJobStatuses;
  in?: InvoiceProcessingJobStatuses[];
  notEq?: InvoiceProcessingJobStatuses;
  notIn?: InvoiceProcessingJobStatuses[];
}

export interface FetchFinInvoiceProcessingJobsFilters {
  id?: IdFilterType;
  invoiceId?: IdFilterType;
  status?: InvoiceProcessingJobStatusFilter;
}

export interface FetchInvoiceProcessingJobsFilters {
  invoiceId?: InvoiceID;
  invoiceNanoId?: InvoiceNanoID;
  projectNanoId?: ProjectNanoID;
}

export enum InvoiceProcessingJobNames {
  ACCEPT = 'accept',
  START = 'start',
  CANCEL = 'cancel'
}

export enum InvoiceProcessingJobStatuses {
  INITIALIZED = 'initialized',
  PROCESSING = 'processing',
  COMPLETED = 'completed',
  FAILED = 'failed'
}

export const enum FetchInvoiceProcessingJobsSortTypes {
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  STATUS_ASC = 'STATUS_ASC',
  STATUS_DESC = 'STATUS_DESC',
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC'
}

export type FetchFinInvoiceProcessingJobFilterJobs =
  FetchItemsFilterItems<FetchFinInvoiceProcessingJobsFilters>;

export type ChangeFinInvoiceProcessingJobFilterJobs = (
  changedFilters: FetchFinInvoiceProcessingJobsFilters,
  removeFilters?: string[]
) => void;
export type FetchFinInvoiceProcessingJobSortJobs = FetchItemsSortItems;

export type FetchInvoiceProcessingJobsGqlQuery = FetchItemsGqlQuery;
export type FetchInvoiceProcessingJobsCacheKey = FetchItemCacheKey;
export type FetchInvoiceProcessingJobsSort = FetchItemsSort;
export type FetchInvoiceProcessingJobsPage = FetchItemsPage;
export type FetchInvoiceProcessingJobsLimit = FetchItemsLimit;

export type UpdateInvoiceProcessingJobsCacheKeys = UpdateItemsCacheKeys;
