import { gql } from 'graphql-request';

import { MayBe } from '../../../types';

import {
  SelectedProductID,
  SelectedProductUUID,
  SelectedProductCreatedAt,
  SelectedProductProductUUID,
  SelectedProductProductName,
  SelectedProductProductBrandUUID,
  SelectedProductProductBrandName,
  SelectedProductProductImageUUID,
  SelectedProductProductImageFile,
  SelectedProductProductNda,
  SelectedProductUserNanoId,
  SelectedProductUserFullName,
  SelectedProductUserImageUUID,
  SelectedProductUserImageFile,
  SelectedProductProductBrandLocalizedName,
  SelectedProductUserClient,
  SelectedProductUserCurrentTeamNanoID
} from '../selectedProductsTypes';

export interface FetchSelectedProductsQueryResponse {
  id: SelectedProductID;
  uuid: SelectedProductUUID;
  createdAt: SelectedProductCreatedAt;
  user: {
    nanoId: SelectedProductUserNanoId;
    fullName: SelectedProductUserFullName;
    client: SelectedProductUserClient;
    currentTeam: MayBe<{
      nanoId: SelectedProductUserCurrentTeamNanoID;
    }>;
    image: {
      uuid: SelectedProductUserImageUUID;
      file: SelectedProductUserImageFile;
    };
  };
  product: {
    uuid: SelectedProductProductUUID;
    name: SelectedProductProductName;
    nda: SelectedProductProductNda;
    brand: {
      uuid: SelectedProductProductBrandUUID;
      name: SelectedProductProductBrandName;
      localizedName: SelectedProductProductBrandLocalizedName;
    };
    image: {
      uuid: SelectedProductProductImageUUID;
      file: SelectedProductProductImageFile;
    };
  };
}

export const FETCH_SELECTED_PRODUCTS_QUERY = gql`
  query TaskSelectedProducts(
    $filters: SelectedProductsFilters
    $sort: [SelectedProductsSortEnum!]
    $limit: Int
    $offset: Int
  ) {
    selectedProducts(
      filters: $filters
      sort: $sort
      limit: $limit
      offset: $offset
    ) {
      nodes {
        id
        uuid
        createdAt
        user {
          nanoId
          fullName
          client
          currentTeam {
            nanoId
          }
          image {
            uuid
            file
          }
        }
        product {
          uuid
          name
          nda
          brand {
            uuid
            name
            localizedName
          }
          image {
            uuid
            file
          }
        }
      }
      paginationInfo {
        nextPage
        totalCount
      }
      sql
    }
  }
`;
