import filter from 'lodash/filter';
import size from 'lodash/size';
import isEmpty from 'lodash/isEmpty';
import includes from 'lodash/includes';

import { SubmitSourceFilesToTaskFormData } from '../../SubmitSourceFilesToTaskForm.types';
import {
  TaskFields,
  TaskNanoID,
  TaskProjectNanoID
} from '../../../../../tasksTypes';

import {
  SUBMIT_SOURCE_FILES_TO_TASK_QUERY,
  SubmitSourceFilesToTaskQueryResponse
} from '../../../../../queries/submitSourceFilesToTask.query';
import {
  SUBMIT_FILE_ATTACHMENTS_AS_SOURCE_FILES_TO_TASK_QUERY,
  SubmitFileAttachmentsAsSourceFilesToTaskResponse
} from '../../../../../queries/submitFileAttachmentsAsSourceFilesToTask.query';

import { useReactHookForm } from '../../../../../../common/hooks/base/useReactHookForm';
import { useSubmitSourceFilesToTask } from '../../../../../hooks/useSubmitSourceFilesToTask';
import { useSubmitFileAttachmentsAsSourceFilesToTask } from '../../../../../hooks/useSubmitFileAttachmentsAsSourceFileToTask';

import { DropzoneHelperFileId } from '../../../../../../../helpers/dropzone/DropzoneHelper';

import { TaskCache } from '../../../../../TaskCache';
import { ProjectCache } from '../../../../../../projects/ProjectCache';
import { formsErrors } from '../../../../../../../locales/keys';

const submitSourceFilesToTaskFormData: SubmitSourceFilesToTaskFormData = {
  sourceFileIds: []
};

interface SubmitSourceFilesToTaskFormOptions {
  taskNanoId: TaskNanoID;
  projectNanoId?: TaskProjectNanoID;
  initialFileIds?: DropzoneHelperFileId[];
}

const sourceFileIdsRules = { required: formsErrors.required };

function useSubmitSourceFilesToTaskForm({
  taskNanoId,
  projectNanoId,
  initialFileIds
}: SubmitSourceFilesToTaskFormOptions) {
  const { handleSubmitReactHookForm, register, errors, control, resetForm } =
    useReactHookForm<SubmitSourceFilesToTaskFormData>({
      defaultValues: submitSourceFilesToTaskFormData,
      isModalForm: true
    });

  const {
    submitSourceFilesToTask,
    submitSourceFilesToTaskLoading,
    submitSourceFilesToTaskErrorMessage,
    submitSourceFilesToTaskReset
  } = useSubmitSourceFilesToTask<SubmitSourceFilesToTaskQueryResponse>({
    query: SUBMIT_SOURCE_FILES_TO_TASK_QUERY,
    cacheKeys: projectNanoId
      ? [
          ProjectCache.tasksCacheKey(projectNanoId),
          ProjectCache.tasksCompactCacheKey(projectNanoId),
          ProjectCache.tasksResultsCacheKey(projectNanoId)
        ]
      : [
          TaskCache.showCacheKey(),
          TaskCache.showResultsCacheKey(),
          TaskCache.indexCacheKey(),
          TaskCache.sourceFilesCacheKey(taskNanoId),
          TaskCache.resultsCacheKey(taskNanoId),
          TaskCache.itemsResultsCacheKey(),
          TaskCache.withoutSourceFiles(),
          TaskCache.dashboardWithoutSourceFiles(),
          TaskCache.userResultsCacheKey(),
          TaskCache.showSidebarCacheKey()
        ]
  });

  const {
    submitFileAttachmentsAsSourceFilesToTask,
    submitFileAttachmentsAsSourceFilesToTaskLoading,
    submitFileAttachmentsAsSourceFilesToTaskErrorMessage,
    submitFileAttachmentsAsSourceFilesToTaskReset
  } = useSubmitFileAttachmentsAsSourceFilesToTask<SubmitFileAttachmentsAsSourceFilesToTaskResponse>(
    {
      query: SUBMIT_FILE_ATTACHMENTS_AS_SOURCE_FILES_TO_TASK_QUERY,
      cacheKeys: [
        TaskCache.showCacheKey(),
        TaskCache.indexCacheKey(),
        TaskCache.sourceFilesCacheKey(taskNanoId),
        TaskCache.resultsCacheKey(taskNanoId),
        TaskCache.itemsResultsCacheKey(),
        TaskCache.withoutSourceFiles(),
        TaskCache.dashboardWithoutSourceFiles(),
        TaskCache.userResultsCacheKey()
      ]
    }
  );

  return {
    control,
    submitSourceFilesToTask,
    submitSourceFilesToTaskLoading:
      submitSourceFilesToTaskLoading ||
      submitFileAttachmentsAsSourceFilesToTaskLoading,
    submitSourceFilesToTaskErrorMessage:
      submitSourceFilesToTaskErrorMessage ||
      submitFileAttachmentsAsSourceFilesToTaskErrorMessage,
    submitSourceFilesToTaskReset:
      submitSourceFilesToTaskReset ||
      submitFileAttachmentsAsSourceFilesToTaskReset,
    resetForm,
    handleSubmitSourceFilesToTask: handleSubmitReactHookForm({
      onSubmit: async (data: SubmitSourceFilesToTaskFormData) => {
        const sourceFileIds = filter(
          data.sourceFileIds,
          (sourceFileId) => !includes(initialFileIds, sourceFileId)
        );
        const fileAttachmentIds = filter(data.sourceFileIds, (sourceFileId) =>
          includes(initialFileIds, sourceFileId)
        );

        if (!isEmpty(fileAttachmentIds)) {
          await submitFileAttachmentsAsSourceFilesToTask({
            uuid: taskNanoId,
            fileAttachmentIds
          });
        }

        if (size(sourceFileIds) > 0) {
          return submitSourceFilesToTask({
            uuid: taskNanoId,
            sourceFileIds
          });
        }
      }
    }),
    registerSourceFileIds: register(
      TaskFields.SOURCE_FILE_IDS,
      sourceFileIdsRules
    ),
    validationErrors: {
      sourceFileIdsValidationError: errors?.sourceFileIds?.['message']
    }
  };
}

export default useSubmitSourceFilesToTaskForm;
