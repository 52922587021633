import isThisYear from 'date-fns/isThisYear';
import isToday from 'date-fns/isToday';

function getCustomDateFormat(
  date: Date | string,
  withTime?: boolean,
  withSeconds?: boolean
): string {
  const currentDate = new Date(date);

  const withTimeNotThisYearFormat = withSeconds
    ? 'dd.MM.yyyy HH:mm:ss'
    : 'dd.MM.yyyy HH:mm';

  const withTimeNotNotTodayFormat = withSeconds
    ? 'dd MMM HH:mm:ss'
    : 'dd MMM HH:mm';

  if (!isThisYear(currentDate)) {
    return withTime ? withTimeNotThisYearFormat : 'dd.MM.yyyy';
  }

  if (!isToday(currentDate)) {
    return withTime ? withTimeNotNotTodayFormat : 'dd MMM';
  }

  return withSeconds ? 'HH:mm:ss' : 'HH:mm';
}

export default getCustomDateFormat;
