import { useCallback, useState } from 'react';
import some from 'lodash/some';
import head from 'lodash/head';

import {
  TaskNanoID,
  TaskResultsFields,
  TaskStatuses
} from '../../../../../tasksTypes';

import {
  SUBMIT_RESULTS_TO_TASK_QUERY,
  SubmitResultsToTaskQueryResponse
} from '../../../../../queries/submitResultsToTask.query';
import {
  UPDATE_MESSAGE_RESULT_VERSION_QUERY,
  UpdateMessageResultVersionRecordType
} from '../../../../../../messages/queries/updateMessageResultVersion.query';
import {
  FETCH_TASK_MESSAGES_WITH_RESULT,
  FetchTaskMessagesWithResultQueryResponse
} from '../../../../../../messages/queries/fetchTaskMessagesWithResult.query';

import { useReactHookForm } from '../../../../../../common/hooks/base/useReactHookForm';
import { useMessages } from '../../../../../../messages/hooks/useMessages';
import { useUpdateMessageResultVersion } from '../../../../../../messages/hooks/useUpdateMessageResultVersion';
import { useSubmitResultsToTask } from '../../../../../hooks/useSubmitResultsToTask';

import { SubmitResultsToTaskFormData } from '../../SubmitResultsToTaskForm.types';

import { submitResultsToTaskValidationSchema } from './useSubmitResultsToTaskForm.schema';

import { DropzoneHelperFiles } from '../../../../../../../helpers/dropzone/DropzoneHelper';

import { TaskCache } from '../../../../../TaskCache';
import { SmartContractCache } from '../../../../../../smartContracts/SmartContractCache';

const submitResultsToTaskFormData: SubmitResultsToTaskFormData = {
  fileAttachmentIds: [],
  hours: '',
  minutes: '',
  lexical: {
    mentionIds: [],
    text: ''
  }
};

interface SubmitResultsToTaskFormOptions {
  taskNanoId: TaskNanoID;
}

function useSubmitResultsToTaskForm({
  taskNanoId
}: SubmitResultsToTaskFormOptions) {
  const { handleSubmitReactHookForm, register, errors, control, resetForm } =
    useReactHookForm<SubmitResultsToTaskFormData>({
      defaultValues: submitResultsToTaskFormData,
      isModalForm: true,
      schema: submitResultsToTaskValidationSchema
    });

  const { messages } = useMessages<FetchTaskMessagesWithResultQueryResponse>({
    cacheKey: TaskCache.lastMessagesWithResultCacheKey(taskNanoId),
    query: FETCH_TASK_MESSAGES_WITH_RESULT,
    initialFilters: {
      taskNanoId,
      isResult: true
    },
    initialLimit: 1
  });

  const {
    updateMessageResultVersion,
    updateMessageResultVersionLoading,
    updateMessageResultVersionErrorMessage
  } = useUpdateMessageResultVersion<UpdateMessageResultVersionRecordType>({
    query: UPDATE_MESSAGE_RESULT_VERSION_QUERY,
    cacheKeys: []
  });

  const {
    submitResultsToTask,
    submitResultsToTaskLoading,
    submitResultsToTaskErrorMessage,
    submitResultsToTaskReset
  } = useSubmitResultsToTask<SubmitResultsToTaskQueryResponse>({
    query: SUBMIT_RESULTS_TO_TASK_QUERY,
    cacheKeys: [
      SmartContractCache.selectByTaskFieldCacheKey(taskNanoId),
      SmartContractCache.showCacheKey(),
      TaskCache.indexCacheKey(),
      TaskCache.showCacheKey(),
      TaskCache.showSidebarCacheKey(),
      TaskCache.showDashboardCacheKey(),
      TaskCache.showHeaderActionsCacheKey(),
      TaskCache.messagesCacheKey(taskNanoId),
      TaskCache.itemsResultsCacheKey(),
      TaskCache.withoutSourceFiles(),
      TaskCache.dashboardWithoutSourceFiles(),
      TaskCache.userResultsCacheKey(),
      TaskCache.userActiveTasksCacheKey(),
      TaskCache.activeTaskCacheKey(taskNanoId),
      TaskCache.lastMessagesWithResultCacheKey(taskNanoId),
      TaskCache.resultsWithoutVersionCacheKey(taskNanoId),
      TaskCache.resultsCacheKey(taskNanoId)
    ]
  });

  const [loadingFiles, setLoadingFiles] = useState<boolean>(false);

  const handleChangeFiles = useCallback<(files: DropzoneHelperFiles) => void>(
    (files) => {
      setLoadingFiles(some(files, (file) => file.state === 'processing'));
    },
    []
  );

  return {
    control,
    submitResultsToTask,
    submitResultsToTaskLoading:
      submitResultsToTaskLoading || updateMessageResultVersionLoading,
    submitResultsToTaskErrorMessage:
      submitResultsToTaskErrorMessage || updateMessageResultVersionErrorMessage,
    submitResultsToTaskReset,
    resetForm,
    handleSubmitResultsToTask: handleSubmitReactHookForm({
      onSubmit: async (data: SubmitResultsToTaskFormData) => {
        const message = head(messages);

        if (
          message &&
          message.resultVersion &&
          message.task.status === TaskStatuses.QUALITY_CONTROL
        ) {
          await updateMessageResultVersion({
            resultVersion: 0,
            uuid: message.uuid
          });
        }

        const mentionedUserIds = data.lexical?.mentionIds;

        return submitResultsToTask({
          uuid: taskNanoId,
          body: data.lexical?.text,
          mentionedUserIds,
          fileAttachmentIds: data.fileAttachmentIds,
          hours: data.hours === '' ? undefined : parseFloat(data.hours),
          minutes: data.minutes === '' ? undefined : parseFloat(data.minutes)
        });
      }
    }),
    registerFileAttachmentIds: register(TaskResultsFields.FILE_ATTACHMENT_IDS),
    registerHours: register(TaskResultsFields.HOURS),
    registerMinutes: register(TaskResultsFields.MINUTES),
    validationErrors: {
      fileAttachmentIdsValidationError: errors?.fileAttachmentIds?.['message'],
      minutesValidationError: errors?.minutes?.message,
      hoursValidationError: errors?.hours?.message
    },
    loadingFiles,
    handleChangeFiles
  };
}

export default useSubmitResultsToTaskForm;
