import React, { useMemo, useCallback, ClipboardEventHandler } from 'react';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import axiosRetry from 'axios-retry';
import filter from 'lodash/filter';
import map from 'lodash/map';
import size from 'lodash/size';
import isEmpty from 'lodash/isEmpty';
import includes from 'lodash/includes';

import { useS3MultipartUpload } from '../../../main/s3Multipart/hooks/useS3MultipartUpload';

import { DropzoneFullscreenHelper } from '../DropzoneFullscreenHelper';

import {
  S3MultipartDropzoneWrapperProps,
  S3MultipartDropzoneWrapperRequiredProps
} from './S3MultipartDropzoneWrapper.types';

const activeStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

axiosRetry(axios, { retries: 20, retryDelay: axiosRetry.exponentialDelay });

function S3MultipartDropzoneWrapper({
  children,
  type,
  disabled,
  maxFiles,
  activeFilesCount,
  preventMaxFilesOverload,
  withFullscreenDropzone,
  withPasteFiles,
  className,
  inputClassName,
  onFilesAccepted,
  onFileCreated,
  onUploadProgress,
  onFileUploaded,
  onFileFailed,
  onFinishUpload,
  noClick = true
}: S3MultipartDropzoneWrapperProps & S3MultipartDropzoneWrapperRequiredProps) {
  const { onUpload } = useS3MultipartUpload({
    type,
    maxFiles,
    activeFilesCount,
    preventMaxFilesOverload,
    onFilesAccepted,
    onFileCreated,
    onUploadProgress,
    onFileUploaded,
    onFileFailed,
    onFinishUpload
  });

  const handlePaste = useCallback<ClipboardEventHandler<HTMLDivElement>>(
    (event) => {
      if (!includes(event?.clipboardData?.types, 'Files')) {
        return;
      }

      event.stopPropagation();
      event.preventDefault();

      const items = event?.clipboardData?.items;
      const fileItems = filter(items, (item) => item.kind === 'file');

      const expectedFilesCount = activeFilesCount + size(fileItems);

      if (isEmpty(fileItems) || (maxFiles && expectedFilesCount > maxFiles)) {
        return;
      }

      const files = map(fileItems, (fileItem) => fileItem.getAsFile());
      onUpload(files);
    },
    [onUpload, maxFiles, activeFilesCount]
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
    onDrop: onUpload,
    disabled,
    maxFiles,
    noClick
  });

  const style = useMemo(
    () => ({
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  return (
    <div
      className={className}
      {...getRootProps()}
      onPasteCapture={withPasteFiles ? handlePaste : undefined}
    >
      {withFullscreenDropzone ? (
        <DropzoneFullscreenHelper style={style} />
      ) : null}
      <input
        className={
          inputClassName || 'absolute w-0 h-0 block invisible opacity-0 -z-1'
        }
        {...getInputProps(
          inputClassName ? { style: { display: 'inline' } } : undefined
        )}
      />
      {children}
    </div>
  );
}

export default S3MultipartDropzoneWrapper;
